import React from 'react'
import { Link} from 'react-router-dom'

function FolioCard({foliodata}) {
    return (
        <>
            {foliodata.map(item=>(
                <div className='col-lg-4 col-md-12'>
                    <div className={`folio-card-wrapper ${item.cardFive}`}>
                        <Link to={item.folioLink} className='folio-img'><img src={item.FolioImage} alt='folio' title='folio' /></Link>
                        <div className='folio-content-wrapper text-center'>
                            <span className='small-folio-heading'><u>{item.FolioSmallHeading}</u></span>
                            <h6 className={`large-folio-heading ${item.largeFolioHeading}`}>{item.FolioHeading}</h6>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default FolioCard

import React from 'react'
import ResizePanel from 'react-resize-panel';

function ResizeSlider({ozenBeforeImage, ozenAfterImage}) {
    return (
        <>
            <div className='difference-design' style={{
                    width: "100%",
                    height: "747px",
                    display: "flex",
                    flexDirection: "row",
                    overflow: "hidden"
                }}
            >
                <ResizePanel
                    direction="e"
                    style={{width: "50%" }}
                >
                    <div style={{width: "100%", }} className={`left-pannel ${ozenBeforeImage}`}></div>
                </ResizePanel>
                
                <div style={{flexGrow: "1" }} className={`right-pannel ${ozenAfterImage}`}>
                
                </div>
            </div>
        </>
    )
}

export default ResizeSlider

import React, { useState } from 'react'
import ListGroup from 'react-bootstrap/ListGroup';
import { Link } from 'react-router-dom';
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

function Footer() { 

    const [isVisible, setIsVisible] = useState(false);

  // Function to scroll back to the top of the page
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Function to show or hide the button based on scroll position
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) { // Adjust this value to control when the button appears
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add scroll event listener
  window.addEventListener('scroll', toggleVisibility);
  return (
    <>
        <footer>
            <div className='container-fluid custom-container-fluid'>
                <div className='row'>
                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='footer-links mt-0'>
                            <div className='footer-heading mb-3'>
                                <h5 className='theme-font-family work-heading fw-600'>Services</h5>
                            </div>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/web-development'>Web Development</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/mob-development'>Mob App Development</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/uiux-design'>UI/UX Design</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/software-development'>Software Development</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/digital-marketing'>Digital Marketing</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/brand-communication'>Branding & Communication</Link></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='footer-links'>
                            <div className='footer-heading mb-3'>
                                <h5 className='theme-font-family work-heading fw-600'>Solutions</h5>
                            </div>
                            <ListGroup as="ul">
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/comet'>Comet CMS</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/e-commerce'>Comet Shop</Link></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>
                    
                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='footer-links social-links'>
                            <div className='footer-heading mb-3'>
                                <h5 className='theme-font-family work-heading fw-600'>Other Links</h5>
                            </div>
                            <ListGroup as="ul">
                                {/* <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/payment'>Make Payments</Link></ListGroup.Item> */}
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/portfolio'>Portfolio</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/privacy-policy'>Privacy</Link></ListGroup.Item>
                                <ListGroup.Item as="li" className='border-0 bg-transparent ps-0 py-1'><Link className='text-decoration-none' to='/terms-of-use'>Terms of use</Link></ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>

                    <div className='col-lg-3 col-md-6 col-sm-6'>
                        <div className='social-links'>
                            <div className='footer-heading mb-3'>
                                <h5 className='theme-font-family work-heading fw-600 mb-3'>Contact Us</h5>
                                <div className='footer-contact-wrapper'>
                                    <div className='footer-phone-link'>
                                        <span><FaPhone /></span>
                                        <span className='ps-3'>0172 4649954</span>
                                    </div>
                                    <div className='footer-phone-link mt-3'>
                                        <span className='fs-5'><MdEmail /></span>
                                        <Link className='ps-3 footer-email' to='mailto:support@digitechsoft.com'>support@digitechsoft.com</Link>
                                    </div>
                                </div>
                            </div>
                            <div className='social-media-wrapper'>
                                <h5 className='theme-font-family work-heading fw-600 mb-3 '>Follow us</h5>
                                <ListGroup as="ul" className='social-media-links d-flex flex-row '>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 mt-0 py-0'><Link target='_blank' to='https://www.facebook.com/DigitechSoftware/'><FaFacebookF /></Link></ListGroup.Item>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 mt-0  py-0'><Link target='_blank'  to='https://www.linkedin.com/company/digitech-soft-solutions/?originalSubdomain=in'><FaLinkedinIn /></Link></ListGroup.Item>
                                    <ListGroup.Item as="li" className='border-0 bg-transparent px-0 mt-0 py-0'><Link target='_blank' to='https://www.instagram.com/digitech.soft/'><FaInstagram /></Link></ListGroup.Item>
                                </ListGroup>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copyright'>
                    <div className='row align-items-center'>
                        <div className='col-12'>
                            <div className='copyright-left text-center'>
                                <span>© Digitech Software Solutions 2024. All Rights Reserved.</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='back-top-btn'>
                {isVisible &&
                    <Link onClick={scrollToTop} className='theme-btn footer-btn'>back to top</Link>
                }
            </div>
            
            
        </footer>        
        {/* <div className='consult-btn-wrapper text-end'>
            <Link to='/consult' className='btn consult-bottom-btn'><BsChatText /></Link>
        </div> */}
    </>
  )
}

export default Footer

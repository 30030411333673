import React from 'react'
import { Route, Routes } from 'react-router-dom'
import  Home  from '../pages/home/Home';
import  Consult  from '../pages/consult/Consult'
import AtmosphereCaseStudy from '../pages/case-study/AtmosphereCaseStudy';
import DigitechPortfolio from '../pages/portfolio/DigitechPortfolio';
import SolfinCaseStudy from '../pages/case-study/SolfinCaseStudy';
import OzenCollection from '../pages/case-study/OzenCollection';
import GameStoreCaseStudy from '../pages/case-study/GameStoreCaseStudy';
import WebDevelopment from '../pages/services/WebDevelopment'
import Comet from '../pages/solutions/Comet';
import ECommerce from '../pages/solutions/ECommerce';
import MobDevelopment from '../pages/services/MobDevelopment';
import SoftwareDevelopment from '../pages/services/SoftwareDevelopment';
import UiuxDesign from '../pages/services/UiuxDesign';
import DigitalMarketing from '../pages/services/DigitalMarketing';
import BrandCommunication from '../pages/services/BrandCommunication';
import PrivacyPolicy from '../pages/privacy policy/PrivacyPolicy';
import TermsOfUse from '../pages/terms-use/TermsOfUse';
import Pricing from '../pages/pricing/Pricing';
import AtmosphereFolio from '../pages/portfolio/AtmosphereFolio';
import AtmosphereHotelResortFolio from '../pages/portfolio/AtmosphereHotelResortFolio';
import TheOzenCollectionFolio from '../pages/portfolio/TheOzenCollectionFolio';
import Payment from '../pages/payment/Payment';
import DigitalMarketingPricing from '../pages/pricing/DigitalMarketingPricing';
import GameStores from '../pages/portfolio/GameStores';
 
function AllRoutes() {
  return (
    <>
        <Routes>
          <Route path='/' element={<Home/>}></Route>
          <Route path='/consult' element={<Consult/>}></Route>
          <Route path='/atmosphere' element={<AtmosphereCaseStudy/>}></Route>
          <Route path='/portfolio' element={<DigitechPortfolio/>}></Route>
          <Route path='/solfin' element={<SolfinCaseStudy/>}></Route>
          <Route path='/ozen' element={<OzenCollection/>}></Route>
          <Route path='/game-store' element={<GameStoreCaseStudy/>}></Route>
          <Route path='/web-development' element={<WebDevelopment/>}></Route>
          <Route path='/comet' element={<Comet/>}></Route>
          <Route path='/e-commerce' element={<ECommerce/>}></Route>
          <Route path='/mob-development' element={<MobDevelopment/>}></Route>
          <Route path='/software-development' element={<SoftwareDevelopment/>}></Route>
          <Route path='/uiux-design' element={<UiuxDesign/>}></Route>
          <Route path='/digital-marketing' element={<DigitalMarketing/>}></Route>
          <Route path='/brand-communication' element={<BrandCommunication/>}></Route>
          <Route path='/privacy-policy' element={<PrivacyPolicy/>}></Route>
          <Route path='/terms-of-use' element={<TermsOfUse/>}></Route>
          <Route path='/pricing' element={<Pricing/>}></Route>
          <Route path='/atmosphere-living' element={<AtmosphereFolio/>}></Route>
          <Route path='/atmosphere-hotels-resorts' element={<AtmosphereHotelResortFolio/>}></Route>
          <Route path='/ozen-collection' element={<TheOzenCollectionFolio/>}></Route>
          <Route path='/payment' element={<Payment/>}></Route>
          <Route path='/digital-marketing-pricing' element={<DigitalMarketingPricing/>}></Route>
          <Route path='/games-stores' element={<GameStores/>}></Route>
        </Routes>
    </>
  )
}

export default AllRoutes

import React from 'react'
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import TabImage from './TabImage';

function SolutionCaseStudyTabs({ responsivetabArray=[] }) {
    console.log("explore Atmosphere component is loaded ")
    const [activeTab, setActiveTab] = React.useState(0);
    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };
    
    console.log("tab array is  : ",responsivetabArray)

    return (
        <>
            <Tabs activeTab={activeTab} onTabClick={onTabClick} responsivetabArray={responsivetabArray}>
                {/* generating an array to loop through it  */}

                {responsivetabArray.map((item) => (
                    <Tab key={item.coverId} className={`item ${activeTab === item.coverId ? 'active text-start border-0' : 'text-start border-0'}`}>
                        <div className='d-flex align-items-center'>
                            <span className='serial-number'>{item.serialNumber}</span>
                            <h5 className='tab-name mb-0'>{item.name}</h5>
                        </div>
                    </Tab>
                ))}
            </Tabs>

            
            {responsivetabArray.map((screen)=>(
                <TabScreen key={screen.coverId} activeTab={activeTab} index={screen.coverId}>
                    <TabImage className='tab-image-right' imageUrl={screen.imageUrl} ImageShadow={screen.ImageShadow} />
                    <p>{screen.para}</p>
                </TabScreen>
            ))}
        </>
    )
}

export default SolutionCaseStudyTabs

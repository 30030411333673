import React from 'react'
import FolioInnerBanner from '../../components/FolioInnerBanner'
import atmosphereLogoImg from '../../assets/images/ozen-large-logo.svg'
import FolioLogo from '../../components/FolioLogo'
import gameStoresLogoOne from '../../assets/images/games-stores-logo.svg'
import { ListGroup } from 'react-bootstrap-v5'
import AhrColorUsage from '../../components/AhrColorUsage'
import { Link } from 'react-router-dom'
import FolioWebsiteTabs from '../../components/FolioWebsiteTabs'
import imageOne from '../../assets/images/game-store-home-page.webp'
import imageTwo from '../../assets/images/game-store-catalogue.webp'
import imageThree from '../../assets/images/game-store-product.webp'
import frameImage from '../../assets/images/upper-frame.webp'
import { MdArrowOutward } from 'react-icons/md'
import MobileAppImage from '../../assets/images/game-store-mobile-view.webp'
import BrochureImage from '../../assets/images/game-stores-left-ui.webp'
import BrochureImageSecond from '../../assets/images/game-stores-right-ui.webp'
import FolioUcmsTabs from '../../components/FolioUcmsTabs'
import imageUcmsOne from '../../assets/images/game-stores-ucms.webp'
import imageUcmsTwo from '../../assets/images/game-stores-ucms.webp'
import ConsultUs from '../../components/ConsultUs'

function GameStores() {

    const folioHeading = 'Games Stores'
    const createdDate = 'June, 2024'
    const subHeading = 'E-Commerce'
    const CaseStudyLink = "/game-store"
    const gameStoresBackground = 'game-stores-background'
    const foliobannerpara = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries."

    const badgedata = [
        {TabText: 'Website Design'}, {TabText: 'Website Communication'}, {TabText: 'SEO Copywriting'}, {TabText: 'UI-UX Design'}, {TabText: 'Backend Development'}, {TabText: 'Unified - CMS'}
    ]

    const logodata = [
        {ozenLogo: gameStoresLogoOne}
    ]

    const colordata = [
        {AhrColorCode: '#ACFC69 ', AhrColorName: 'Green Yellow'},
        {AhrColorCode: '#1C2C54', AhrColorName: 'Space Cadet'},
        {AhrColorCode: '#F9F9F9', AhrColorName: 'Seasalt'}
    ] 

    const tabArray = [
        {
          coverId: 0, name: 'Home', imageUrl: imageOne, imageFrameUrl: frameImage, ozenDropShadow: 'game-stores-shadow', gameStoresContent: 'game-stores-content'
        }, 
        { 
          coverId: 1, name: 'Catalogue', imageUrl: imageTwo, imageFrameUrl: frameImage, ozenDropShadow: 'game-stores-shadow', gameStoresContent: 'game-stores-content'
        },
        {
          coverId: 2, name: 'Product', imageUrl: imageThree, imageFrameUrl: frameImage, ozenDropShadow: 'game-stores-shadow', gameStoresContent: 'game-stores-content'
        }
    ];

    const atmospherePara = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries."

    const ucmstabArray = [
        {
            coverId:0, name:'Lorem ipsum ipsum', imageUrl:imageUcmsOne,  imageFrameUrl: frameImage, hideScrollAnimation:'hide-scroll-animation', ucmsTabImage: 'ucms-tab-image', ucmsOzenDropShadow: 'ucms-dropshadow'
        }, 
        {
            coverId:1, name:'Lorem ipsum ipsum', imageUrl:imageUcmsTwo,  imageFrameUrl: frameImage, hideScrollAnimation:'hide-scroll-animation', ucmsTabImage: 'ucms-tab-image', ucmsOzenDropShadow: 'ucms-dropshadow'
        }
    ]

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Let's get started!", consultPara: "Whether you're optimising operations, expanding reach, or enhancing customer satisfaction, our solutions cover software, mobile app development, and websites.", consultPortfolio: 'faqs-consult-wrapper', pageLink: '/consult', LinkText: 'get started'}
    ]
    return (
        <>
            <FolioInnerBanner atmosphereLogo={atmosphereLogoImg} folioHeading= {folioHeading} foliobannerpara= {foliobannerpara} badgedata={badgedata}  createdDate={createdDate} subHeading={subHeading} gameStoresBackground={gameStoresBackground} CaseStudyLink={CaseStudyLink} />

            <section>
                <div className='about-atmosphere'>
                    <div className='container'>
                        <div className='folio-logo-wrapper games-stores-logo'>
                            <ListGroup as='ul'>
                                <FolioLogo logodata={logodata}/>
                            </ListGroup>
                        </div>
                        <div className='colo-usage-main'>
                            <div className='color-usage-heading'>
                                <h3 className='heading-font-family'>Color Usage</h3>
                            </div>
                            <div className='color-usage-wrapper d-flex'>
                                <div className='primary-color-usage w-100 games-stores-colors'>
                                    <ListGroup as='ul' className='ozen-color-usage'>
                                        <AhrColorUsage colordata={colordata} />
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study position-relative'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-website-folio'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Website<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link to='' target="_blank" className='text-uppercase d-inline-block theme-btn'>Visit Site <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='website-tabs-wrapper'>
                                <FolioWebsiteTabs tabArray={tabArray} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='typography-wrapper'>
                    <div className='container'>
                        <div className='typography-heading'>
                            <h3>Typography</h3>
                        </div>
                        <div className='typography-inner-wrapper game-stores-typography-wrapper'>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <div className='typography-family-info'>
                                        <div className='alphabet-font-wrapper d-flex align-items-center'>
                                            <h1 className='mb-0'>Aa</h1>
                                            <span>Poppins</span>
                                        </div>
                                        <div className='alphabets-wrapper'>
                                            <h2>abcdefghijklmnopqrstuvwxyz</h2>
                                            <h3>abcdefghijklmnopqrstuvwxyz</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='typography-font-info'>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 40</span>
                                            <h1>Heading 1</h1>
                                        </div>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 36</span>
                                            <h2>Heading 2</h2>
                                        </div>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 22</span>
                                            <h3>Heading 3</h3>
                                        </div>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 18</span>
                                            <h4>Heading 4</h4>
                                        </div>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 16</span>
                                            <h5>Bodytext 1</h5>
                                        </div>
                                        <div className='typography-font mb-0 d-flex align-items-center'>
                                            <span>Size 14</span>
                                            <h6>Bodytext 2</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-mobile-folio'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Mobile View<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link to='' target="_blank" className='text-uppercase d-inline-block theme-btn'>View All <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='website-img'>
                                <img src={MobileAppImage} title='' alt='' className='w-100' />
                            </div>
                        </div>
                        <div className='atmosphere-ui-sheet'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>UI Sheet<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link to=''  className='text-uppercase d-inline-block theme-btn'>Visit Site <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='game-store-ui-wrapper'>
                                        <img src={BrochureImage} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='game-store-ui-wrapper'>
                                        <img src={BrochureImageSecond} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study position-relative'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-website-folio pb-0'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>UCMS Design<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                            </div>
                            <div className='website-tabs-wrapper'>
                                <FolioUcmsTabs ucmstabArray={ucmstabArray}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <Link to="/atmosphere-living" className='healthism-btn-wrapper ozen-living-wrapper mt-0 w-100 text-center'>
                            <span className='d-block'>next project</span>
                            <h2>Atmosphere Living</h2>
                        </Link>
                    </div>
                </div>
            </section>
            
            <ConsultUs consultdata={consultdata} showheading={false} showSmallHeading={false} /> 
        </>
    )
}

export default GameStores

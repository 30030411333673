import React from 'react'
import { HiMiniArrowLongLeft } from 'react-icons/hi2'
import { Link } from 'react-router-dom'

function ServiceBanner ({servicedata}) {
    
    return (
        <>
            {servicedata.map(item =>(
                <section>
                    <div className={`service-main-banner header-space ${item.cometMainBanner}`}>
                        <div className='container'>
                            <div className='row align-items-center'>
                                <div className='col-lg-6'>
                                    <div className='service-banner-content'>
                                        <h6 className={`text-uppercase brand-color fs-4 fw-semibold work-heading theme-font-family ${item.smallHeadingColor}`}>{item.bannerSmallHeading}</h6>
                                    <h2 className={`service-banner-heading fw-bold ${item.webBannerHeading}`}>{item.bannerHeading}</h2>
                                        <Link to='/' className='btn p-0 mt-2'> <HiMiniArrowLongLeft /> <span className='ms-1'>Back to Home</span></Link>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='service-banner-right'>
                                        <img src={item.bannerImage} alt='banner' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            ))}
        </>
    )
}

export default ServiceBanner

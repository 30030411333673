import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from 'react-bootstrap-v5';
import { Link } from 'react-router-dom'
import { MdArrowOutward } from "react-icons/md";

function SoftwareTechnologiesSlider({softwaredataSetting, technologiessliderdata}) {
    const settings = {...softwaredataSetting}
    return (
        <>
            <Slider {...settings}>
                {technologiessliderdata.map(item => (
                    <Card className='software-technologies-card'>
                        <div className='technologies-left-icon'>
                            <Card.Img className='m-auto' src={item.technologiesImage} alt='' title='Branding' />
                        </div>
                        <Card.Body>
                            <Card.Title className='fw-semibold'>{item.technologiesTitle}</Card.Title>
                            <Card.Text>{item.technologiesPara}</Card.Text>
                            <Link to={item.technologiesLink} className='btn text-uppercase get-started-btn text-decoration-none p-0'>
                            {item.technologiesGetStarted}  <MdArrowOutward />
                            </Link>
                        </Card.Body>
                    </Card>
                ))}
            </Slider>
        </>
    )
}

export default SoftwareTechnologiesSlider

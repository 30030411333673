import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './assets/scss/style.scss'
import './assets/scss/responsive.scss'
import '../node_modules/react-tabs-scrollable/dist/rts.css';  
import Header from './components/Header'
import AllRoutes from './allRoutes/AllRoutes';
import Footer from './components/Footer'

function App() {
  return (
    <>
      <Header/>
      <AllRoutes/>
      <Footer/>
    </>
  );
}

export default App;

import React from 'react'
import { Card } from 'react-bootstrap-v5'

function CometFeaturesCards({cometfeaturesdata}) {
    return (
        <>  
            {cometfeaturesdata.map(item=>(
                <div className='col-lg-4 col-md-6'>
                    <Card className="comet-features-card text-center rounded-0">
                        <div className='card-img-wrapper'>
                            <Card.Img className={`m-auto ${item.cometfeaturecard}`}  src={item.cometfeaturesImage} alt='' title='' />
                        </div>
                        <Card.Body className='p-0 mt-5'>
                            <Card.Title className='mb-0'>{item.cometfeaturesTitle}</Card.Title>
                            <Card.Text className='text-center mt-3'>{item.cometfeatureText}</Card.Text>
                        </Card.Body>
                    </Card>
                </div>
            ))}
        </>
    )
}

export default CometFeaturesCards

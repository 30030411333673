import React from 'react'
import WeWorkOnSlider from './WeWorkOnSlider'
import PhpLogo from '../assets/images/php-logo.webp'
import BootstrapLogo from '../assets/images/bootstrap-logo.webp'
import AppleLogo from '../assets/images/apple-logo.webp'
import FigmaLogo from '../assets/images/figma.webp'
import AndroidLogo from '../assets/images/android.webp'
import NodeLogo from '../assets/images/node-js.webp'
import ReactLogo from '../assets/images/react.webp'
import PostgreLogo from '../assets/images/postgresql.webp'
import FlutteraLogo from '../assets/images/flutter.webp'
import ReactNativeLogo from '../assets/images/react.webp'
import PythonLogo from '../assets/images/python.webp'
import CssLogo from '../assets/images/css.webp'
import HtmlLogo from '../assets/images/html.webp'

function WeWorkOn() {
    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 7,
        slidesToScroll: 1,
        responsive: [   
            {
                breakpoint: 1460,
                settings: {
                  slidesToShow: 6,
                }
            },
            {
                breakpoint: 1399,
                settings: {
                  slidesToShow: 5,
                }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 4,
              }
            },
            {
                breakpoint: 838,
                settings: {
                  slidesToShow: 3,
                }
            },
            {
                breakpoint: 576,
                settings: {
                  slidesToShow: 2,
                }
            },
            {
                breakpoint: 380,
                settings: {
                  slidesToShow: 1,
                }
            }
        ]
    };

    const images = [
        {softwareImage: PhpLogo, workText:'PHP'}, 
        {softwareImage: NodeLogo, workText:'NodeJS'},
        {softwareImage: ReactLogo, workText:'ReactJS'},
        {softwareImage: PostgreLogo, workText:'PostgreSQL'}, 
        {softwareImage: AndroidLogo, workText:'Android'},
        {softwareImage: AppleLogo, workText:'IOS'},
        {softwareImage: FlutteraLogo, workText:'Flutter'}, 
        {softwareImage: ReactNativeLogo, workText:'React Native'},
        {softwareImage: PythonLogo, workText:'Python'}, 
        {softwareImage: BootstrapLogo, workText:'Bootstrap 5'}, 
        {softwareImage: CssLogo, workText:'CSS 3'},
        {softwareImage: HtmlLogo, workText:'HTML 5'},
        {softwareImage: FigmaLogo, workText:'Figma'}
    ]
    return (
        <>
            <section>
                <div className='digitech-services-wrapper atmosphere-case-study digitech-working-wrapper'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            <h2 className='fw-bold'>We work with<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='testimonal-slider'>
                            <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default WeWorkOn

import React from 'react'

function OzenColorsIcons({iconcolorsdata}) {
    return (
        <>
            {iconcolorsdata.map(item =>(
                <div className={`ozen-inner-icons ${item.ahrInnerIcon} ${item.iconSpacing}`}>
                    <div className={`icon-wrapper  ${item.yellowIcon}`}>
                        <img src={item.iconOne} title='' alt='' />
                    </div>
                    <div className={`icon-wrapper  ${item.yellowIcon}`}>
                        <img src={item.iconTwo} title='' alt='' />
                    </div>
                    <div className={`icon-wrapper mb-0 ${item.yellowIcon}`}>
                        <img src={item.iconThree} title='' alt='' />
                    </div>
                </div>
            ))}
        </>
    )
}

export default OzenColorsIcons

import React, { useState } from 'react';
import parse from 'html-react-parser'
import { MdArrowOutward } from 'react-icons/md'
import ServiceBanner from '../../components/ServiceBanner'
import BannerImage from '../../assets/images/comet-img.webp'
import { Link } from 'react-router-dom'
import TechnologyCard from '../../components/TechnologyCard'
import TechnologyImage from '../../assets/images/react.webp'
import TechnologyImage2 from '../../assets/images/node-js.webp'
import TechnologyImage3 from '../../assets/images/postgresql.webp'
import TechnologyImage4 from '../../assets/images/express-js.webp'
import TechnologyImage5 from '../../assets/images/html.webp'
import TechnologyImage6 from '../../assets/images/bootstrap-logo.webp'
import GetDoneCards from '../../components/GetDoneCards'
import FaqsAccordion from '../../components/FaqsAccordion'
import { Accordion } from 'react-bootstrap-v5'
import ConsultUs from '../../components/ConsultUs'
import FeaturesImage from '../../assets/images/multi-site-management.webp'
import FeaturesImage2 from '../../assets/images/unified-library.webp'
import FeaturesImage3 from '../../assets/images/user-role.webp'
import FeaturesImage4 from '../../assets/images/web-analytics.webp'
import FeaturesImage5 from '../../assets/images/game-lead-management.webp'
import FeaturesImage6 from '../../assets/images/offers-deals.webp'
import CometFeaturesCards from '../../components/CometFeaturesCards'
import OverlappingBoxes from '../../components/OverlappingBoxes';
import offerImageOne from '../../assets/images/comet-2.webp'
import offerImageTwo from '../../assets/images/comet-3.webp'
import offerImageThird from '../../assets/images/comet-1.webp'
import ShowcaseSlider from '../../components/ShowcaseSlider';
import showcaseImage from '../../assets/images/comet-showcase-1.webp'
import showcaseImageTwo from '../../assets/images/comet-showcase-2.webp'
import showcaseImageThree from '../../assets/images/comet-showcase-3.webp'
import WeWorkOnSlider from '../../components/WeWorkOnSlider';

function Comet() {
    const servicedata= [
        {bannerSmallHeading:'COMET CMS', bannerHeading:"Unlock seamless Multi-site Management with COMET", bannerImage: BannerImage, cometMainBanner: 'comet-main-banner', smallHeadingColor: 'comet-heaidng-color'}
    ]

    const paraaboutservice= '<span className="letter-size">W</span>elcome to COMET, the solution that revolutionizes multi-site management. Say goodbye to the complexity of managing multiple websites with disparate systems. With COMET, you can centralize website management, streamline processes, and drive efficiency like never before.'

    // const paraaboutserviceOne= 'Lorem Ipsum has been the industrys standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text.'

    const technologydata = [
        {TechnologyImage: TechnologyImage, technologyTitle:'React'},
        {TechnologyImage: TechnologyImage2, technologyTitle:'Node'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'Postgres'},
        {TechnologyImage: TechnologyImage4, technologyTitle:'Express Js'},
        {TechnologyImage: TechnologyImage5, technologyTitle:'HTML 5'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'Bootstrap'}
    ]

    const getdonedata = [
        {getDoneTitle:'Chain Resorts', getDonePara: 'Simplify booking and enhance guest experiences.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Chain Salons', getDonePara: 'Standardize services and promotions for consistency.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Chain Restaurants', getDonePara: 'Manage menus and promotions seamlessly.', getDonecard: 'comet-get-done-wrapper'},
        {getDoneTitle:'Chain Hotels', getDonePara: 'Optimize booking and loyalty programs.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Chain Shops', getDonePara: 'Centralize inventory and promotions.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Franchise Networks', getDonePara: 'Empower franchisees with customizable templates.', getDonecard: 'comet-get-done-wrapper'}
    ]

    const accordionData = [
        {
            planEventKey:0, planTabHeading: 'How does COMET ensure consistency in branding and messaging across multiple websites?', planTabText: "COMET's unified modules enable centralized management of media, content, and promotions, ensuring consistent branding and messaging across all websites."
        }, 
        {
            planEventKey:1, planTabHeading: 'Can COMET support the creation and management of complex promotional campaigns across multiple sites?', planTabText: "Yes, COMET's deal management feature allows businesses to create and publish deals quickly across all websites, driving sales and engagement effectively."
        },
        {
            planEventKey:2, planTabHeading: 'How does COMET simplify website analytics tracking for businesses with multiple sites?', planTabText: "COMET provides a unified analytics dashboard, consolidating traffic data, keywords, and SEO results for all websites, empowering businesses to gain actionable insights and optimize their digital strategy."
        },
        {
            planEventKey:3, planTabHeading: 'Is COMET compatible with existing POS systems for seamless integration?', planTabText: "Yes, COMET offers quick POS integrations, allowing businesses to streamline operations and enhance customer experiences across online and offline channels effortlessly."
        }
    ]

    const defaultActiveKey = accordionData.length > 0 ? accordionData[0].planEventKey : null;

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Multi-Site Setup to Transform Your Business.", consultPara: "Take the first step towards seamless multi-website operations, and explore new possibilities.", consultPortfolio: 'faqs-consult-wrapper', cometConsultBtn: 'mob-consult-btn', pageLink: '/consult', LinkText: 'get started'}
    ]

    const cometfeaturesdata = [
        {cometfeaturesImage: FeaturesImage, cometfeaturesTitle:'Multi-Site Management', cometfeatureText:'Ability to manage multiple websites (main site and sub-brand sites).'},
        {cometfeaturesImage: FeaturesImage2, cometfeaturesTitle:'Unified Content Library', cometfeatureText:'Centralised repository for media assets (images, videos, documents).',},
        {cometfeaturesImage: FeaturesImage3, cometfeaturesTitle:'User Role management', cometfeatureText:'Tools to arrange site navigation & menu for each sub-brand site independently.', cometfeaturecard:'comet-feature-img'},
        {cometfeaturesImage: FeaturesImage4, cometfeaturesTitle:'Web Analytics', cometfeatureText:'Ability to manage multiple websites (main site and sub-brand sites).'},
        {cometfeaturesImage: FeaturesImage5, cometfeaturesTitle:'Lead Management', cometfeatureText:'Centralised repository for media assets (images, videos, documents).',  cometfeaturecard:'comet-feature-img'},
        {cometfeaturesImage: FeaturesImage6, cometfeaturesTitle:'Offers & Deals', cometfeatureText:'Tools to arrange site navigation & menu for each sub-brand site independently.'}
    ]

    // const showmoredata = [
    //     {cometfeaturesImage: FeaturesImage7, cometfeaturesTitle:'Sell Online', cometfeatureText:'Ability to manage multiple websites (main site and sub-brand sites).'},
    //     {cometfeaturesImage: FeaturesImage8, cometfeaturesTitle:'Content Marketing', cometfeatureText:'Centralised repository for media assets (images, videos, documents).',},
    //     {cometfeaturesImage: FeaturesImage9, cometfeaturesTitle:'Quick POS Integrations', cometfeatureText:'Easily integrate with your popular POS systems.', cometfeaturecard:'comet-feature-img'}
    // ]

    const [isOpened, setIsOpened] = useState(false);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }

    // const testimonialSetting = {
    //     arrow: false,
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 1199,
    //           settings: {
    //             slidesToShow: 1,
    //           }
    //         }
    //     ]
    // };

    // const testimonialdata = [
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'}
    // ]

    const overlapdata = [
        {overlapHeading: 'Unified Modules', overlapPara: "Manage your media, press releases, blogs, and content for all your sites from just one simple backend. With COMET, streamline your content management processes and ensure consistency across all your web properties effortlessly.", overlapLink:'Next', overlapClassOne: 'overlap-box-first', offerImageThird: offerImageOne}, 

        {overlapHeading: 'Create and Publish Deals', overlapPara: "Quickly create recurring and time-limited deals and discounts and promote them across all your websites with ease. Drive sales and engage customers effectively with COMET's deal management feature.", overlapLink:'Next', overlapClassSecond: 'overlap-box-second', offerImageThird: offerImageTwo}, 

        {overlapHeading: 'Unified Analytics Dashboard', overlapPara: "Access all your traffic data analytics, keywords, and SEO results for all your websites on one unified dashboard. Gain valuable insights into website performance and optimize your digital strategy for maximum impact with COMET's comprehensive analytics suite.", overlapLink:'Next', overlapClassThird: 'overlap-box-third', offerImageThird: offerImageThird}
    ]

    const FeaturesPara = "COMET's CMS unifies powerful features, offering seamless website management, robust content creation, and intuitive customization tools. With its user-friendly interface and comprehensive functionality, COMET empowers businesses to create, manage, and optimise their online presence with ease."

    const showcaseSettings = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    };

    const showcasedata = [
        {smallHeding: 'Hospitality', LargeHeading: 'Atmosphere Kanifushi', showcaseImage: showcaseImage, sliderClass: 'comet-slide-one'}, 
        {smallHeding: 'Hospitality', LargeHeading: 'Raaya By Atmosphere', showcaseImage: showcaseImageTwo, sliderClass: 'comet-slide-two'}, 
        {smallHeding: 'Hospitality', LargeHeading: 'Ozen Reserve Bolifushi', showcaseImage: showcaseImageThree, sliderClass: 'comet-slide-three'}

    ]

    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },

            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

    const images = [
        {softwareImage: TechnologyImage, workText:'React'},
        {softwareImage: TechnologyImage2, workText:'Node js'},
        {softwareImage: TechnologyImage3, workText:'Postgres'},
        {softwareImage: TechnologyImage4, workText:'Express Js'},
        {softwareImage: TechnologyImage5, workText:'HTML 5'},
        {softwareImage: TechnologyImage6, workText:'Bootstrap', SliderImg:'technology-slider-img'}
    ]

    return (
        <>
            <ServiceBanner servicedata={servicedata}/>

            <section>
                <div className='about-service-wrapper'>
                    <div className='container'>
                        <div className='service-about-para'>
                            <p>{parse(paraaboutservice)}</p>
                            {/* <p>{parse(paraaboutserviceOne)}</p> */}
                            <div className='service-btn-wrapper'>
                                <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */}


            <section>
                <div className='overlapping-main-wrapper  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row comet-responsive-column align-items-center'>
                            <div className='col-lg-6'>
                                <div className='features-heading offer-heading-wrapper'>
                                    <h2 className='mb-0 fw-bold pe-5 content-management-heading'>COMET's Unified Content Management System</h2>
                                </div>  
                            </div>
                            <div className='col-lg-6'>
                                <div className='paragraph-wrapper'>
                                    <p className='mb-0'>Effortlessly oversee, update, control, and optimise all your websites from a single, intuitive platform.</p>
                                </div>
                            </div>
                        </div> 
                        <OverlappingBoxes overlapdata={overlapdata}/>
                    </div>
                </div>
            </section>

            <section>
                <div className='comet-features-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row align-items-end'>
                            <div className='col-lg-11 col-md-12'>
                                <div className='features-heading'>
                                    <h2 className='mb-5 fw-bold'>Features<span className='brand-color'>.</span></h2>
                                    <p>{parse(FeaturesPara)}</p>
                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-md-6'>
                                <div className='comet-btn-wrapper text-end'>
                                    <Link to='' className='btn comet-btn text-uppercase'>View Packages<MdArrowOutward /></Link>
                                </div>
                            </div> */}
                        </div>
                        <div className='features-inner-cards'>
                            <div className='row'>
                                <CometFeaturesCards cometfeaturesdata={cometfeaturesdata} />
                            </div>
                                {/* <div className="box">
                                    
                                    {isOpened && (
                                        <div className="boxcontent">
                                            <div className='row'>
                                                <CometShowMoreFeatures showmoredata={showmoredata} />
                                            </div>
                                        </div>
                                    )}
                                    <div className="boxtitle" onClick={toggle}>
                                        <div className='show-less-wrapper'>
                                            <BsChevronDown className='show-less-icon' />
                                        </div>
                                    </div>
                                </div> */}
                        </div>
                    </div>
                </div>
            </section>

            
            <section>
                <div className='about-service-wrapper overflow-hidden  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-features mb-5'>
                            <h2 className='service-banner-heading fw-bold'>Showcase<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='showcase-slider'>
                            <ShowcaseSlider showcaseSettings={showcaseSettings} showcasedata={showcasedata} />
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */}
            

            <section>
                <div className='about-service-wrapper atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='fw-bold'>Technologies used<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='copywritting-para'>
                            <div className='row technologies-card-wrapper'>
                                <TechnologyCard technologydata={technologydata}/>
                            </div>
                            <div className='testimonal-slider technology-slider-wrapper'>
                                <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Technologies used */}

            {/* <section>
                <div className='digitech-services-wrapper pt-0'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            <h2 className='fw-bold'>Testimonials<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='testimonal-slider testimonial-slider-wrapper'>
                            <TestimonialSlider testimonialSetting={testimonialSetting} testimonialdata={testimonialdata} />
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className='company-service-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='company-inner-service'>
                            <h2 className='faqs-big-heading  fw-bold'>Who can Benefit from Multi-site Setup?</h2> 
                            <div className='company-service-box'>
                                <div className='row'>
                                    <GetDoneCards getdonedata={getdonedata}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='faqs-wrapper'>  
                        <h6 className='comet-brand-color  theme-font-family'>FAQs</h6>   
                        <h2 className='faqs-big-heading fw-bold'>Frequently Asked Questions</h2> 
                        <Accordion className='faqs-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                            <FaqsAccordion accordionData={accordionData} />
                        </Accordion>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} />
        </>
    )
}

export default Comet

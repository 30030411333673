import React from 'react'
import Card from 'react-bootstrap/Card';

function TechnologyCard({technologydata}) {
  return (
    <>
      {technologydata.map(item=>(
        <div className='col-lg-2 col-md-4 col-sm-6'>
            <Card className={`digitech-technology-card text-center rounded-0 ${item.marketingCards} ${item.marketinSpacing}`}>
                <Card.Img className={`m-auto ${item.cardImage}`} src={item.TechnologyImage} alt='' title='Branding' />
                <Card.Body  className='p-0 mt-4'>
                    <Card.Title className='mb-0'>{item.technologyTitle}</Card.Title>
                </Card.Body>
            </Card>
        </div>))}
    </>
  )
}

export default TechnologyCard

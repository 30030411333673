import React from 'react'
import { Card } from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md'

function GetDoneCards({getdonedata}) {
    return (
        <>
            {getdonedata.map(item=>(
                <div className='col-lg-4 col-md-6'>
                    <Link to='/consult' className='text-decoration-none'>
                        <Card className={`rounded-0 get-done-cards ${item.getDonecard}`}>
                            <Card.Body className='d-flex justify-content-between p-0'>
                                <div className='get-done-left'>
                                    <Card.Title className='mb-0 fs-4 fw-semibold'>{item.getDoneTitle}</Card.Title>
                                    <Card.Text className='mt-4 w-100'>{item.getDonePara}</Card.Text>
                                </div>
                                <div className='get-done-right'>
                                    <Link to='' className='get-done-btn'><MdArrowOutward /></Link>
                                </div>
                            </Card.Body>
                        </Card>
                    </Link>
                </div>
            ))}
        </>
    )
}

export default GetDoneCards

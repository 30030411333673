import React from 'react'
import FolioCard from '../../components/FolioCard'
import folioImage1 from '../../assets/images/folio-1.webp'
import folioImage2 from '../../assets/images/folio-2.webp'
import folioImage3 from '../../assets/images/folio-3.webp'
import folioImage4 from '../../assets/images/folio-7.webp'
import folioImage5 from '../../assets/images/folio-5.webp'
import folioImage6 from '../../assets/images/folio-9.webp'
import folioImage7 from '../../assets/images/folio-4.webp'
import folioImage8 from '../../assets/images/folio-11.webp'
import folioImage9 from '../../assets/images/folio-6.webp'
import folioImage10 from '../../assets/images/folio-16.webp'
import folioImage11 from '../../assets/images/folio-14.webp'
import folioImage12 from '../../assets/images/folio-17.webp'
import folioImage13 from '../../assets/images/folio-13.webp'
import folioImage14 from '../../assets/images/folio-24.webp'
import folioImage15 from '../../assets/images/folio-15.webp'
import folioImage16 from '../../assets/images/folio-21.webp'
import folioImage17 from '../../assets/images/folio-12.webp'
import folioImage18 from '../../assets/images/folio-18.webp'
import folioImage19 from '../../assets/images/folio-8.webp'
import folioImage20 from '../../assets/images/folio-23.webp'
import folioImage21 from '../../assets/images/folio-10.webp'
import folioImage22 from '../../assets/images/folio-19.webp'
import folioImage23 from '../../assets/images/folio-20.webp'
import folioImage24 from '../../assets/images/folio-22.webp'
import ConsultUs from '../../components/ConsultUs';

function DigitechPortfolio() {
    const foliodata = [
        {FolioImage: folioImage1, FolioSmallHeading: 'Mobile App', FolioHeading: 'Freshkart Farms'},
        {FolioImage: folioImage2, FolioSmallHeading: 'Brand Design', FolioHeading: 'Atmosphere Living', folioLink: ''},
        {FolioImage: folioImage3, FolioSmallHeading: 'Mobile App', FolioHeading: '3i schools'},
        {FolioImage: folioImage4, FolioSmallHeading: 'Brand Design', FolioHeading: 'Healthism Plus'},
        {FolioImage: folioImage5, FolioSmallHeading: 'Mobile App', FolioHeading: 'Healthism Plus', cardFive: 'healthism-card'},
        {FolioImage: folioImage6, FolioSmallHeading: 'Mobile App', FolioHeading: 'Raheja Developers'},
        {FolioImage: folioImage7, FolioSmallHeading: 'Software Dev.', FolioHeading: 'KloudStory'},
        {FolioImage: folioImage8, FolioSmallHeading: 'UI/UX', FolioHeading: 'Payment Gateway Services'},
        {FolioImage: folioImage9, FolioSmallHeading: 'Mobile App', FolioHeading: 'Namoology'},
        {FolioImage: folioImage10, FolioSmallHeading: 'Software Dev.', FolioHeading: '3ischools'},
        {FolioImage: folioImage11, FolioSmallHeading: 'Web Dev.', FolioHeading: 'The Ozen Collection', cardFive: 'healthism-card', folioLink: ''},
        {FolioImage: folioImage12, FolioSmallHeading: 'Brand Communication', FolioHeading: 'Atmosphere Living'},
        {FolioImage: folioImage13, FolioSmallHeading: 'Web Dev.', FolioHeading: 'Atmosphere Living'},
        {FolioImage: folioImage14, FolioSmallHeading: 'Software Dev.', FolioHeading: 'RCG'},
        {FolioImage: folioImage15, FolioSmallHeading: 'Web Dev.', FolioHeading: '3ischools'},
        {FolioImage: folioImage16, FolioSmallHeading: 'Software Dev.', FolioHeading: 'Bizzpulse'},
        {FolioImage: folioImage17, FolioSmallHeading: 'Software Dev.', FolioHeading: 'Freshkart', cardFive: 'healthism-card'},
        {FolioImage: folioImage18, FolioSmallHeading: 'Software Dev.', FolioHeading: 'Aztel'},
        {FolioImage: folioImage19, FolioSmallHeading: 'Software Dev.', FolioHeading: 'Solfin - Sustainable Finance'},
        {FolioImage: folioImage20, FolioSmallHeading: 'UI/UX', FolioHeading: '3ischools'},
        {FolioImage: folioImage21, FolioSmallHeading: 'Web Dev.', FolioHeading: 'Atmosphere Hotels & Resorts', folioLink: ''},
        {FolioImage: folioImage22, FolioSmallHeading: 'Software Dev.', FolioHeading: 'Comet', largeFolioHeading: 'comet-folio-heading'},
        {FolioImage: folioImage23, FolioSmallHeading: 'Web Dev.', FolioHeading: 'Hummings', cardFive: 'healthism-card'},
        {FolioImage: folioImage24, FolioSmallHeading: 'Software Dev.', FolioHeading: 'Games Stores', folioLink: ''}
    ]

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Let's get started!", consultPara: "Whether you're optimising operations, expanding reach, or enhancing customer satisfaction, our solutions cover software, mobile app development, and websites.", consultPortfolio: 'faqs-consult-wrapper', pageLink: '/consult', LinkText: 'get started'}
    ]
    return (
        <>
            <section>
                <div className='digitech-folio header-space'>
                    <div className='container'>
                        <div className='row'>
                            <FolioCard foliodata={foliodata}/>
                        </div>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} showheading={false} showSmallHeading={false} />
        </>
    )
}

export default DigitechPortfolio

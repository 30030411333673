import React from 'react'
import ServiceBanner from '../../components/ServiceBanner'
import { MdArrowOutward } from 'react-icons/md'
import BannerImage from '../../assets/images/brand-bg.webp'
import { Link } from 'react-router-dom'
import parse from 'html-react-parser'
import offerImageOne from '../../assets/images/brand-img.webp'
import offerImageTwo from '../../assets/images/brand-img-2.webp'
import offerImageThird from '../../assets/images/brand-img-3.webp'
import EcommerceFeaturesCardsSecond from '../../components/EcommerceFeaturesCardsSecond'
import ecommerceImage from '../../assets/images/customized-strategies.svg'
import ecommerceImageTwo from '../../assets/images/creative-excellence.svg'
import ecommerceImageThree from '../../assets/images/consistency.svg'
import ecommerceImageFour from '../../assets/images/measured-results.svg'
import ecommerceImageFive from '../../assets/images/impactful-message.svg'
import ecommerceImageSix from '../../assets/images/tailored-services.svg'
import EcommerceFeaturesCards from '../../components/EcommerceFeaturesCards'
import EcommerceShowcaseSlider from '../../components/EcommerceShowcaseSlider'
import showcaseImgOne from '../../assets/images/brand-showcase-1.webp'
import showcaseImgTwo from '../../assets/images/brand-design-4.webp'
import showcaseImgThree from '../../assets/images/brand-showcase-3.webp'
import showcaseImgFour from '../../assets/images/brand-design-5.webp'
import showcaseImgFive from '../../assets/images/brand-design-6.webp'
import showcaseImgSix from '../../assets/images/brand-showcase-2.webp'
import showcaseImgSeven from '../../assets/images/brand-design-7.webp'
import StatisticsCards from '../../components/StatisticsCards'
import StatisticImageLarge from '../../assets/images/branding-stattistics.webp'
import StatisticImage  from '../../assets/images/report-up.svg'
import GetDoneCards from '../../components/GetDoneCards'
import { Accordion } from 'react-bootstrap-v5'
import FaqsAccordion from '../../components/FaqsAccordion'
import ConsultUs from '../../components/ConsultUs'
import OverlappingBrandBoxes from '../../components/OverlappingBrandBoxes'

function BrandCommunication() {
    const servicedata= [
        {bannerSmallHeading:'Branding & Communication', bannerHeading:"Nurturing Your Brand with Care and Precision", bannerImage: BannerImage, cometMainBanner: 'mob-main-banner', smallHeadingColor: 'mob-heaidng-color', webBannerHeading:'ecommerce-heading'}
    ]

    const paraaboutservice= "<span className=\"letter-size\">A</span>t Digitech's Brand Design Services, we specialize in elevating brands through innovative and creative design solutions. With a focus on graphic design, logo design, visual design, marketing, and creative communications, we help businesses stand out in today's competitive market."

    // const paraaboutserviceOne= 'Lorem Ipsum has been the industrys standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text.'

    const brandoverlapdata = [
        {overlapHeading: 'Brand Design', overlapPara: "Our experienced team of designers and copywriters collaborates to craft captivating visuals and compelling content that bring your brand to life and effectively communicate your message", overlapLink:'Next', overlapClassOne: 'overlap-box-first', offerImageThird: offerImageOne, brandImage:'brand-img-wrapper', brandOverlapping: 'brand-Overlapping-wrapper'}, 

        {overlapHeading: 'Creative Communications', overlapPara: "Our innovative marketing strategies and creative communication tactics help you connect with your audience on a deeper level, driving engagement and loyalty.", overlapLink:'Next', overlapClassSecond: 'overlap-box-second', offerImageThird: offerImageTwo, brandImage:'brand-img-wrapper', brandOverlapping: 'brand-Overlapping-wrapper'}, 

        {overlapHeading: 'Visual Design', overlapPara: "We develop cohesive visual branding elements that reinforce your brand identity and create a memorable brand experience for your audience.", overlapLink:'Next', overlapClassThird: 'overlap-box-third', offerImageThird: offerImageThird, brandImage:'brand-img-wrapper', brandOverlapping: 'brand-Overlapping-wrapper'}
    ]

    const ecommercedata = [
        {ecommerceImage: ecommerceImage, ecommerceTitle:'Strategic Approach', ecommerceText: 'Effectively conveys your brand message with impact.'},
        {ecommerceImage: ecommerceImageTwo, ecommerceTitle:'Creative Excellence', ecommerceText: 'Deliver exceptional results filled with passion & creativity.', featureImageWrapper: 'ecommerce-feature-img', brandFeatures: 'brand-features-wrapper'},
        {ecommerceImage: ecommerceImageThree, ecommerceTitle:'Consistency', ecommerceText: 'Reinforces your brand identity & messaging across all mediums.'}
    ]

    const ecommercedatasecond = [
        {ecommerceImage: ecommerceImageFour, ecommerceTitle:'Measured Results', ecommerceText: 'Achieve increased brand awareness to customer engagement.', featureImageWrapper: 'ecommerce-feature-img', brandFeatures: 'brand-features-wrapper', brandresponsive: 'brand-feature-responsive'},
        {ecommerceImage: ecommerceImageFive, ecommerceTitle:'Impactful Messaging', ecommerceText: 'Crafts messaging that resonates with your audience.'},
        {ecommerceImage: ecommerceImageSix, ecommerceTitle:'Tailored Services', ecommerceText: 'Custom solutions to meet your specific needs and goals.', featureImageWrapper: 'ecommerce-feature-img', brandFeatures: 'brand-features-wrapper'}
    ]

    const ecommerceSettings = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 3,
              }
            },{
                breakpoint: 992,
                settings: {
                    slidesToShow: 2,
                }
              },{
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                }
              },{
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                }
              }
          ]
    };

    const ecommarcedata = [
        {ecommarceimageUrl: showcaseImgOne, ecommerceSubTitle: 'Atmosphere Living', ecommerceTitle:'Customer Cards', brandresponsive: 'brand-responsive-image', hidebrandresponsive: 'show-brand-responsive'},
        {ecommarceimageUrl: showcaseImgTwo, ecommerceSubTitle: 'Atmosphere Living', ecommerceTitle:'Brand Design', brandresponsive: 'brand-responsive-image', hidebrandresponsive: 'show-brand-responsive'},
        {ecommarceimageUrl: showcaseImgThree, ecommerceSubTitle: 'Healthism', ecommerceTitle:'Merchandise', brandresponsive: 'brand-responsive-image', hidebrandresponsive: 'show-brand-responsive'},
        {ecommarceimageUrl: showcaseImgFour, ecommerceSubTitle: 'Atmosphere Living', ecommerceTitle:'Salto Cards', brandresponsive: 'brand-responsive-image', hidebrandresponsive: 'show-brand-responsive'},
        {ecommarceimageUrl: showcaseImgFive, ecommerceSubTitle: 'Atmosphere Living', ecommerceTitle:'Stationery', brandresponsive: 'brand-responsive-image', hidebrandresponsive: 'show-brand-responsive'},
        {ecommarceimageUrl: showcaseImgSix, ecommerceSubTitle: 'Atmosphere Living', ecommerceTitle:'Mugs & Cups', brandresponsive: 'brand-responsive-image', hidebrandresponsive: 'show-brand-responsive'},
        {ecommarceimageUrl: showcaseImgSeven, ecommerceSubTitle: 'Atmosphere Living', ecommerceTitle:'Business Cards', brandresponsive: 'brand-responsive-image', hidebrandresponsive: 'show-brand-responsive'}
    ]

    const statisticsdata = [
        {statisticsTitle:' 32%', statisticsImage: StatisticImage, statisticsPara: 'Brand Visibilty', softwareStatistics: 'software-inner-statistics'}, 
        {statisticsTitle:'40%', statisticsImage: StatisticImage, statisticsPara: 'Customer Engagement', statisticscard: 'statistics-wrapper-two', softwareStatistics: 'software-inner-statistics', brandCommunication: 'brand-communication-img'}, 
        {statisticsTitle:'25%', statisticsImage: StatisticImage, statisticsPara: 'Sales Revenue', resultCardTwo: 'statitics-cards-third', softwareStatistics: 'software-inner-statistics'}
    ]

    const statisticHeading= 'End Results'
    const parastatistic= "Through our brand design services, we've helped numerous brands achieve their goals and elevate their presence in the market. Here are three stats showcasing our impact"

    // const testimonialSetting = {
    //     arrow: false,
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 1199,
    //           settings: {
    //             slidesToShow: 1,
    //           }
    //         }
    //     ]
    // };

    // const testimonialdata = [
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'}
    // ]

    const getdonedata = [
        {getDoneTitle:'Graphic Design', getDoneImage: StatisticImage, getDonePara: 'Captivating visuals bring your brand to life and communicate effectively.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Logo Design', getDoneImage: StatisticImage, getDonePara: 'Crafted logos reflect your brand identity and leave a lasting impression.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Marketing Communications', getDoneImage: StatisticImage, getDonePara: 'Drive deeper audience connections, enhancing engagement and loyalty.', getDonecard: 'comet-get-done-wrapper'},
        {getDoneTitle:'Visual Branding', getDoneImage: StatisticImage, getDonePara: 'Cohesive elements reinforce your identity, creating memorable experiences.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Content Creation', getDoneImage: StatisticImage, getDonePara: 'Compelling content resonates with your audience, strengthening brand messaging.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Social Media Management', getDoneImage: StatisticImage, getDonePara: 'Managed presence engages audience, amplifying brand reach.', getDonecard: 'comet-get-done-wrapper'}
    ]

    const accordionData = [
        {
            planEventKey:0, planTabHeading: 'How long does it take to complete a brand design project?', planTabText: "The timeline for each project varies depending on the scope and complexity. We work closely with our clients to establish realistic timelines and deliver projects on time."
        }, 
        {
            planEventKey:1, planTabHeading: 'Do you offer out-of-the-box solutions for impactful brand communication?', planTabText: "Yes, we specialize in providing innovative and unconventional approaches to brand communication. Our team is dedicated to creating unique strategies and solutions that set your brand apart and captivate your audience."
        },
        {
            planEventKey:2, planTabHeading: 'Can you work with existing brand guidelines?', planTabText: "Absolutely! We can work with existing brand guidelines to maintain consistency across all design materials and platforms."
        },
        {
            planEventKey:3, planTabHeading: 'What sets Digitech apart from other design agencies?', planTabText: "At Digitech, we combine creative excellence with strategic thinking to deliver impactful design solutions that drive results. Our focus on innovation and customer-centric approach sets us apart from other agencies."
        }
    ]

    const defaultActiveKey = accordionData.length > 0 ? accordionData[0].planEventKey : null;

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Elevate your brand communication with our expertise.", consultPara: "Reach out to us today and unlock tailored solutions meticulously crafted to resonate deeply with your audience, ensuring every touchpoint with your brand is a catalyst for success.", consultPortfolio: 'faqs-consult-wrapper', cometConsultBtn: 'mob-consult-btn', pageLink: '/consult', LinkText: 'get started'}
    ]

    return (
        <>
            <ServiceBanner servicedata={servicedata}/>

            <section>
                <div className='about-service-wrapper'>
                    <div className='container'>
                        <div className='service-about-para'>
                            <p>{parse(paraaboutservice)}</p>
                            {/* <p>{parse(paraaboutserviceOne)}</p> */}
                            <div className='service-btn-wrapper'>
                                <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */} 

            <section>
                <div className='overlapping-main-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row comet-responsive-column align-items-center'>
                            <div className='col-lg-6'>
                                <div className='features-heading offer-heading-wrapper'>
                                    <h2 className='mb-0 fw-bold'>We Help you Elevate your Brand<span className='brand-color'>.</span></h2>
                                </div>  
                            </div>
                            <div className='col-lg-6'>
                                <div className='paragraph-wrapper'>
                                    <p className='mb-0'>We elevate your brand through visually stunning designs and innovative marketing strategies, ensuring it stands out and resonates with your audience.</p>
                                </div>
                            </div>
                        </div> 
                        <OverlappingBrandBoxes brandoverlapdata={brandoverlapdata}/>
                    </div>
                </div>
            </section>

            <section>
                <div className='comet-features-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                    <div className='benefits-wrapper d-flex'>
                            <div className='benefits-heading-wrapper'>
                                <div className='features-heading mb-5'>
                                    <h2 className='mb-0 fw-bold'>Benefits<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            <div className='benefits-content-wrapper'>
                                <div className='features-inner-cards mt-0'>
                                    <div className='row'>
                                        <div className='col-lg-6 mb-0 col-md-12 col-sm-12'>
                                            <EcommerceFeaturesCards ecommercedata={ecommercedata} />
                                        </div>
                                        <div className='col-lg-6 mb-0 col-md-12 col-sm-12 mt-lg-0 mt-md-4'>
                                            <EcommerceFeaturesCardsSecond ecommercedatasecond={ecommercedatasecond} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper overflow-hidden atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='features-heading'>
                            <h2 className='mb-0 fw-bold'>Showcase<span className='brand-color'>.</span></h2>
                        </div>  
                        <div className='ecommerce-showcase-slider brand-slider'>
                            <EcommerceShowcaseSlider ecommerceSettings={ecommerceSettings} ecommarcedata={ecommarcedata} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study overflow-hidden'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-12'>
                                <div className='statistics-wrapper-left'>
                                    <h2 className='fw-bold'>{statisticHeading}<span className='brand-color'>.</span></h2>
                                    <p className='statistics-para'>{parse(parastatistic)}</p>
                                    <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12'>
                                <div className='statistics-right d-flex'>
                                    <div className='statistics-inner-wrapper software-statistics-wrapper'>
                                        <StatisticsCards statisticsdata={statisticsdata} />
                                    </div>
                                    <div className='statistics-img'>
                                        <img src={StatisticImageLarge} alt='' title='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className='digitech-services-wrapper pt-0'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            <h2 className='fw-bold'>Testimonials<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='testimonal-slider testimonial-slider-wrapper'>
                            <TestimonialSlider testimonialSetting={testimonialSetting} testimonialdata={testimonialdata} />
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className='company-service-wrapper  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='company-inner-service'>
                            <h2 className='faqs-big-heading  fw-bold'>High Impact Services for Creative Communication</h2> 
                            <div className='company-service-box'>
                                <div className='row'>
                                    <GetDoneCards getdonedata={getdonedata}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='faqs-wrapper'>  
                        <h6 className='comet-brand-color text-uppercase theme-font-family'>FAQs</h6>   
                        <h2 className='faqs-big-heading fw-bold'>Frequently Asked Questions</h2> 
                        <Accordion className='faqs-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                            <FaqsAccordion accordionData={accordionData} />
                        </Accordion>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} />
        </>
    )
}

export default BrandCommunication

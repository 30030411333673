import React from 'react'

function PricingBanner({pricingdata}) {
    return (
        <>
            {pricingdata.map(item =>(
                <section>
                    <div className='pricing-banner-wrappeer header-space'>
                        <div className='container'>
                            <div className='pricing-inner text-center'>
                                <h3>{item.pricingTitle}</h3>
                                <p>{item.pricingPara}</p>
                            </div> 
                        </div>
                    </div>
                </section>
            ))}
        </>
    )
}

export default PricingBanner

import React from 'react'
import parse from 'html-react-parser'
import { MdArrowOutward } from 'react-icons/md'
import ServiceBanner from '../../components/ServiceBanner'
import BannerImage from '../../assets/images/ecommerce-bg.webp'
import { Link } from 'react-router-dom'
import TechnologyCard from '../../components/TechnologyCard'
import TechnologyImage from '../../assets/images/node-js.webp'
import TechnologyImage2 from '../../assets/images/express-js.webp'
import TechnologyImage3 from '../../assets/images/react.webp'
import TechnologyImage4 from '../../assets/images/html.webp'
import TechnologyImage5 from '../../assets/images/css.webp'
import TechnologyImage6 from '../../assets/images/postgresql.webp'
import FaqsAccordion from '../../components/FaqsAccordion'
import { Accordion } from 'react-bootstrap-v5'
import GetDoneCards from '../../components/GetDoneCards'
import ConsultUs from '../../components/ConsultUs'
import FeaturesUsedCard from '../../components/FeaturesUsedCard'
import FeaturesImage from '../../assets/images/content-marketing.webp'
import FeaturesImage2 from '../../assets/images/game-lead-management.webp'
import FeaturesImage3 from '../../assets/images/offers-deals.webp'
import EcommerceFeaturesCards from '../../components/EcommerceFeaturesCards'
import featureImage from '../../assets/images/freshkart-mockup-mob.webp'
import ecommerceImage from '../../assets/images/one-time.svg'
import ecommerceImageTwo from '../../assets/images/no-price-caps.svg'
import ecommerceImageThree from '../../assets/images/hosting-control.svg'
import ecommerceImageFour from '../../assets/images/dynamic-promotions.svg'
import ecommerceImageFive from '../../assets/images/multi-house-warehousing.svg'
import ecommerceImageSix from '../../assets/images/multi-shop-capability.svg'
import EcommerceFeaturesCardsSecond from '../../components/EcommerceFeaturesCardsSecond'
import EcommerceShowcaseSlider from '../../components/EcommerceShowcaseSlider'
import offerImgOne from '../../assets/images/ecommerce-img-one.webp'
import offerImgTwo from '../../assets/images/ecommerce-img-second.webp'
import offerImgThree from '../../assets/images/ecommerce-img-third.webp'
import offerImgFour from '../../assets/images/ecommerce-img-four.webp'
import offerImgFive from '../../assets/images/ecommerce-img-five.webp'
import offerImgSix from '../../assets/images/ecommerce-img-six.webp'
import offerImgSeven from '../../assets/images/ecommerce-img-seven.webp'
import offerImgEight from '../../assets/images/ecommerce-img-eight.webp'
import offerImgNine from '../../assets/images/ecommerce-img-nine.webp'
import WeWorkOnSlider from '../../components/WeWorkOnSlider'
import responsiveOne from '../../assets/images/e-commerce-mob-1.webp'
import responsiveTwo from '../../assets/images/e-commerce-mob-2.webp'
import responsiveThree from '../../assets/images/bh-mob-front-view.webp'
import responsiveFour from '../../assets/images/bfsg-mob-front-view.webp'
import responsiveFive from '../../assets/images/filll-mob-front-view.webp'
import responsiveSix from '../../assets/images/hummings-mob-front-view.webp'
import responsiveSeven from '../../assets/images/intex-mob-front-view.webp'
import responsiveEight from '../../assets/images/signoraware-mob-front-view.webp'
import responsiveNine from '../../assets/images/medmom-mob-front-view.webp'
import EcommerceLogoSlider from '../../components/EcommerceLogoSlider'
import ecommarceLogoOne from '../../assets/images/freshkart-logo.png'
import ecommarceLogoTwo from '../../assets/images/hummings-logo.png'
import ecommarceLogoThree from '../../assets/images/bfsg-logo.png'
import ecommarceLogoFour from '../../assets/images/intex-logo.png'
import ecommarceLogoFive from '../../assets/images/filll-logo.svg'
import ecommarceLogoSix from '../../assets/images/signora-ware-logo.png'
import ecommarceLogoSeven from '../../assets/images/medmom-logo.png'
import ecommarceLogoEight from '../../assets/images/games-stores-logo.png'


function ECommerce() {
    const servicedata= [
        {bannerSmallHeading:'COMET Shop', bannerHeading:"E-commerce Solution without limitations", bannerImage: BannerImage, cometMainBanner: 'ecommerce-main-banner', smallHeadingColor: 'ecommerce-heaidng-color', webBannerHeading:'ecommerce-heading'}
    ]

    const paraaboutservice = "<span className=\"letter-size\">E</span>xperience e-commerce without limitations with COMET Shop. Our revolutionary platform offers bespoke solutions tailored to your needs, ensuring unparalleled flexibility and control. Unlike other solutions, we empower you with unlimited possibilities, from product listings to user management. Discover the freedom to grow your online business without constraints.";


    // const paraaboutserviceOne= 'Lorem Ipsum has been the industrys standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text.'

    const technologydata = [
        {TechnologyImage: TechnologyImage, technologyTitle:'Node Js'},
        {TechnologyImage: TechnologyImage2, technologyTitle:'Express Js'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'React'},
        {TechnologyImage: TechnologyImage4, technologyTitle:'HMTL 5'},
        {TechnologyImage: TechnologyImage5, technologyTitle:'CSS 3'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'PostgreSQL'}
    ]

    const getdonedata = [
        {getDoneTitle:'Custom Shop Design', getDonePara: 'Create visually stunning online stores tailored to your brand.', getDonecard: 'ecommerce-get-done-wrapper'}, 
        {getDoneTitle:'Platform Setup', getDonePara: 'Choose from popular e-commerce platforms like Shopify, Magento or WooCommerce', getDonecard: 'ecommerce-get-done-wrapper'}, 
        {getDoneTitle:'SEO Optimization', getDonePara: ' Improve search engine visibility & attract more customers.', getDonecard: 'ecommerce-get-done-wrapper'},
        {getDoneTitle:'Shop Promotion', getDonePara: 'Drive traffic and boost sales with strategic campaigns.', getDonecard: 'ecommerce-get-done-wrapper'}, 
        {getDoneTitle:'Email Marketing', getDonePara: 'Boost sales with personalized email campaigns.', getDonecard: 'ecommerce-get-done-wrapper'}, 
        {getDoneTitle:'Support & Maintenance', getDonePara: 'Access ongoing support for smooth operations.', getDonecard: 'ecommerce-get-done-wrapper'}
    ]

    const accordionData = [
        {
            planEventKey:0, planTabHeading: 'Can you provide custom design for my online store?', planTabText: "Absolutely! We understand the importance of branding. Our team works closely with you to create a visually stunning and cohesive online shop presence that leaves a lasting impression on your customers."
        }, 
        {
            planEventKey:1, planTabHeading: 'Is there a limit on the number of shops, products/items, or users?', planTabText: "No, with COMET Shop, there are no limits. You can create and manage as many shops, products/items, and users as you need to support your business growth."
        },
        {
            planEventKey:2, planTabHeading: 'Can I host it on my server?', planTabText: "Certainly! At COMET Shop, we offer you the flexibility to choose. Whether you prefer to host your e-commerce platform with us or on your own server, rest assured that you'll have maximum security and control over your online business either way."
        },
        {
            planEventKey:3, planTabHeading: 'Do you offer ongoing support and updates?', planTabText: "We provide continuous support and regular updates to ensure that your online store operates smoothly. Additionally, we are constantly adding new features and making quality-of-life improvements to enhance your e-commerce experience."
        }
    ]

    const defaultActiveKey = accordionData.length > 0 ? accordionData[0].planEventKey : null;

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Unlock Your E-commerce Potential with COMET Shop!", consultPara: "Ready to elevate your e-commerce game? Unleash the power of COMET Shop! Quickly set up shop and sell online with seamless integration of popular payment gateways.", pageLink: '/consult', LinkText: 'get started'}
    ]

    const featuresuseddata = [
        {featuresImage: FeaturesImage, featuresTitle:'Easy Cataloguing', featureText:'Effortlessly organize and showcase your products.'},
        {featuresImage: FeaturesImage2, featuresTitle:'Inventory Manager', featureText:'Keep track of stock levels and streamline inventory management.'},
        {featuresImage: FeaturesImage3, featuresTitle:'POS Integration', featureText:'Quickly integrate for popular payment gateways.'}
    ]

    const FeaturesPara="Effortlessly organise and showcase your products with COMET Shop's easy cataloguing feature, while seamlessly managing inventory levels and streamlining operations through its Inventory Manager."

    const ecommercedata = [
        {ecommerceImage: ecommerceImage, ecommerceTitle:'One-Time Cost', ecommerceText: 'One-time cost for a lifetime license.'},
        {ecommerceImage: ecommerceImageTwo, ecommerceTitle:'No Price Caps', ecommerceText: 'Unlimited products, inventory, stores, and users.', featureImageWrapper: 'ecommerce-feature-img'},
        {ecommerceImage: ecommerceImageThree, ecommerceTitle:'Hosting Control', ecommerceText: 'Host on your server for complete control & security.'}
    ]

    const ecommercedatasecond = [
        {ecommerceImage: ecommerceImageFour, ecommerceTitle:'Dynamic Promotions', ecommerceText: 'Drive sales with built-in coupons, deals, and offers.', featureImageWrapper: 'ecommerce-feature-img'},
        {ecommerceImage: ecommerceImageFive, ecommerceTitle:'Multi-House Warehousing', ecommerceText: 'Manage inventory across multiple warehouses.'},
        {ecommerceImage: ecommerceImageSix, ecommerceTitle:'Multi-Shop Capability', ecommerceText: 'Ability to manage multiple online shops.', featureImageWrapper: 'ecommerce-feature-img'}
    ]

    const ecommerceSettings = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
              }
            },{
                breakpoint: 767,
                settings: {
                    arrow: false,
                }
              }
          ]
    };

    const ecommarcedata = [
        {ecommarceimageUrl:offerImgOne, ecommerceSubTitle: 'Gaming', ecommerceTitle:'Games Stores', MobResponsiveImage: responsiveOne},
        {ecommarceimageUrl:offerImgTwo, ecommerceSubTitle: 'Food and Delivery', ecommerceTitle:'Freshkart Farms', MobResponsiveImage: responsiveTwo},
        {ecommarceimageUrl:offerImgSix, ecommerceSubTitle: 'Education', ecommerceTitle:'Hummings', MobResponsiveImage: responsiveSix},  
        {ecommarceimageUrl:offerImgFive, ecommerceSubTitle: 'Fragrances', ecommerceTitle:'Filll', MobResponsiveImage: responsiveFive},
        {ecommarceimageUrl:offerImgFour, ecommerceSubTitle: 'Beauty', ecommerceTitle:'BFSG', MobResponsiveImage: responsiveFour},
        {ecommarceimageUrl:offerImgThree, ecommerceSubTitle: 'Fashion & Clothing', ecommerceTitle:'Being Human (Under Development)', MobResponsiveImage: responsiveThree},
        {ecommarceimageUrl:offerImgSeven, ecommerceSubTitle: 'Electronics', ecommerceTitle:'INTEX', MobResponsiveImage: responsiveSeven},
        {ecommarceimageUrl:offerImgEight, ecommerceSubTitle: 'Home Essentials', ecommerceTitle:'SignoraWare', MobResponsiveImage: responsiveEight},
        {ecommarceimageUrl:offerImgNine, ecommerceSubTitle: 'Healthcare', ecommerceTitle:'Medmom Pharma', MobResponsiveImage: responsiveNine},
    ]

    // const testimonialSetting = {
    //     arrow: false,
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 1199,
    //           settings: {
    //             slidesToShow: 1,
    //           }
    //         }
    //     ]
    // };

    // const testimonialdata = [
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'}
    // ]

    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },

            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

    const images = [
        {softwareImage: TechnologyImage, workText:'Node Js'},
        {softwareImage: TechnologyImage2, workText:'Express Js'},
        {softwareImage: TechnologyImage3, workText:'React'},
        {softwareImage: TechnologyImage4, workText:'HTML 5'},
        {softwareImage: TechnologyImage5, workText:'CSS 3'},
        {softwareImage: TechnologyImage6, workText:'Postgres SQL'}
    ]

    const LogoSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 5,
                }
            },

            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                }
            },

            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 3,
                }
            },

            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 3,
                }
            },

            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                }
            },

        ]
    };

    const EcommerceSlider = [
        {ecommarceLogoUrl: ecommarceLogoOne, ecommerceAltText: "Logo", ecommerceTitle: "Logo"},
        {ecommarceLogoUrl: ecommarceLogoTwo, ecommerceAltText: "Logo", ecommerceTitle: "Logo", EcommerceClassView: 'ecommerce-img-hummings'},
        {ecommarceLogoUrl: ecommarceLogoThree, ecommerceAltText: "Logo", ecommerceTitle: "Logo", EcommerceClassView: 'ecommerce-img-bfsg'},
        {ecommarceLogoUrl: ecommarceLogoFour, ecommerceAltText: "Logo", ecommerceTitle: "Logo", EcommerceClassView: 'ecommerce-img'},
        {ecommarceLogoUrl: ecommarceLogoFive, ecommerceAltText: "Logo", ecommerceTitle: "Logo", EcommerceClassView: 'ecommerce-img'},
        {ecommarceLogoUrl: ecommarceLogoSix, ecommerceAltText: "Logo", ecommerceTitle: "Logo"},
        {ecommarceLogoUrl: ecommarceLogoSeven, ecommerceAltText: "Logo", ecommerceTitle: "Logo", EcommerceClassView: 'ecommerce-img'},
        {ecommarceLogoUrl: ecommarceLogoEight, ecommerceAltText: "Logo", ecommerceTitle: "Logo", EcommerceClassView: 'ecommerce-img'},
    ]

    return (
        <>
            <ServiceBanner servicedata={servicedata}/>   

            <section>
                <div className='ecommerce-product-logo'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='ecommerce-slider-wrapper'>
                            <EcommerceLogoSlider LogoSetting={LogoSetting} EcommerceSlider={EcommerceSlider} />
                        </div>
                    </div>
                </div>
            </section>
            {/* end service logos */}     

            <section>
                <div className='about-service-wrapper'>
                    <div className='container'>
                        <div className='service-about-para'>
                            <p>{parse(paraaboutservice)}</p>
                            {/* <p>{parse(paraaboutserviceOne)}</p> */}
                            <div className='service-btn-wrapper'>
                                <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */}  

            <section>
                <div className='comet-features-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row align-items-end'>
                            <div className='col-lg-12'>
                                <div className='features-heading'>
                                    <h2 className='mb-0 fw-bold'>Features<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className='features-inner-cards'>
                            <div className='row'>
                                <div className='col-lg-4 mb-0 col-md-12 col-sm-12'>
                                    <EcommerceFeaturesCards ecommercedata={ecommercedata} />
                                </div>
                                <div className='col-lg-4 col-md-12 col-sm-12'>
                                    <div className='features-img'>
                                        <img src={featureImage} title='' alt='' className='m-auto' />
                                    </div>
                                </div>
                                <div className='col-lg-4 mb-0 col-md-12 col-sm-12'>
                                    <EcommerceFeaturesCardsSecond ecommercedatasecond={ecommercedatasecond} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper overflow-hidden  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='features-heading'>
                            <h2 className='mb-0 fw-bold'>Showcase<span className='brand-color'>.</span></h2>
                        </div>  
                        <div className='ecommerce-showcase-slider'>
                            <EcommerceShowcaseSlider ecommerceSettings={ecommerceSettings} ecommarcedata={ecommarcedata} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='fw-bold'>Technologies used<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='copywritting-para'>
                            <div className='row technologies-card-wrapper'>
                                <TechnologyCard technologydata={technologydata}/>
                            </div>
                            <div className='testimonal-slider technology-slider-wrapper'>
                                <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Technologies used */}

            <section>
                <div className='about-atmosphere  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='copywritting-para'>
                            <div className='row align-items-center'>
                                <div className='col-lg-12 col-md-12'>
                                    <div className='unified-heading'>
                                        <h2 className='fw-bold'>Get Started with Online Selling Using COMET Shop<span className='brand-color'>.</span></h2>
                                    </div>
                                </div>
                                {/* <div className='col-lg-4 col-md-4'>
                                    <div className='unified-btn text-end'>
                                        <Link className='text-uppercase mt-4 d-inline-block theme-btn' to=''>View Packages <MdArrowOutward /></Link>
                                    </div>
                                </div> */}
                                <div className='col-lg-12'>
                                    <div className='features-paragraph mt-5'>
                                        <p>{parse(FeaturesPara)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <FeaturesUsedCard featuresuseddata={featuresuseddata}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Unified CMS features */}  

            {/* <section>
                <div className='digitech-services-wrapper pt-0'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            <h2 className='fw-bold'>Testimonials<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='testimonal-slider testimonial-slider-wrapper'>
                            <TestimonialSlider testimonialSetting={testimonialSetting} testimonialdata={testimonialdata} />
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className='company-service-wrapper  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='company-inner-service'>
                            <h2 className='faqs-big-heading  fw-bold'>E-commerce Services</h2> 
                            <div className='company-service-box'>
                                <div className='row'>
                                    <GetDoneCards getdonedata={getdonedata}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='faqs-wrapper'>  
                        <h6 className='comet-brand-color text-uppercase theme-font-family'>FAQs</h6>   
                        <h2 className='faqs-big-heading fw-bold'>Frequently Asked Questions</h2> 
                        <Accordion className='faqs-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                            <FaqsAccordion accordionData={accordionData} />
                        </Accordion>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} showSmallHeading={false} />
        </>
    )
}

export default ECommerce

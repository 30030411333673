import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

function FaqsAccordion({ accordionData }) {
    const [activeKey, setActiveKey] = useState(accordionData.length > 0 ? accordionData[0].planEventKey : null);

    const toggleAccordion = (eventKey) => {
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    return (
        <Accordion activeKey={activeKey} onSelect={toggleAccordion}>
            {accordionData.map((item, index) => (
                <Accordion.Item key={index} eventKey={item.planEventKey}>
                    <Accordion.Header>{item.planTabHeading}</Accordion.Header>
                    <Accordion.Body>
                        <p>{item.planTabText}</p>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default FaqsAccordion;

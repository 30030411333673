import React from 'react'
import { ListGroup } from 'react-bootstrap-v5'

function PaymentBoxes({paymentdata}) {
    return (
        <>
            {paymentdata.map(item =>(
                <ListGroup.Item as="li">
                    <div className='payment-inner-wrapper'>
                        <div className='payment-icon'>
                            <img src={item.paymentIcon} alt='' title='' />
                        </div>
                        <div className='payment-text'>
                            <h6>{item.paymentHeading}</h6>
                            <p className='mb-0'>{item.paymentPara}</p>
                        </div>
                    </div>
                </ListGroup.Item>
            ))}
        </>
    )
}

export default PaymentBoxes

import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function EcommerceLogoSlider({EcommerceSlider, LogoSetting}) {
    const settings = {...LogoSetting}
    return (
        <>
            <Slider {...settings}>
                {EcommerceSlider.map(item =>(
                    <div className='ecommerce-logo-slide'>
                        <img src={item.ecommarceLogoUrl} alt={item.ecommerceAltText} title={item.ecommerceTitle} className={`${item.EcommerceClassView}`} />
                    </div>  
                ))}
            </Slider>
        </>
    )
}

export default EcommerceLogoSlider

import React from "react";
import { Link } from "react-router-dom";

function HeaderDropdown({setDiplay, setExpanded, dropdowndata }) {
 
  const handelClick = () => {
    if(setDiplay){
      setDiplay(false)
    }
    if(setExpanded){
      setExpanded((prevExpanded) => !prevExpanded); // Toggle the state to keep track of whether the menu is expanded or not.
      document.body.classList.toggle("opened-menu"); // Toggle the 'opened-menu' class on the body.

    }
  };
  return (
    <div className="dropdown-content-wrapper">
      <ul className="dropdown-list-wrapper">
        {dropdowndata.map((item) => (
          <li
            key={item.id}
            onClick={handelClick}
          >
            <Link to={item.DropdownLink} className="dropdown-link">
              <div className="dropdown-inner-wrapper d-flex">
                <div className={`${item.leftBox} dropdown-img me-3`}>
                  <img src={item.dropdownImage} alt="icon" title="icon" />
                </div>
                <div className="dropdown-inner-item">
                  <h6 className="theme-font-family mb-0 work-heading dropdown-heading">
                    {item.DropdownHeading}
                  </h6>
                  <p>{item.DropdownPara}</p>
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      
    </div>
  );
}

export default HeaderDropdown;

import React from 'react'
import { Card } from 'react-bootstrap-v5'

function ServiceFeatureCard({servicefeaturecarddata}) {
    return (
        <>
            {servicefeaturecarddata.map(item=> (
                <div className='col-lg-6 col-md-6'>
                    <Card className='Service-feature-card'>
                        <Card.Img src={item.cardImage} alt='' title='Branding' />
                        <Card.Body  className='p-0 mt-3'>
                            <Card.Title>{item.cardTitle}</Card.Title>
                            <p className='pe-4'>{item.cardPara}</p>
                        </Card.Body>
                    </Card>
                </div>
            ))}
        </>
    )
}

export default ServiceFeatureCard

import React from 'react'

function MobFeatures({mobfeaturedata}) {
    return (
        <>
            {mobfeaturedata.map(item => (
                <div className={`mob-card-wrapper ${item.mobcardfeature} ${item.responsivecard} ${item.mobcardspace}`}>
                    <div className={`mob-card-icon ${item.mobIconColorChange} ${item.responsiveImage}  ${item.responsiveImageMobile}`}>
                        <img src={item.mobicon} alt='' title=''/>
                    </div>
                    <div className={`mob-content-wrapper ${item.mobContent}`}>
                        <h5 className='work-heading theme-font-family fw-semibold'> {item.mobHeading}</h5>
                        <p>{item.mobPara}</p>
                    </div>
                </div>
            ))}
        </>
    )
}

export default MobFeatures

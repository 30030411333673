import React from 'react'
import WorkSlider from './WorkSlider'
import workImage from '../assets/images/atmosphere-living.webp'
import workImageTwo from '../assets/images/shot-2.webp'
import workImageThree from '../assets/images/shot-3.webp'
import workImageFour from '../assets/images/shot-4.webp'
import workImageFive from '../assets/images/shot-5.webp'
import workImageSix from '../assets/images/shot-8.webp'
import workImageSeven from '../assets/images/shot-7.webp'
import workImageEight from '../assets/images/shot-6.webp'
import workImageNine from '../assets/images/fill-bg.webp'
import workImageTen from '../assets/images/hummings-bg.webp'
import workImageEleven from '../assets/images/bfsg-bg.webp'
import workImageTweleve from '../assets/images/medmom-bg.webp'
import workImageThirteen from '../assets/images/signora-ware-bg.webp'
import workreponsiveOne from '../assets/images/al-mob-front-view.webp'
import workreponsiveTwo from '../assets/images/raheja-mob-front-view.webp'
import workreponsiveThree from '../assets/images/freshkart-mob-front-view.webp'
import workreponsiveFour from '../assets/images/aztel-mob-front-view.webp'
import workreponsiveFive from '../assets/images/3ischools-mob-front-view.webp'
import workreponsiveSix from '../assets/images/ozen-mob-front-view.webp'
import workreponsiveSeven from '../assets/images/ahr-mob-front-view.webp'
import workreponsiveEight from '../assets/images/gs-mob-front-view.webp'
import workreponsiveNine from '../assets/images/filll-mob-front-view.webp'
import workreponsiveTen from '../assets/images/hummings-mob-front-view.webp'
import workreponsiveEleven from '../assets/images/bfsg-mob-front-view.webp'
import workreponsiveTweleve from '../assets/images/medmom-mob-front-view.webp'
import workreponsiveThirteen from '../assets/images/signoraware-mob-front-view.webp'

function Works() {
    const workSetting = {
      arrow: true,
      dots: false,
      centerMode: true,
      infinite: true,
      autoplay: false,
      speed: 900,
      cssEase: 'ease',
      slidesToShow: 1,
      centerPadding: '300px',
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1399,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            centerPadding: '200px',
          }
        },
        {
          breakpoint: 768,
          settings: {
            centerPadding: '60px',
          }
        },
        {
          breakpoint: 576,
          settings: {
            centerPadding: '30px',
          }
        },
        {
          breakpoint: 431,
          settings: {
            centerPadding: '20px',
          }
        }
      ]
    };

    const images = [
        {workImage:workImage, workPara:'Curated second home experiences, turning ordinary into extraordinary.', workHeading:'Atmosphere Living', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveOne},
        {workImage:workImageTwo, workPara:'All-in-One Solution for Property Listing, Booking, Sales, & Broker Management.', workHeading:'Raheja Developers', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveTwo},
        {workImage:workImageThree, workPara:'Facilitating swift delivery of farm-fresh produce directly from farmers.', workHeading:'Freshkart farms', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveThree},
        {workImage:workImageFour, workPara:'Calling cards, prepaid options, built-in POS, and unlimited Canada-wide calling.', workHeading:'Aztel', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveFour},
        {workImage:workImageFive, workPara:'Transforming education with automation, communication, & learning management.', workHeading:'3ischools', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveFive},
        {workImage:workImageSix, workPara:'Opulent, innovative designs transforming luxury resorts into lavish escapes.', workHeading:'The Ozen Collection', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveSix},
        {workImage:workImageSeven, workPara:'Redefining hotel and resort experiences for unparalleled indulgence.', workHeading:'Atmosphere Hotels & Resorts', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveSeven},
        {workImage:workImageEight, workPara:'Offering a diverse inventory, from games to hardware, consoles, and handhelds.', workHeading:'Games Stores', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveEight},
        {workImage:workImageNine, workPara:'Fragrances for hair, car, and home.', workHeading:'Filll', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveNine},
        {workImage:workImageTen, workPara:"Creative learning through kids' activity books.", workHeading:'Hummings', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveTen},
        {workImage:workImageEleven, workPara:'Your top source for unique beauty brands.', workHeading:'BFSG', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveEleven},
        {workImage:workImageTweleve, workPara:'Bridging the Gap to Health and Healing.', workHeading:'Medmom Pharma', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveTweleve},
        {workImage:workImageThirteen, workPara:'Crockery, Air & Liquid tight containers and Lunch Boxes', workHeading:'SignoraWare', learnMoreLink: 'View All', FoiloLink: '/portfolio', workResponsiveImg: workreponsiveThirteen}
    ]
  return (
    <>
      <section>
        <div className='digitech-services-wrapper atmosphere-case-study pb-0 overflow-hidden'>
            <div className='container-fluid custom-container-fluid'>
                <div className='service-card-heading d-flex align-items-center justify-content-between'>
                    <h2 className='fw-bold'>Our Portfolio<span className='brand-color'>.</span></h2>
                    {/* <Link to='/portfolio' className='text-decoration-none theme-btn text-uppercase'>View All <MdArrowOutward /></Link> */}
                </div>
            </div>
            <div className='work-slide'>
                <WorkSlider workSetting={workSetting} images={images} />
            </div>
        </div>
      </section>
    </>
  )
}

export default Works

import React from 'react'
import parse from 'html-react-parser'
import ServiceBanner from '../../components/ServiceBanner'
import BannerImage from '../../assets/images/service-banner-1.webp'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md'
import ServiceCardPage from '../../components/ServiceCardPage'
import ServiceFeatureCard from '../../components/ServiceFeatureCard'
import CardImage from '../../assets/images/eye-view.svg'
import CardImageTwo from '../../assets/images/powerful-cms.svg'
import CardImageThree from '../../assets/images/scalable.svg'
import CardImageFour from '../../assets/images/advanced-analytics-black.svg'
import ShowcaseSlider from '../../components/ShowcaseSlider'
import showcaseImage from '../../assets/images/showcase-1.webp'
import showcaseImageTwo from '../../assets/images/raya-by-atmosphere-wd.webp'
import showcaseImageThree from '../../assets/images/showcase-3.webp'
import showcaseImagefour from '../../assets/images/ozen-collection-showcase-wd.webp'
import StatisticsCards from '../../components/StatisticsCards'
import StatisticImage  from '../../assets/images/report-up.svg'
import StatisticImageLarge from '../../assets/images/statistics-img.webp'
import TechnologyCard from '../../components/TechnologyCard'
import TechnologyImage from '../../assets/images/php-logo.webp'
import TechnologyImage2 from '../../assets/images/react.webp'
import TechnologyImage3 from '../../assets/images/html.webp'
import TechnologyImage4 from '../../assets/images/css.webp'
import TechnologyImage5 from '../../assets/images/bootstrap-logo.webp'
import TechnologyImage6 from '../../assets/images/tailwind-css.webp'
import FaqsAccordion from '../../components/FaqsAccordion'
import Accordion from 'react-bootstrap/Accordion';
import ConsultUs from '../../components/ConsultUs'
import GetDoneCards from '../../components/GetDoneCards'
import responsiveImage from '../../assets/images/ozen-responsive.webp'
import MobFeatures from '../../components/MobFeatures'
import mobIconOne from '../../assets/images/dynamic-navigation.svg'
import mobIconTwo from '../../assets/images/accessibility.svg'
import mobIconThree from '../../assets/images/adaptive-content.svg'
import WeWorkOnSlider from '../../components/WeWorkOnSlider'

function WebDevelopment() {
    const servicedata= [
        {bannerSmallHeading:'Website Development', bannerHeading:'Unlock the Power of Your Web Presence', bannerImage: BannerImage, webBannerHeading: 'web-banner-heading'}
    ]

    const paraaboutservice = "<span className=\"letter-size\">W</span>elcome to the gateway of digital transformation! At Digitech, we don't just build websites; we craft immersive digital experiences tailored to elevate your brand above the digital noise. With our website development service, we're not just coding lines; we're sculpting your digital identity with scalable, cutting-edge technology that adapts and grows alongside your business needs.";


    // const paraaboutserviceOne= 'Lorem Ipsum has been the industrys standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text.'

    const servicecarddata= [
        {cardHeading: 'Clean', cardPara: 'Experience the seamless harmony of form and function as we infuse your digital vision with the elegance of simplicity. Our designs are sleek, clutter-free, and user-centric, ensuring every visitor feels at home on your website. '}, 
        {cardHeading: 'Personal Touch', cardPara: "Your brand is unique, and so should be your website. We infuse your brand's personality into every pixel, creating a digital space that resonates with your audience."}, 
        {cardHeading: 'Innovative & Unique', cardPara: 'Standing out in the digital landscape requires innovation. Our team thrives on pushing boundaries, creating custom solutions that set you apart from the competition.'}, 
        {cardHeading: 'Exceptional User Experience', cardPara: 'We prioritize the end-user experience above all else. From intuitive navigation to engaging visuals, every aspect is meticulously crafted to captivate and convert.'}
    ]

    const servicefeaturecarddata= [
        {cardImage: CardImage, cardTitle: 'Colour Tested', cardPara: 'We understand the psychology of colors and leverage them strategically to evoke the desired emotions and actions from your visitors.'},
        {cardImage: CardImageTwo, cardTitle: 'Powerful CMS', cardPara: 'Take control of your content effortlessly with our robust Content Management System, empowering you to update and manage your website with ease.'},
        {cardImage: CardImageThree, cardTitle: 'Scalable', cardPara: 'Your success is our priority. Our scalable solutions grow with your business, ensuring your website remains a powerful asset for years to come.'},
        {cardImage: CardImageFour, cardTitle: 'Advanced Analytics', cardPara: 'Gain valuable insights into website performance, visitor behaviour, and marketing effectiveness with comprehensive analytics tools, allowing you to make data-driven decisions to optimise your website and maximise results.'}
    ]

    const showcaseSettings = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              }
            }
          ]
    };

    const showcasedata = [
        {smallHeding: 'E-Commerce', LargeHeading: 'Freshkart Farms', showcaseImage: showcaseImage}, 
        {smallHeding: 'Hospitality', LargeHeading: 'Raaya By Atmosphere', showcaseImage: showcaseImageTwo, sliderClass: 'slide-two'}, 
        {smallHeding: 'Education', LargeHeading: '3ischools', showcaseImage: showcaseImageThree, sliderClass: 'slide-three'}, 
        {smallHeding: 'Hospitality', LargeHeading: 'The Ozen Collection', showcaseImage: showcaseImagefour, sliderClass: 'slide-four'}

    ]

    const statisticHeading= 'Real Results'
    const parastatistic= "Through our tailored solutions, we've been instrumental in assisting our clients in achieving significant milestones. Our design revamps and facelifts have effectively increased website traffic, optimized operational efficiencies, and elevated user engagement."

    const statisticsdata = [
        {statisticsTitle:' 26%', statisticsImage: StatisticImage, statisticsPara: 'Website Traffic'}, 
        {statisticsTitle:'37.5%', statisticsImage: StatisticImage, statisticsPara: 'User Engagement ', statisticscard: 'statistics-wrapper-two', webDevelopmentIcon: 'web-development-statistics-icon'}, 
        {statisticsTitle:'4x', statisticsImage: StatisticImage, statisticsPara: 'Website Speed', resultCardTwo: 'statitics-cards-third'}
    ]

    const technologydata = [
        {TechnologyImage: TechnologyImage, technologyTitle:'PHP'},
        {TechnologyImage: TechnologyImage2, technologyTitle:'React'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'HTML 5'},
        {TechnologyImage: TechnologyImage4, technologyTitle:'CSS 3', cardImage:'technology-img'},
        {TechnologyImage: TechnologyImage5, technologyTitle:'Boostrap'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'Tailwind CSS'}
    ]

    const accordionData = [
        {
            planEventKey:0, planTabHeading: ' How do your website development solutions optimize operational efficiencies? ', planTabText: "We thoroughly analyze your business processes to identify areas for improvement. By implementing streamlined solutions, including automation of tasks and workflow enhancements. Additionally, our in house CMS, COMET, enables multisite setup, allowing for the unification and sharing of media and content resources across multiple websites, further streamlining operations and maximizing productivity."
        }, 
        {
            planEventKey:1, planTabHeading: 'How do you elevate user engagement on websites?', planTabText: "We meticulously employ user-centric design principles and strategically place interactive features throughout the website. By carefully crafting copy and scientifically positioning Call-to-Action (CTA) buttons, we captivate visitors and prompt meaningful interactions, ultimately resulting in heightened user engagement."
        },
        {
            planEventKey:2, planTabHeading: 'What CMS do you provide and use?', planTabText: "We offer our custom in-house CMS, COMET, which is unmatched in its advanced features and robust functionality. This cutting-edge technology provides unparalleled flexibility and scalability, seamlessly adapting to the diverse needs of various industries and businesses."
        },
        {
            planEventKey:3, planTabHeading: 'How does your design revamp increase website traffic?', planTabText: "Our design revamps are meticulously crafted by a team of highly creative designers, proficient copywriters, and skilled developers. Together, they collaborate to build a cohesive user flow and engaging story that captivates visitors from the moment they land on your site. By enhancing user experience and optimizing search engine visibility through strategic design elements and compelling content, our revamps drive a noticeable increase in website traffic."
        }
    ]

    const defaultActiveKey = accordionData.length > 0 ? accordionData[0].planEventKey : null;

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Ready to elevate your web presence?", consultPara: "Let's embark on a journey of website excellence together. Contact us today and let's bring your vision to life!", consultPortfolio: 'faqs-consult-wrapper', pageLink: '/consult', LinkText: 'get started'}
    ]

    const getdonedata = [
        {getDoneTitle:'Website Design', getDoneImage: StatisticImage, getDonePara: 'Elevate digital aesthetics with captivating website design.'}, 
        {getDoneTitle:'Website Development ', getDoneImage: StatisticImage, getDonePara: 'Bring visions to life through expert website development.'}, 
        {getDoneTitle:'Brand Design', getDoneImage: StatisticImage, getDonePara: 'Craft memorable brand identities through unique design.'},
        {getDoneTitle:'Backend Development', getDoneImage: StatisticImage, getDonePara: 'Optimise content management with our advanced CMS - COMET.'}, 
        {getDoneTitle:'Website Copy', getDoneImage: StatisticImage, getDonePara: 'Engaging audiences with compelling website copy that converts.'}, 
        {getDoneTitle:'Digital Marketing', getDoneImage: StatisticImage, getDonePara: 'Elevating online presence, increasing visibility, and driving growth.'}
    ]

    const mobfeaturedata = [
        {mobHeading: 'Dynamic Navigation', mobPara: ' Seamless browsing for every visitor, regardless of device.', mobicon: mobIconOne, responsivecard: 'responsive-card-inner', responsiveImage: 'responsive-icon-wrapper', mobContent: 'responsive-content-wrapper'}, 
        {mobHeading: 'Accessibility', mobPara: 'WCAG standards ensure inclusivity for all users.', mobicon: mobIconTwo, responsivecard: 'responsive-card-inner', mobContent: 'responsive-content-wrapper',responsiveImageMobile:'responsive-mobile-view'}, 
        {mobHeading: 'Adaptive Content', mobPara: 'Fluid design adapts to devices for optimal viewing.', mobicon: mobIconThree, mobcardspace: 'web-feature-space', responsivecard: 'responsive-card-inner', responsiveImage: 'responsive-icon-wrapper', mobContent: 'responsive-content-wrapper'}
    ]

    // const testimonialSetting = {
    //     arrow: false,
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 1199,
    //           settings: {
    //             slidesToShow: 1,
    //           }
    //         }
    //     ]
    // };

    // const testimonialdata = [
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'}
    // ]

    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },

            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

    const images = [
        {softwareImage: TechnologyImage, workText:'PHP'},
        {softwareImage: TechnologyImage2, workText:'React'},
        {softwareImage: TechnologyImage3, workText:'HTML 5'},
        {softwareImage: TechnologyImage4, workText:'CSS 3'},
        {softwareImage: TechnologyImage5, workText:'Boostrap'},
        {softwareImage: TechnologyImage6, workText:'Tailwind CSS', SliderImg:'technology-slider-img'}
    ]

  return (
        <>
            <ServiceBanner servicedata={servicedata}/>
            
            <section>
                <div className='about-service-wrapper'>
                    <div className='container'>
                        <div className='service-about-para'>
                            <p>{parse(paraaboutservice)}</p>
                            {/* <p>{parse(paraaboutserviceOne)}</p> */}
                            <div className='service-btn-wrapper'>
                                <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */}

            <section>
                <div className='about-service-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-wrapper service-web-cards d-flex'>
                            <div className='service-left'>
                                <div className='service-clean-heading'>
                                    <h2 className='service-banner-heading fw-bold'>Crafted with Emotion, Developed with Care & Precision<span className='brand-color'>.</span></h2>
                                    <Link to='/consult' className='btn service-get-started-btn mt-5'>Get Started <MdArrowOutward /></Link> 
                                </div>
                            </div>
                            <div className='service-right'>
                                <div className='Service-cards-innre'>
                                    <div className='row'>
                                        <ServiceCardPage servicecarddata={servicecarddata} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='service-banner-heading fw-bold'>Features That Empower<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='row service-column'>
                            <ServiceFeatureCard servicefeaturecarddata={servicefeaturecarddata} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='responsive-design-wrapper'>
                    <div className='container-fluid custom-container-fluid'>
                            <div className='service-features'>
                                <h2 className='service-banner-heading fw-bold'>Mobile Responsive Excellence<span className='brand-color'>.</span></h2>
                            </div> 
                        <div className='responsive-design-inner'>
                            <div className='row align-content-center'>
                                <div className='col-lg-6 col-md-12'>
                                    <div className='responsive-img'>
                                        <img title='' src={responsiveImage} alt='' className='w-100'/>
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-12'>
                                    <div className='responsive-cards-wrapper'>
                                        <MobFeatures mobfeaturedata={mobfeaturedata} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper atmosphere-case-study overflow-hidden'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-features'>
                            <h2 className='service-banner-heading fw-bold'>Showcase<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='showcase-slider'>
                            <ShowcaseSlider showcaseSettings={showcaseSettings} showcasedata={showcasedata} />
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */}

            <section>
                <div className='about-service-wrapper atmosphere-case-study overflow-hidden'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-12'>
                                <div className='statistics-wrapper-left'>
                                    <h2 className='fw-bold'>{statisticHeading}<span className='brand-color'>.</span></h2>
                                    <p className='statistics-para'>{parse(parastatistic)}</p>
                                    <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12'>
                                <div className='statistics-right d-flex'>
                                    <div className='statistics-inner-wrapper'>
                                        <StatisticsCards statisticsdata={statisticsdata} />
                                    </div>
                                    <div className='statistics-img'>
                                        <img src={StatisticImageLarge} alt='' title='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='fw-bold'>Technologies used<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='copywritting-para'>
                            <div className='row technologies-card-wrapper'>
                                <TechnologyCard technologydata={technologydata}/>
                            </div>
                            <div className='testimonal-slider technology-slider-wrapper'>
                                <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Technologies used */}

            {/* <section>
                <div className='digitech-services-wrapper pt-0'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            <h2 className='fw-bold'>Testimonials<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='testimonal-slider testimonial-slider-wrapper'>
                            <TestimonialSlider testimonialSetting={testimonialSetting} testimonialdata={testimonialdata} />
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className='company-service-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='company-inner-service'>
                            <h2 className='faqs-big-heading  fw-bold'>What do you want to be done?</h2> 
                            <div className='company-service-box'>
                                <div className='row'>
                                    <GetDoneCards getdonedata={getdonedata}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='faqs-wrapper'>  
                        <h6 className='brand-color text-uppercase theme-font-family'>FAQs</h6>   
                        <h2 className='faqs-big-heading fw-bold'>Frequently Asked Questions</h2> 
                        <Accordion className='faqs-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                            <FaqsAccordion accordionData={accordionData} />
                        </Accordion>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} showSmallHeading={false} />
        </>
  )
}

export default WebDevelopment

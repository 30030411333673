import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { IoIosStar } from "react-icons/io";

function TestimonialSlider({testimonialdata,testimonialSetting}) {
    const settings = {...testimonialSetting}
  return (
    <>  
        <Slider {...settings}>
            {testimonialdata.map(item=>(
                <div className={`testimonial-wrapper ${item.testimonialInnerPage}`}>
                    <h4 className='theme-font-family work-heading home-testimonial-heading'>{item.testimonialHeading}</h4>
                    {/* <div className='star'> 
                        <span><IoIosStar /></span>
                        <span><IoIosStar /></span>
                        <span><IoIosStar /></span>
                        <span><IoIosStar /></span>
                        <span><IoIosStar /></span>
                    </div> */}
                    <p className='mt-5 home-testimonail-para'>{item.testimonialParagraph}</p>
                    <div className='testimonial-name'>
                        <h5 className='theme-font-family work-heading'>{item.testimonialName} <span>{item.smallText}</span></h5>
                    </div>
                </div>
            ))}
        </Slider>
    </>
  )
}

export default TestimonialSlider

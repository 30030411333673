import React from 'react'
import { Link} from 'react-router-dom'
import { MdArrowOutward } from "react-icons/md";

function CardView({carddata,cardBrand}) {
  return (
    <>
      {carddata.map(item=>(
        <div className='col-xl-3 col-lg-4 col-md-6 col-sm-12'>
            <div className={`card digitech-card desktop-service-cards border-0 ${item.cardBrand}`}>
              <div className='card-header bg-transparent border-0 p-0'>
                  <div className='card-img'>
                      <img src={item.ServiceIcon} title='' alt='' />
                  </div>
                  <h3 className='fw-semibold'>{item.headinTitle}</h3>
              </div>
              <div className='card-body p-0'>
                  <p className='card-paragraph'>{item.CardParagraph}</p>
                  <div className='btn-wrapper text-end'>
                      <Link to={item.serviceLink} className='btn text-uppercase learn-more-btn text-decoration-none p-0'>
                          {item.LearnMore} <MdArrowOutward />
                      </Link>
                  </div>
              </div>
            </div>
        </div>
      ))}
    </>
  )
}

export default CardView

import React from 'react';
import { useSpring, animated } from 'react-spring';

function WebsiteTabImage({ imageUrl, imageFrameUrl, maadhooImage, hideScrollAnimation }) {
    const props = useSpring({
        from: { x: 300 },
        to: { x: 0 },
        config: { duration: 600 },
    });

    return (
        <>
            <animated.div style={props}>
                <div className='upper-frame'>
                    <img src={imageFrameUrl} title='' alt='' />
                </div>
                <div className={`right-side-image h-100 ${maadhooImage} ${hideScrollAnimation}`}>
                    <img src={imageUrl} title='' alt='' />
                </div>
            </animated.div>
        </>
    );
}

export default WebsiteTabImage;

import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md';

function WorkSlider({images,classview='',workSetting}) {
    const settings = {...workSetting}
    return (
        <>
            <Slider {...settings}>
                {images.map(item=>(
                    <div className='case-study-text rounded-4 ms-auto me-auto'>
                        <div className='work-image'>
                            <img className='work-desktop-img' src={item.workImage} alt='Work' title='Work' />
                            <img className='work-responsive-img' src={item.workResponsiveImg} alt='' title='' /> 
                        </div>
                        <div className='work-content py-4'>
                            <div className='working-wrapper'>
                                <h5 className='theme-font-family work-heading'>{item.workHeading}</h5>
                                <p>{item.workPara}</p>
                            </div>
                            <div className='work-btn-wrapper'>
                                <Link to={item.FoiloLink} className='theme-btn text-uppercase'>{item.learnMoreLink} <MdArrowOutward /></Link>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    )
}

export default WorkSlider

import React, { useState } from 'react';
import { Tab, TabScreen, Tabs } from 'react-tabs-scrollable';
import PricingList from './PricingList';
import { ListGroup } from 'react-bootstrap-v5';

function PricingTabs({ tabArray = [] }) {
  const [activeTab, setActiveTab] = useState(tabArray.length > 0 ? tabArray[0].coverId : 0);
  const [planType, setPlanType] = useState('Monthly');

  const onTabClick = (e, index) => {
    setActiveTab(index);
  };

  const handlePlanChange = (e) => {
    setPlanType(e.target.value);
  };

  return (
    <>
      <Tabs mode="scrollSelectedToCenterFromView" activeTab={activeTab} onTabClick={onTabClick} tabsArray={tabArray} centerMode={true}>
        {tabArray.map((item) => (
          <Tab key={item.coverId} className={`item ${activeTab === item.coverId ? 'active border-0' : 'border-0'}`}>
            {item.buttonText}
          </Tab>
        ))}
      </Tabs>
      {tabArray.map((screen) => (
        <TabScreen className='pricing-tab-screen'
          key={screen.coverId}
          activeTab={activeTab}
          index={screen.coverId}
        >
          <div className='pricing-info-wrapper'>
            <div className='monthly-toggle-btn'>
              <div className="switches-container">
                <input type="radio" id="switchMonthly" name="switchPlan" value="Monthly" checked={planType === 'Monthly'} onChange={handlePlanChange} />
                <input type="radio" id="switchYearly" name="switchPlan" value="Yearly" checked={planType === 'Yearly'} onChange={handlePlanChange} />
                <label htmlFor="switchMonthly">Monthly</label>
                <label htmlFor="switchYearly">Yearly</label>
                <div className="switch-wrapper">
                  <div className="switch">
                    <div className='monthly'>Monthly</div>
                    <div className='monthly'>Yearly</div>
                  </div>
                </div>
              </div>
            </div>
            <ListGroup as="ul" className='d-flex align-items-center'>
              <PricingList pricinglistdata={screen.pricinglistdata} />
            </ListGroup>
          </div>
        </TabScreen>
      ))}
    </>
  );
}

export default PricingTabs;

import React, { useState }  from 'react'
import PricingBanner from '../../components/PricingBanner'
import PricingTabs from '../../components/PricingTabs'
import FeatureHeadings from '../../components/FeatureHeadings'
import PricingFeatures from '../../components/PricingFeatures'
import { ListGroup} from 'react-bootstrap-v5'
import PaymentBoxes from '../../components/PaymentBoxes'
import paymentIcon from '../../assets/images/accessibility.svg'
import { BsChevronDown } from "react-icons/bs";
import paymentImageOne from '../../assets/images/visa.webp'
import paymentImageTwo from '../../assets/images/discover.webp'
import paymentImageThree from '../../assets/images/american-express.webp'
import paymentImageFour from '../../assets/images/master-card.webp'
import pricingIcon from "../../assets/images/check.svg";
import pricingIconminus from '../../assets/images/minus-second.svg'
import PricingList from '../../components/PricingList'

function DigitalMarketingPricing() {
    const pricingdata = [
        {pricingTitle: 'Our Plans', pricingPara: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget malesuada est. Donec lobortis consequat ultricies. Etiam laoreet odio at dictum elementum."}
    ]

    const comparePlanFeature = "compare plan feature"

    const paymentdata = [
        {
            paymentIcon: paymentIcon, paymentHeading: "Money Back Gauranteee", paymentPara: "Lorem ipsum dolor sit amet, consectetur adipiscing"
        },
        {
            paymentIcon: paymentIcon, paymentHeading: "Secure Payment", paymentPara: "Lorem ipsum dolor sit amet, consectetur adipiscing"
        },
        {
            paymentIcon: paymentIcon, paymentHeading: "Secure Payment", paymentPara: "Lorem ipsum dolor sit amet, consectetur adipiscing"
        }
    ]

    const [isOpened, setIsOpened] = useState(false);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }

    const paymentAccepted = "Accepted Payment Methods"

    const pricinglistdata = [
        {
            packageHeading: 'Professional', packageSmallText: 'Scale your business', packagePrice: '25k', packageButton: 'get started', fadeClass: "fade-tab", monthText: '/month', discountText: 'Minimum Contract 4 Months', cutPrice: '30k', showButton: true,
            
            collabratorslistdata : [
                {collabratorlistText: 'collaborators', boldText: '15'},
                {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                {collabratorlistText: 'Free domain for 1 year'},
                {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                {collabratorlistText: 'Accept payments'},
                {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                {collabratorlistText: 'developer platform', boldText: 'Advanced'},
            ]
        },
        {
            packageHeading: 'Premium', packageSmallText: 'Scale your business', packagePrice: '35k', packageButton: 'get started', recommendedText: 'recommended', getStartedPurpleBtn: "pricing-btn",fadeClass: "fade-tab", monthText: '/month', discountText: 'Minimum Contract 4 Months', cutPrice: '40k', showButton: true,
            
            collabratorslistdata : [
                {collabratorlistText: 'collaborators', boldText: '15'},
                {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                {collabratorlistText: 'Free domain for 1 year'},
                {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                {collabratorlistText: 'Accept payments'},
                {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                {collabratorlistText: 'developer platform', boldText: 'Advanced'},
            ]
        },
        {
            packageHeading: 'Ultimate', packageSmallText: 'Scale your business', packagePrice: '60k', packageButton: 'Contact us',fadeClass: "fade-tab", monthText: '/month', discountText: 'Minimum Contract 4 Months', cutPrice: '70k',getstartedLink : '/consult',
            
            collabratorslistdata : [
                {collabratorlistText: 'collaborators', boldText: '15'},
                {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                {collabratorlistText: 'Free domain for 1 year'},
                {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                {collabratorlistText: 'Accept payments'},
                {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                {collabratorlistText: 'developer platform', boldText: 'Advanced'},
            ]
        }
    ]
   
    const pricingfeaturesheading = [
        {
            domainHeading: 'Max Keywords', mainHeading: 'Website Review & Analysis', tooltipText: "The number of keywords we'll focus on for SEO."
        },
        {
            domainHeading: 'Website & Competitor Analysis', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Get a thorough website audit for making improvements and also get a analysis report of your competition's approaches."
        },
        ,
        {
            domainHeading: 'Keywords Research', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading', tooltipText: "GGet the list of relevant keywords people are searching for related to your industry and business."
        },
        {
            domainHeading: 'Initial Ranking Report', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "We'll deliver you a current report of ranking for your targetted keywords."
        },
        ,
        {
            domainHeading: 'Content Duplicity Check', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Percentage of the similarity of content by page type, section, and page depth level." 
        },
        {
            domainHeading: 'Meta Tags Creation', mainHeading: 'On Page SEO Analysis', HideBox:'hide-blank-box',tooltipText: "A canonical URL is the URL of a page chosen by Google as the most representative of a group of duplicate pages."
        },
        {
            domainHeading: 'Canonicalization', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "A canonical URL is the URL of a page chosen by Google as the most representative of a group of duplicate pages."
        },
        {
            domainHeading: 'URL Structure', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading' ,tooltipText: "It informs prospective site visitors about the content of a page, and keeping URLs short makes them more user friendly."
        },
        {
            domainHeading: 'Content Optimization', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "As a result, the site's pages are appealing to both search engines and visitors."
        },
        {
            domainHeading: 'Image Optimization ', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Image optimisation has several benefits, including improved user experience, quicker website load times, and further ranking opportunities." 
        },
        {
            domainHeading: 'Heading Tag Optimization', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Using headers results in higher-quality, easier-to-read things on a web page. Improved text attracts users, which benefits your SEO." 
        },
        {
            domainHeading: 'Website Speed Optimization ', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Increasing the speed with which web pages load." 
        },
        {
            domainHeading: 'Robots.txt ', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Allows search engine crawlers to crawl specific URLs." 
        },
        {
            domainHeading: 'Sitemap Creation', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Search engines access this file to crawl your site more effectively." 
        },
        {
            domainHeading: 'Google Analytics & Search Console Setup', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "GA gives us statistics & insights for online presence of business & Search Console assist in measuring the site's Search volume, how they perform & resolving issues." 
        },
        {
            domainHeading: 'Google My Business Page Setup and Optimization ', mainHeading: 'Local SEO Setup', HideBox:'hide-blank-box',tooltipText: "Setting up a Google business profile and integrating it with the website",
        },
        {
            domainHeading: 'Local Citations ', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Local citation is to mention about company on web like Business directories, Yelp or Yellow Pages to get people find you online." 
        },
        {
            domainHeading: 'Local Classifieds', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "These are an organised collection of brief advertising on several classified websites." 
        },
        {
            domainHeading: 'Blog Posting (500 – 700 words) ', mainHeading: 'Content Marketing', HideBox:'hide-blank-box',tooltipText: "To make your blog more visible in organic search, technical as well as on-page SEO approaches are used.",
        },
        {
            domainHeading: 'Article Writing (500 – 700 words) ', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "It's hand written & well researched industry-experts article of content, similar to a blog post." 
        },
        {
            domainHeading: 'Infographic Creation', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Collection of imagery, data visualizations like pie charts and bar graphs, and minimal text that gives an easy-to-understand overview of a topic." 
        },
        {
            domainHeading: 'Keyword Ranking Report', mainHeading: 'Monthly Reporting', HideBox:'hide-blank-box',tooltipText: "You will receive monthly report for the current keyword rankings in search results",
        },
        {
            domainHeading: 'Google Analytics Report', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Google Analytics (GA) information & report to help you better understand user behaviour on website." 
        },
        {
            domainHeading: 'Acquired Links Report', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',tooltipText: "Report of amount of links that have been acquired referencing your business at the end of each month." 
        },
        {
            domainHeading: 'Email', mainHeading: 'Client Support', HideBox:'hide-blank-box', hideToolTip: 'hide-tooltip'
        },
        {
            domainHeading: 'Chat', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading',  hideToolTip: 'hide-tooltip'
        },
        {
            domainHeading: 'Call', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading', hideToolTip: 'hide-tooltip'
        },
    ]

    const pricingfeaturesdata = [
        {
            Starter: 'Starter',pricingDomain: "customer-accounts-text", mainprice: '25k', rupeeSymble: '₹',  RecommendedText: 'Recommended', featureRightBlank: 'feature-right-blank', hideTableResponsive : 'hide-table-responsive',StarterTwo: 'Plus',
            mainpriceTwo: '35k', StarterThree:  'Enterprise', mainpriceThree: '60k'
        }, 
        {
            pricingTextOne: '4 keywords', showSmallHeading:false, pricingTextThree: '15 keywords', pricingDomain: "customer-accounts-text", pricingTextTwo: '8 keywords', HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },  
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },  
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },   
        {
            pricingIconTwo: pricingIconminus, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIconminus, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        }, 
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon,  hideMainHeading: 'hide-pricing-box', featureRightBlank: 'feature-right-blank',
        }, 
        {
             pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box'
        }, 
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon,  hideMainHeading: 'hide-pricing-box', featureRightBlank: 'feature-right-blank',
        },
        {
            pricingTextOne: '4', showSmallHeading:false, pricingTextThree: '12', pricingDomain: "customer-accounts-text", pricingTextTwo: '8', HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingTextOne: '2', showSmallHeading:false, pricingTextThree: '8', pricingDomain: "customer-accounts-text", pricingTextTwo: '4', HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingTextTwo: '4', showSmallHeading:false, pricingTextThree: '8', pricingDomain: "customer-accounts-text", pricingTextOne: '2',  hideMainHeading: 'hide-pricing-box', featureRightBlank: 'feature-right-blank',
        },
        {
            pricingTextOne: '4', showSmallHeading:false, pricingTextThree: '12', pricingDomain: "customer-accounts-text", pricingTextTwo: '8', HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconOne: pricingIconminus, showSmallHeading:true, pricingTextThree: '1', pricingDomain: "customer-accounts-text", pricingIconTwo: pricingIconminus, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box', showText: false, showText: 'dm-show-text', hideText:'dm-hide-text',
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon,  hideMainHeading: 'hide-pricing-box', featureRightBlank: 'feature-right-blank',
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon,  hideMainHeading: 'hide-pricing-box', featureRightBlank: 'feature-right-blank',
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIconminus, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
    ]
   
    const [planType, setPlanType] = useState('Monthly');



    const handlePlanChange = (e) => {
        setPlanType(e.target.value);
    };
    return (
        <>
            <PricingBanner pricingdata={pricingdata} />

            <section>
                <div className='pricing-wrapper'>
                    <div className='container'>
                        <div className='pricing-tabs-wrapper'>
                            <div className='digital-marketing-heading-wrapper'>
                                <span className='marekting-heading'>Digital Marketing Pricing</span>
                            </div>
                            <div className='pricing-info-wrapper mt-5'>
                                <div className='monthly-toggle-btn'>
                                    <div className="switches-container">
                                        <input type="radio" id="switchMonthly" name="switchPlan" value="Monthly" checked={planType === 'Monthly'} onChange={handlePlanChange} />
                                        <input type="radio" id="switchYearly" name="switchPlan" value="Yearly" checked={planType === 'Yearly'} onChange={handlePlanChange} />
                                        <label htmlFor="switchMonthly">Monthly</label>
                                        <label htmlFor="switchYearly">Yearly</label>
                                        <div className="switch-wrapper">
                                        <div className="switch">
                                            <div className='monthly'>Monthly</div>
                                            <div className='monthly'>Yearly</div>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                                <ListGroup as="ul" className='d-flex align-items-center'>
                                    <PricingList pricinglistdata={pricinglistdata} />
                                </ListGroup>
                            </div>
                        </div>

                        <div className='compare-wrapper'>
                            <div className='compare-heading'>
                                <a href='#pricingtable' className='compare-feature-wrapper'>{comparePlanFeature}</a>
                            </div>
                            <div className='payment-main-wrapper'>
                                <ListGroup as="ul" className='d-flex align-items-center payment-wrapper flex-row flex-wrap'>
                                    <PaymentBoxes paymentdata={paymentdata} />
                                    {isOpened && (
                                        <PaymentBoxes paymentdata={paymentdata} />
                                    )}
                                </ListGroup>
                                <div className="boxtitle" onClick={toggle}>
                                    <div className='show-less-wrapper bg-white'>
                                        <BsChevronDown className='show-less-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='payment-accepted-method d-flex align-items-center justify-content-center'>
                            <div className='payment-accepted-text'>{paymentAccepted}</div>
                            <div className='payment-accepted-wrapper'>
                                <span className='payment-image'><img src={paymentImageOne} alt='' title='' /></span>
                                <span className='payment-image'><img src={paymentImageTwo} alt='' title='' /></span>
                                <span className='payment-image'><img src={paymentImageThree} alt='' title='' /></span>
                                <span className='payment-image'><img src={paymentImageFour} alt='' title='' /></span>
                            </div>
                        </div>

                        <div className='pricing-table-wrapper' id="pricingtable">
                            <div className='pricing-table-inner d-flex'>
                                <div className='package-table-headings'>
                                    <FeatureHeadings pricingfeaturesheading={pricingfeaturesheading} />
                                </div>
                                <div className='pricing-data-inner'>
                                    <PricingFeatures pricingfeaturesdata={pricingfeaturesdata} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DigitalMarketingPricing

import React, { useEffect }  from 'react';
import anime from 'animejs/lib/anime.es.js';
import { Link } from 'react-router-dom';
import { IoArrowDown } from "react-icons/io5";


function OverlappingBrandBoxes({brandoverlapdata}) {
    useEffect(() => {
        document.querySelectorAll(".page").forEach(function (element, index) {
            element.addEventListener("click", function (event) {
                var x = event.pageX;
                var y = event.pageY;
    
                var nextItem = index + 1;
                if (nextItem >= document.querySelectorAll(".page").length) {
                    nextItem = 0;
                    document.querySelectorAll(".page")[0].style.transform = "translateY(0)";
                }
    
                document.querySelectorAll(".page")[nextItem].style.zIndex =
                    parseInt(getComputedStyle(this).zIndex) + 1;
    
                anime({
                    targets: document.querySelectorAll(".page")[nextItem],
                    translateY: ["100%", "0%"], // Animate from translateY(100%) to translateY(0%)
                    duration: 1000, // Animation duration in milliseconds
                    easing: "easeInOutQuad", // Easing function
                });
            });
        });
    }, []);
    return (
        <>  
            <div className="overlapping-wrapper brand-overlap-wrapper overflow-hidden">
                {brandoverlapdata.map (item=> (
                    <div className={`page ${item.overlapClassOne} ${item.overlapClassSecond} ${item.overlapClassThird} ${item.brandOverlapping}`}>
                        <div  className='offer-deals-wrapper elevate-brand-wrapper d-flex align-items-center'>
                            <div className='offer-text-wrapper'>
                                <h2>{item.overlapHeading}</h2>
                                <p>{item.overlapPara}</p>
                                <div className='next-desktop-btn'>
                                    <Link to="" className='btn offer-btn'>{item.overlapLink} <IoArrowDown /></Link>
                                </div>
                            </div>
                            <div className={`offer-Img-wrapper ${item.brandImage}`}>
                                <img src={item.offerImageThird} alt='' title='' />
                                <div className='next-slide-btn'>
                                    <Link to="" className='btn offer-btn'>{item.overlapLink} <IoArrowDown /></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default OverlappingBrandBoxes

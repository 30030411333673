import React from 'react'
import { ListGroup } from 'react-bootstrap-v5'

function CollabratorsList({collabratorslistdata}) {
    return (
        <>
            {collabratorslistdata.map(item =>(
                <ListGroup.Item as="li" className='collabrator-list-group'>
                    <strong className='fw-semibold'>{item.boldText}</strong> {item.collabratorlistText}
                </ListGroup.Item>
            ))}
        </>
    )
}

export default CollabratorsList

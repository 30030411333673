import React from 'react'
import parse from 'html-react-parser'
import ServiceBanner from '../../components/ServiceBanner'
import BannerImage from '../../assets/images/uiux-bg.webp'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md'
import EcommerceShowcaseSlider from '../../components/EcommerceShowcaseSlider'
import offerImgOne from '../../assets/images/uiux-showcase-3.webp'
import offerImgTwo from '../../assets/images/ecommerce-img-second.webp'
import offerImgThree from '../../assets/images/uiux-showcase-2.webp'
import GetDoneCards from '../../components/GetDoneCards'
import StatisticImage  from '../../assets/images/report-up.svg'
import Accordion from 'react-bootstrap/Accordion';
import FaqsAccordion from '../../components/FaqsAccordion'
import ConsultUs from '../../components/ConsultUs'
import TechnologyCard from '../../components/TechnologyCard'
import TechnologyImage from '../../assets/images/figma.webp'
import TechnologyImage2 from '../../assets/images/photoshop.svg'
import TechnologyImage3 from '../../assets/images/illustrator.svg'
import TechnologyImage4 from '../../assets/images/html.webp'
import TechnologyImage5 from '../../assets/images/css.webp'
import TechnologyImage6 from '../../assets/images/react.webp'
import ExploreAtmosphereTabs from '../../components/ExploreAtmosphereTabs'
import imageOne from '../../assets/images/uiux-solution-1.webp'
import imageTwo from '../../assets/images/uiux-solution-2.webp'
import imageThree from '../../assets/images/uiux-solution-3.webp'
import ServiceCardPage from '../../components/ServiceCardPage'
import processImgOne from '../../assets/images/ideation.svg'
import processImgTwo from '../../assets/images/wireframing.svg'
import processImgThree from '../../assets/images/high-fidelity-design.svg'
import processImgFour from '../../assets/images/user-experience-testing.svg'
import UiuxProcessSlider from '../../components/UiuxProcessSlider'
import WeWorkOnSlider from '../../components/WeWorkOnSlider'
import SolutionCaseStudyTabs from '../../components/SolutionCaseStudyTabs'
import responsiveOne from '../../assets/images/uiux-mob-3.webp'
import responsiveTwo from '../../assets/images/e-commerce-mob-2.webp'
import responsiveThree from '../../assets/images/uiux-mob-1.webp'

function UiuxDesign() {
    const servicedata= [
        {bannerSmallHeading:'UI/UX Design', bannerHeading:'Crafting Exceptional User Experiences with Intuitive Interface Design', bannerImage: BannerImage, webBannerHeading: 'uiux-heading'}
    ]

    const paraaboutservice= "<span className=\"letter-size\">A</span>t Digitech, we craft engaging digital experiences that resonate with users and drive results. With a user-centric approach, we prioritize intuitive design principles to ensure every journey is memorable and seamless, whether it's a website, mobile app, or brand identity. Through creativity, innovation, and collaboration, we bring your visions to life, delivering exceptional digital experiences that leave a lasting impact."


    // const paraaboutserviceOne= 'Lorem Ipsum has been the industrys standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text.'

    const servicecarddata= [
        {cardHeading: 'User-Centric Approach', cardPara: 'We prioritize the needs and preferences of users, crafting interfaces that are intuitive and easy to navigate.', cardSpace:'service-card-space'}, 
        {cardHeading: 'Visual Impact', cardPara: 'Our designs are visually stunning, capturing attention and leaving a lasting impression.'}, 
        {cardHeading: 'Functionality First', cardPara: 'While aesthetics are important, we never compromise on functionality, ensuring that every element serves a purpose.', cardSpace:'service-card-space'}, 
        {cardHeading: 'Continuous Iteration', cardPara: 'We believe in constant refinement and improvement, iterating on designs based on user feedback and data insights.'}
    ]

    const ecommerceSettings = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
              }
            },{
                breakpoint: 767,
                settings: {
                    arrow: false,
                }
              }
          ]
    };

    const ecommarcedata = [
        {ecommarceimageUrl:offerImgOne, ecommerceSubTitle: 'Hospitality', ecommerceTitle: 'Atmosphere Hotels & Resorts', MobResponsiveImage: responsiveOne},
        {ecommarceimageUrl:offerImgTwo, ecommerceSubTitle: 'E-Commerce', ecommerceTitle: 'Freshkart Farms', MobResponsiveImage: responsiveTwo},
        {ecommarceimageUrl:offerImgThree, ecommerceSubTitle: 'Hospitality', ecommerceTitle: 'The Ozen Collection', MobResponsiveImage: responsiveThree}
    ]

    const technologydata = [
        {TechnologyImage: TechnologyImage, technologyTitle:'Figma'},
        {TechnologyImage: TechnologyImage2, technologyTitle:'Photoshop'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'Illustrator'},
        {TechnologyImage: TechnologyImage4, technologyTitle:'HTML 5', cardImage:'technology-img'},
        {TechnologyImage: TechnologyImage5, technologyTitle:'CSS 3'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'React'}
    ]

   
    const getdonedata = [
        {getDoneTitle:'UI/UX Design', getDoneImage: StatisticImage, getDonePara: 'Craft intuitive and visually appealing interfaces.'}, 
        {getDoneTitle:'Web Design', getDoneImage: StatisticImage, getDonePara: 'Create stunning and functional websites.'}, 
        {getDoneTitle:'Graphic Design', getDoneImage: StatisticImage, getDonePara: ' Design captivating visuals and branding materials.'},
        {getDoneTitle:'Brand Identity', getDoneImage: StatisticImage, getDonePara: 'Establish unique and memorable brand presences.'}, 
        {getDoneTitle:'Mobile App Design', getDoneImage: StatisticImage, getDonePara: 'Develop seamless and engaging mobile experiences.'}, 
        {getDoneTitle:'E-commerce Design', getDoneImage: StatisticImage, getDonePara: 'Build user-friendly and conversion-focused online stores.'}
    ]

    const accordionData = [
        {
            planEventKey:0, planTabHeading: 'How does Digitech approach user experience design?', planTabText: "Digitech prioritizes user needs, crafting intuitive interfaces, visually stunning designs, and functionally robust solutions. Through continuous iteration, we refine designs for optimal usability."
        }, 
        {
            planEventKey:1, planTabHeading: 'What design services does Digitech offer?', planTabText: "Digitech offers UI/UX, web, graphic, brand identity, mobile app, and e-commerce design. We bring clients' visions to life with expertise in intuitive interfaces, captivating visuals, and user-friendly experiences."
        },
        {
            planEventKey:2, planTabHeading: 'How does Digitech ensure design consistency?', planTabText: "Digitech maintains design consistency across platforms through standardized elements and close collaboration with clients. Adhering to UI style guides and brand guidelines ensures coherence in all our projects."
        },
        {
            planEventKey:3, planTabHeading: 'What sets Digitech apart in design?', planTabText: "Digitech's holistic approach, rooted in user-centric principles and continuous iteration, sets us apart. We prioritize functionality, aesthetics, and accessibility, delivering exceptional designs that drive results."
        }
    ]

    const defaultActiveKey = accordionData.length > 0 ? accordionData[0].planEventKey : null;

    const consultdata = [
        {smallHeading: 'Consult with us', largeHeading: "Delight Customers with Intuitive User Interfaces.", consultPara: "We're not just designing interfaces; we're crafting seamless experiences, fostering connections, and transforming concepts into tangible user-centric solutions. ", consultPortfolio: 'faqs-consult-wrapper', pageLink: '/consult', LinkText: 'get started'}
    ]

    const tabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Accessibility & Adaptability', para:"Our designs adhere to WCAG standards, ensuring accessibility for all users, while seamlessly adapting to different devices and screen sizes.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'UI Sheet to Control the Design',  para:"We implement UI style guides to maintain consistency and coherence across all design elements, facilitating a cohesive user experience.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Continuous Improvement',  para:"We prioritize ongoing refinement and enhancement of our designs based on user feedback and emerging trends, ensuring that our solutions remain relevant and effective over time.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    const responsivetabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Accessibility & Adaptability', serialNumber:"01.", para:"Our designs adhere to WCAG standards, ensuring accessibility for all users, while seamlessly adapting to different devices and screen sizes.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'UI Sheet to Control the Design', serialNumber:"02.", para:"We implement UI style guides to maintain consistency and coherence across all design elements, facilitating a cohesive user experience.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Continuous Improvement', serialNumber:"03.", para:"We prioritize ongoing refinement and enhancement of our designs based on user feedback and emerging trends, ensuring that our solutions remain relevant and effective over time.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    console.log("tab  ",responsivetabArray)

    const galleryCardData = [
        {
            id:1,processTitle: 'Ideation', processPara: "We begin by sketching out ideas and concepts, allowing for creativity and exploration.", processImg: processImgOne, processNumber: '01'
        },
        {
            id:2,processTitle: 'Wireframing', processPara: "Once ideas are solidified, we create wireframes to map out the structure and layout of the design.", processImg: processImgTwo, processNumber: '02'
        },
        {
            id:3,processTitle: 'High-Fidelity Designs', processPara: "With a solid foundation in place, we bring designs to life with high-fidelity visuals and refined details.", processImg: processImgThree, processNumber: '03'
        },
        {
            id:4,processTitle: 'User Experience Testing', processPara: "Before finalizing designs, we prototype and conduct user experience testing to ensure optimal usability and satisfaction.", processImg: processImgFour, processNumber: '04'
        }

    ]

    // const testimonialSetting = {
    //     arrow: false,
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 1199,
    //           settings: {
    //             slidesToShow: 1,
    //           }
    //         }
    //     ]
    // };

    // const testimonialdata = [
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'}
    // ]

    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },


            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

     
    const images = [
        {softwareImage: TechnologyImage, workText:'Figma'},
        {softwareImage: TechnologyImage2, workText:'Photoshop'},
        {softwareImage: TechnologyImage3, workText:'Illustrator'},
        {softwareImage: TechnologyImage4, workText:'HTML 5', cardImage:'technology-img'},
        {softwareImage: TechnologyImage5, workText:'CSS 3'},
        {softwareImage: TechnologyImage6, workText:'React'}
    ]


    return (
        <>
            <ServiceBanner servicedata={servicedata}/>

            <section>
                <div className='about-service-wrapper'>
                    <div className='container'>
                        <div className='service-about-para'>
                            <p>{parse(paraaboutservice)}</p>
                            {/* <p>{parse(paraaboutserviceOne)}</p> */}
                            <div className='service-btn-wrapper'>
                                <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */}

            <section>
                <div className='about-service-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-wrapper service-web-cards d-flex'>
                            <div className='service-left'>
                                <div className='service-clean-heading'>
                                    <h2 className='service-banner-heading fw-bold'>Balancing Visual Impact with User-Centric Design<span className='brand-color'>.</span></h2>
                                    <Link to='/consult' className='btn service-get-started-btn mt-5'>Get Started <MdArrowOutward /></Link> 
                                </div>
                            </div>
                            <div className='service-right'>
                                <div className='Service-cards-innre'>
                                    <div className='row'>
                                        <ServiceCardPage servicecarddata={servicecarddata} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='uiux-process-wrapper atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-heading uiux-process-heading'>
                            <h2 className='mb-0'>Our UI/UX Design Process<span className='brand-color'>.</span></h2>
                        </div>

                        <div className='uiux-process-boxes'>
                            <UiuxProcessSlider galleryCardData={galleryCardData} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='uiux-design-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='atmosphere-heading'>
                                    <h2 className='mb-0'>Controlled Design Focus Areas<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className='digitechtabs casestudy-desktop-tabs uiux-design-tabs d-flex justify-content-between'>
                            <ExploreAtmosphereTabs tabArray={tabArray}/>
                        </div>
                        <div className='responsive-digitech-tab uiux-responsive-tab'>
                            <SolutionCaseStudyTabs responsivetabArray={responsivetabArray} />
                        </div>
                    </div>
                </div>
            </section>
            
            <section>
                <div className='about-service-wrapper overflow-hidden atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='features-heading'>
                            <h2 className='mb-0 fw-bold'>Showcase<span className='brand-color'>.</span></h2>
                        </div>  
                        <div className='ecommerce-showcase-slider'>
                            <EcommerceShowcaseSlider ecommerceSettings={ecommerceSettings} ecommarcedata={ecommarcedata} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='fw-bold'>Technologies used<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='copywritting-para'>
                            <div className='row technologies-card-wrapper'>
                                <TechnologyCard technologydata={technologydata}/>
                            </div>
                            <div className='testimonal-slider technology-slider-wrapper'>
                                <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Technologies used */}

            {/* <section>
                <div className='digitech-services-wrapper pt-0'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            <h2 className='fw-bold'>Testimonials<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='testimonal-slider testimonial-slider-wrapper'>
                            <TestimonialSlider testimonialSetting={testimonialSetting} testimonialdata={testimonialdata} />
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className='company-service-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='company-inner-service'>
                            <h2 className='faqs-big-heading  fw-bold'>Design Services Offered</h2> 
                            <div className='company-service-box'>
                                <div className='row'>
                                    <GetDoneCards getdonedata={getdonedata}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='faqs-wrapper'>  
                        <h6 className='comet-brand-color text-uppercase theme-font-family'>FAQs</h6>   
                        <h2 className='faqs-big-heading fw-bold'>Frequently Asked Questions</h2> 
                        <Accordion className='faqs-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                            <FaqsAccordion accordionData={accordionData} />
                        </Accordion>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} showSmallHeading={false} />

        </>
    )
}

export default UiuxDesign

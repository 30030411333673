import React from 'react'
import { Link} from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md';

function ConsultUs({showheading=true, showSmallHeading=true, consultdata, consultPortfolio}) {
  return (
    <>
        {consultdata.map(item=>(
            <section>
                <div className={`consult-main-wrapper  ${item.consultPortfolio}`}>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='consult-wrapper'>
                            <div className='row align-items-center'>
                                <div className='col-lg-9'>
                                    <div className='consult-info-wrapper'>
                                        {showSmallHeading ? (<h6 className='subbrand-color text-uppercase'>{item.smallHeading}</h6>):(<h6 className='brand-color text-uppercase theme-font-family'>{item.smallHeading}</h6>)}
                                        {showheading ? 
                                        (<h2 className='mb-4 small-heading fw-bold'>{item.largeHeading}</h2>):
                                        (<h2 className='mb-4 large-heading fw-bold'>{item.largeHeading}</h2>)}
                                        
                                        <p>{item.consultPara}</p>
                                    </div>
                                </div>
                                <div className='col-lg-3'>
                                    <div className='consult-btn-wrapper d-flex align-item-center justify-content-end'>
                                        <Link to={item.pageLink}  className={`btn consult-btn ${item.cometConsultBtn}`}>{item.LinkText}  <MdArrowOutward /></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>  
                </div>
            </section>
        ))}
    </>
  )
}

export default ConsultUs

import React from 'react'
import CaseStudySlider from './CaseStudySlider';
import offerImgOne from '../assets/images/game-store-case-study.webp'
import offerImgTwo from '../assets/images/atmosphere-hotel-resorts-case-study.webp'
import offerImgThree from '../assets/images/ozen-case-study.webp'
import offerImgFour from '../assets/images/solfin-case-study.webp'

function CaseStudy({showNewHeading=true}) {
    const gallerySettings = {
        arrow: true,
        dots: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '9px',
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 2,
              }
            },
            {
                breakpoint: 991,
                settings: {
                  slidesToShow: 1,
                }
            }
          ]
    };

    const images = [

        {imageUrl:offerImgOne, galleryHeading:"Games Stores - Revolutionized E-Commerce for Gaming Enthusiasts", learnMore:'/game-store', learnMoreLink: 'learn more', link: '', targetLink: '_blank', classview:'atmosphere-image', caseStudyLink: '/game-store'},

        {imageUrl:offerImgTwo, galleryHeading:"Transforming Website Management for Atmosphere Hotels and Resorts", learnMore:'/atmosphere', learnMoreLink: 'learn more', link: '', targetLink: '_blank', classview:'solfin-light-bg', casestudyclass: 'hotel-resort-case-study', caseStudyLink: '/atmosphere'},


        {imageUrl:offerImgThree, galleryHeading:"Elevating THE OZEN COLLECTION's Luxury Hospitality Experience", link: '/', learnMore:'/ozen', learnMoreLink: 'learn more', targetLink: '_blank', classview:'texet-image', casestudyclass: 'ozen-case-study', caseStudyLink: '/ozen'},

        {imageUrl:offerImgFour, galleryHeading:"Helping Solfin build a sustainable future", learnMore:'/solfin', learnMoreLink: 'learn more', link: '', targetLink: '_blank', classview: 'solfin-case-study-background', caseStudyLink: '/solfin'},
    ]

    return (
        <>
            <section>
                <div className='digitech-services-wrapper  atmosphere-case-study case-study-wrapper'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            {showNewHeading ? (<h2 className='fw-bold'>More Case Studies<span className='brand-color'>.</span></h2>):(<h2 className='fw-bold'>Success Stories<span className='brand-color'>.</span></h2>)}
                            {/* <h2>Case Studies<span className='brand-color'>.</span></h2> */}
                        </div>
                    </div>
                    <div className='case-study-slider'>
                        <div className='container-fluid custom-container-fluid'>
                            <CaseStudySlider gallerySettings={gallerySettings} images={images} />
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CaseStudy

import React from 'react'
import HomeHero from '../../components/HomeHero'
import ServicesCard from '../../components/ServicesCard'
import CaseStudy from '../../components/CaseStudy'
import ConsultUs from '../../components/ConsultUs'
import Works from '../../components/Works'
import Testimonial from '../../components/Testimonial'
import WeWorkOn from '../../components/WeWorkOn'

function Home() {
  const consultdata = [
    {smallHeading: 'Consult with Us', largeHeading: "Let's get started!", consultPara: "Whether you're optimising operations, expanding reach, or enhancing customer satisfaction, our solutions cover software, mobile app development, and websites.", pageLink: '/consult', LinkText: 'get started'}
]

    return (
      <>
        <HomeHero/>
        <ServicesCard/>
        <CaseStudy showNewHeading={false} />
        <ConsultUs showheading={false} showSmallHeading={false} consultdata={consultdata}/>
        <Works/>
        <Testimonial/>
        <WeWorkOn />
      </>
    )
  }
  
  export default Home
import React from 'react'
import parse from 'html-react-parser'
import { MdArrowOutward } from 'react-icons/md'
import ServiceBanner from '../../components/ServiceBanner'
import BannerImage from '../../assets/images/mob-bg.webp'
import { Link } from 'react-router-dom'
import ServiceCardPage from '../../components/ServiceCardPage'
import MobFeatures from '../../components/MobFeatures'
import mobFeatureImg from '../../assets/images/mob-features-img.webp'
import mobIconOne from '../../assets/images/user-centric-design.svg'
import mobIconTwo from '../../assets/images/platform-selection.svg'
import mobIconThree from '../../assets/images/robust-architecture.svg'
import mobIconFour from '../../assets/images/quality-assurance.svg'
import mobIconFive from '../../assets/images/performance-optimization.svg'
import mobIconSix from '../../assets/images/regular-updates.svg'
import MobShowcaseSlider from '../../components/MobShowcaseSlider'
import mobSlideImgOne from '../../assets/images/folio-1.webp'
import mobSlideImgTwo from '../../assets/images/folio-3.webp'
import mobSlideImgThree from '../../assets/images/raheja-folio.webp'
import mobSlideImgFour from '../../assets/images/folio-6.webp'
import mobSlideImgFive from '../../assets/images/game-stores-folio.webp'
import StatisticsCards from '../../components/StatisticsCards'
import StatisticImage  from '../../assets/images/report-up.svg'
import StatisticImageLarge from '../../assets/images/statistics-img-2.webp'
import TechnologyCard from '../../components/TechnologyCard'
import TechnologyImage from '../../assets/images/android-studio.webp'
import TechnologyImage2 from '../../assets/images/kotlin.webp'
import TechnologyImage3 from '../../assets/images/flutter.webp'
import TechnologyImage4 from '../../assets/images/x-code.webp'
import TechnologyImage5 from '../../assets/images/swift.webp'
import TechnologyImage6 from '../../assets/images/react.webp'
import ConsultUs from '../../components/ConsultUs'
import FaqsAccordion from '../../components/FaqsAccordion'
import GetDoneCards from '../../components/GetDoneCards'
import Accordion from 'react-bootstrap/Accordion';
import MobFeaturesSecond from '../../components/MobFeaturesSecond'
import WeWorkOnSlider from '../../components/WeWorkOnSlider'

function MobDevelopment() {
    const servicedata= [
        {bannerSmallHeading:'mobile app development', bannerHeading:"Empowering Your Vision with Innovative Mobility Solutions", bannerImage: BannerImage, cometMainBanner: 'mob-main-banner', smallHeadingColor: 'mob-heaidng-color', webBannerHeading:'ecommerce-heading'}
    ]

    const paraaboutservice= "<span className=\"letter-size\">I</span>n a world where fingertips do the talking, mobile apps have become the conduits of connection, convenience, and commerce. At Digitech, we understand the pulse of this digital age. Our Mobile App Development service is not just about crafting applications; it's about architecting experiences that resonate with users on the move."

    // const paraaboutserviceOne= 'Lorem Ipsum has been the industrys standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text.'

    const servicecarddata= [
        {cardHeading: 'Hybrid Technology Mastery', cardPara: 'Embracing the best of both worlds, we specialize in hybrid tech for iPhone and Android development, ensuring your app reaches a broader audience without compromising performance.'}, 
        {cardHeading: 'React Native Expertise', cardPara: 'Harnessing the power of React Native, we deliver mobile apps that are not only robust and snappy but also scalable and future-proof, giving your business a competitive edge.'}, 
        {cardHeading: 'User-Centric Innovation', cardPara: 'Our focus on user experience is unparalleled. From personalized experiences to meticulous iconography and world-class design, we craft apps that captivate and engage users, fostering long-lasting connections.'}, 
        {cardHeading: 'Storytelling Through Apps', cardPara: 'We believe in the power of storytelling. By creating compelling user stories for your app, we immerse users in experiences that resonate with them on a personal level, driving engagement and loyalty.'}
    ]

    const mobfeaturedata = [
        {mobHeading: 'User-Centric Design', mobPara: 'Prioritize intuitive interfaces that delight users and fulfill their needs effectively.', mobicon: mobIconOne}, 
        {mobHeading: 'Platform Selection', mobPara: 'Choose platforms strategically to maximize reach and engagement.', mobicon: mobIconTwo, mobIconColorChange: 'mob-feature-bg'}, 
        {mobHeading: 'Robust Architecture', mobPara: 'Design scalable systems for long-term adaptability and maintenance.', mobicon: mobIconThree, mobcardspace: 'mobile-feature-space'}
    ]

    const mobfeaturedatasecond = [
        {mobHeading: 'Quality Assurance', mobPara: 'Ensure a seamless user experience through rigorous testing and bug fixing.', mobicon: mobIconFour, mobIconColorChange: 'mob-feature-bg', mobcardfeature: 'mob-inner-wrapper', mobContent: 'mob-inner-content'}, 
        {mobHeading: 'Performance Optimization', mobPara: ' Optimize speed and responsiveness for a smooth user experience.', mobicon: mobIconFive, mobcardfeature: 'mob-inner-wrapper', mobContent: 'mob-inner-content'}, 
        {mobHeading: 'Regular Updates', mobPara: 'Stay relevant and competitive with consistent feature enhancements and bug fixes.', mobicon: mobIconSix, mobIconColorChange: 'mob-feature-bg', mobcardfeature: 'mob-inner-wrapper', mobContent: 'mob-inner-content', mobcardspace: 'mob-feature-space'}
    ]

    const mobshowcaseSettings = {
        arrow: true,
        dots: false,
        centerMode: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              }
            },{
                breakpoint: 767,
                settings: {
                  slidesToShow: 1,
                  slidesToScroll: 1,
                }
              }
          ]
    };

    const mobshowdata = [
        {mobSlideImg: mobSlideImgTwo, showcaseSmallTitle: 'Education', showcaseLargeTitle: '3ischools'}, 
        {mobSlideImg: mobSlideImgThree, showcaseSmallTitle: 'Real Estate', showcaseLargeTitle: 'Raheja Developers'}, 
        {mobSlideImg: mobSlideImgFour, showcaseSmallTitle: 'Astrology', showcaseLargeTitle: 'Namoology'}, 
        {mobSlideImg: mobSlideImgOne, showcaseSmallTitle: 'E-Commerce', showcaseLargeTitle: 'Freshkart Farms'}, 
        {mobSlideImg: mobSlideImgFive, showcaseSmallTitle: 'E-Commerce', showcaseLargeTitle: 'Games Stores'}
    ]

    const statisticsdata = [
        {statisticsTitle:'30%', statisticsImage: StatisticImage, statisticsPara: ' User Engagement', statisticscard: 'mob-statistic-card-one'}, 
        {statisticsTitle:'50+ ', statisticsImage: StatisticImage, statisticsPara: 'Clients Mobilized', statisticscard: 'mob-statistics-wrapper-two'}, 
        {statisticsTitle:'2 Million +', statisticsImage: StatisticImage, statisticsPara: 'Customer Interactions', resultCardTwo: 'mob-statitics-cards-third'}
    ]

    const statisticHeading= 'Real Results'
    const parastatistic= "Welcome to Digitech's Mobile App Development Hub, where innovation drives results. With a focus on excellence, we pride ourselves on delivering exceptional service and satisfaction to our clients. Experience the transformative power of mobile app development with Digitech."

    const technologydata = [
        {TechnologyImage: TechnologyImage, technologyTitle:'Andriod Studio'},
        {TechnologyImage: TechnologyImage2, technologyTitle:'Kotlin'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'Flutter'},
        {TechnologyImage: TechnologyImage4, technologyTitle:'XCode', cardImage:'technology-img'},
        {TechnologyImage: TechnologyImage5, technologyTitle:'Swift'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'React Native'}
    ]


    const getdonedata = [
        {getDoneTitle:'UI/UX Design', getDoneImage: StatisticImage, getDonePara: 'Craft seamless interfaces that inspire and delight users.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'App Prototyping', getDoneImage: StatisticImage, getDonePara: 'Bring your vision to life swiftly with iterative design processes.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Cross-Platform Development', getDoneImage: StatisticImage, getDonePara: 'Reach a wider audience with compatibility across devices.', getDonecard: 'comet-get-done-wrapper'},
        {getDoneTitle:'Backend Development', getDoneImage: StatisticImage, getDonePara: 'Ensure seamless integration and scalability for your app.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Ongoing Support & Updates', getDoneImage: StatisticImage, getDonePara: 'Receive continuous support for sustained success.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Third-Party Integrations', getDoneImage: StatisticImage, getDonePara: 'Enhance your app with seamless integration of popular services.', getDonecard: 'comet-get-done-wrapper'}
    ]

    const accordionData = [
        {
            planEventKey:0, planTabHeading: 'What sets Digitech apart in the realm of mobile app development?', planTabText: "Digitech stands out for its commitment to excellence and user-centric innovation, crafting apps that exceed expectations and foster long-lasting connections with users."
        }, 
        {
            planEventKey:1, planTabHeading: "How does Digitech ensure the success of mobile apps in today's competitive landscape?", planTabText: "Digitech leverages hybrid technology mastery and React Native expertise to ensure that mobile apps are not only robust and scalable but also reach a broader audience without compromising performance."
        },
        {
            planEventKey:2, planTabHeading: "What services does Digitech offer to complement its mobile app development solutions?", planTabText: "Digitech offers a suite of services including UI/UX design, app prototyping, cross-platform development, backend development, ongoing support & updates, and third-party integrations to enhance app functionality and user experience."
        },
        {
            planEventKey:3, planTabHeading: 'What support do you offer after the launch of mobile apps?', planTabText: "We provide ongoing support and updates to ensure the sustained success of mobile apps. Our team remains available to address any issues, implement enhancements, and keep the app aligned with evolving business needs and technological advancements."
        }
    ]

    const defaultActiveKey = accordionData.length > 0 ? accordionData[0].planEventKey : null;

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Begin Building Your Impactful Mobile App Today.", consultPara: "We're not just building apps; we're creating experiences, forging connections, and transforming ideas into realities. Join us on this journey, where innovation meets impact, and possibilities abound.", consultPortfolio: 'faqs-consult-wrapper', cometConsultBtn: 'mob-consult-btn', pageLink: '/consult', LinkText: 'get started'}
    ]

    // const testimonialSetting = {
    //     arrow: false,
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 1199,
    //           settings: {
    //             slidesToShow: 1,
    //           }
    //         }
    //     ]
    // };

    // const testimonialdata = [
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'}
    // ]

    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },

            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

    
    const images = [
        {softwareImage: TechnologyImage, workText:'Andriod Studio'},
        {softwareImage: TechnologyImage2, workText:'Kotlin'},
        {softwareImage: TechnologyImage3, workText:'Flutter'},
        {softwareImage: TechnologyImage4, workText:'XCode'},
        {softwareImage: TechnologyImage5, workText:'Swift', SliderImg:'mob-technology-img'},
        {softwareImage: TechnologyImage6, workText:'React Native'}
    ]

    return (
        <>
            <ServiceBanner servicedata={servicedata}/>

            <section>
                <div className='about-service-wrapper'>
                    <div className='container'>
                        <div className='service-about-para'>
                            <p>{parse(paraaboutservice)}</p>
                            {/* <p>{parse(paraaboutserviceOne)}</p> */}
                            <div className='service-btn-wrapper'>
                                <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */} 

            <section>
                <div className='about-service-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-wrapper service-web-cards d-flex'>
                            <div className='service-left'>
                                <div className='service-clean-heading'>
                                    <h2 className='service-banner-heading fw-bold'>What makes us special<span className='brand-color'>.</span></h2>
                                    <Link to='/consult' className='btn service-get-started-btn mt-5'>Get Started <MdArrowOutward /></Link> 
                                </div>
                            </div>
                            <div className='service-right'>
                                <div className='Service-cards-innre'>
                                    <div className='row'>
                                        <ServiceCardPage servicecarddata={servicecarddata} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features mob-features-heading'>
                            <h2 className='service-banner-heading fw-bold'>Features<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='row align-items-center feature-responsive-wrapper'>
                            <div className='col-lg-4 col-md-12'>
                                <MobFeatures mobfeaturedata={mobfeaturedata} />
                            </div>
                            <div className='col-lg-4 col-md-12'>
                                <div className='mob-feature-img'>
                                    <img src={mobFeatureImg} title='' alt='' className='w-100'/>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12'>
                                <MobFeaturesSecond mobfeaturedatasecond={mobfeaturedatasecond} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-features showcase-heading'>
                            <h2 className='service-banner-heading fw-bold'>Showcase<span className='brand-color'>.</span></h2>
                        </div>
                    </div>
                    
                    <div className='mob-showcase-slider overflow-hidden '>
                        <MobShowcaseSlider mobshowcaseSettings={mobshowcaseSettings} mobshowdata={mobshowdata} />
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study overflow-hidden'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-12'>
                                <div className='statistics-wrapper-left'>
                                    <h2 className='fw-bold'>{statisticHeading}<span className='brand-color'>.</span></h2>
                                    <p className='statistics-para'>{parse(parastatistic)}</p>
                                    <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12'>
                                <div className='statistics-right d-flex'>
                                    <div className='statistics-inner-wrapper'>
                                        <StatisticsCards statisticsdata={statisticsdata} />
                                    </div>
                                    <div className='statistics-img'>
                                        <img src={StatisticImageLarge} alt='' title='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='fw-bold'>Technologies used<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='copywritting-para'>
                            <div className='row technologies-card-wrapper'>
                                <TechnologyCard technologydata={technologydata}/>
                            </div>
                            <div className='testimonal-slider technology-slider-wrapper'>
                                <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Technologies used */}

            {/* <section>
                <div className='digitech-services-wrapper pt-0'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            <h2 className='fw-bold'>Testimonials<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='testimonal-slider testimonial-slider-wrapper'>
                            <TestimonialSlider testimonialSetting={testimonialSetting} testimonialdata={testimonialdata} />
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className='company-service-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='company-inner-service'>
                            <h2 className='faqs-big-heading  fw-bold'>What would you like to get done?</h2> 
                            <div className='company-service-box'>
                                <div className='row'>
                                    <GetDoneCards getdonedata={getdonedata}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='faqs-wrapper'>  
                        <h6 className='comet-brand-color text-uppercase theme-font-family'>FAQs</h6>   
                        <h2 className='faqs-big-heading fw-bold'>Frequently Asked Questions</h2> 
                        <Accordion className='faqs-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                            <FaqsAccordion accordionData={accordionData} />
                        </Accordion>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} />
        </>
    )
}

export default MobDevelopment

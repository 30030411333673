import React from 'react'
import { Card } from 'react-bootstrap-v5'

function MarketingstatisticCards({marketingstatisticsdata}) {
    return (
        <>
            {marketingstatisticsdata.map(item=>(
                <Card className={`border-0 statistics-card-wrapper ${item.statisticscard} ${item.resultCardTwo} ${item.ozenFirstCard} ${item.ozenSecondCard} ${item.kanifushiFirstCard} ${item.kanifushiSecondCard}`}>
                    <Card.Body className='d-flex align-items-center p-0 justify-content-between'>
                        <Card.Title className='mb-0 fw-semibold'>{item.statisticsTitle}</Card.Title>
                        <Card.Img src={item.statisticsImage} className='result-card-img' alt='' title='' />
                    </Card.Body>
                    <Card.Text className='mt-4 w-100'>{item.statisticsPara}</Card.Text>
                </Card>
            ))}
        </>
    )
}

export default MarketingstatisticCards

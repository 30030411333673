import React from 'react'
import parse from 'html-react-parser'
import ServiceBanner from '../../components/ServiceBanner'
import BannerImage from '../../assets/images/dm-banner.webp'
import { MdArrowOutward } from 'react-icons/md'
import { Link } from 'react-router-dom'
import UiuxProcessSlider from '../../components/UiuxProcessSlider'
import processImgOne from '../../assets/images/establish-objectives.svg'
import processImgTwo from '../../assets/images/market-research.svg'
import processImgThree from '../../assets/images/comprehensive-strategy.svg'
import processImgFour from '../../assets/images/implement-measure.svg'
import ExploreAtmosphereTabs from '../../components/ExploreAtmosphereTabs'
import imageOne from '../../assets/images/seo-img.webp'
import imageTwo from '../../assets/images/social-media.webp'
import imageThree from '../../assets/images/mobile-marketing.webp'
import EcommerceFeaturesCards from '../../components/EcommerceFeaturesCards'
import EcommerceFeaturesCardsSecond from '../../components/EcommerceFeaturesCardsSecond'
import ecommerceImage from '../../assets/images/enhanced-online-visibility.svg'
import ecommerceImageTwo from '../../assets/images/lead-generation-excellence.svg'
import ecommerceImageThree from '../../assets/images/traffic-boost-conversion.svg'
import ecommerceImageFour from '../../assets/images/measurable-roi.svg'
import ecommerceImageFive from '../../assets/images/customized-strategies.svg'
import ecommerceImageSix from '../../assets/images/expert-guidance-support.svg'
import TechnologyCard from '../../components/TechnologyCard'
import TechnologyImage from '../../assets/images/moz.webp'
import TechnologyImage2 from '../../assets/images/google-analytics.webp'
import TechnologyImage3 from '../../assets/images/semrush.webp'
import TechnologyImage4 from '../../assets/images/hootsuite.webp'
import TechnologyImage6 from '../../assets/images/google-adwards.webp'
import TechnologyImage7 from '../../assets/images/ahrefs.webp'
import GetDoneCards from '../../components/GetDoneCards'
import StatisticImage  from '../../assets/images/report-up.svg'
import { Accordion } from 'react-bootstrap-v5'
import FaqsAccordion from '../../components/FaqsAccordion'
import ConsultUs from '../../components/ConsultUs'
import SlideOneImage from '../../assets/images/ahr-marketing.webp'
import SlideImageTwo from '../../assets/images/kanifushi-marketing-img.webp'
import MarketingSlider from '../../components/MarketingSlider'
import LookingPackages from '../../components/LookingPackages'
import WeWorkOnSlider from '../../components/WeWorkOnSlider'
import SolutionCaseStudyTabs from '../../components/SolutionCaseStudyTabs'

function DigitalMarketing() {
    const servicedata= [
        {bannerSmallHeading:'Digital Marketing', bannerHeading:'Digital Marketing Services', bannerImage: BannerImage, webBannerHeading: 'web-banner-heading'}
    ]

    const paraaboutservice = "<span className=\"letter-size\">A</span>t Digitech, we offer comprehensive digital marketing solutions tailored to meet your business objectives and drive results. Our approach combines strategic planning, market research, and targeted campaigns to effectively reach and engage your target audience. But we don't stop at mere promotions; we architect your online presence to ensure lasting impact and success in the digital realm.";

    // const paraaboutserviceOne= 'Lorem Ipsum has been the industrys standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text.'

    const galleryCardData = [
        {
            id:1,processTitle: ' Establish Clear Objectives', processPara: "We begin by clearly identifying your business objectives and expected outcomes, whether it's increasing website traffic, generating leads, boosting online sales, or enhancing brand awareness.", processImg: processImgOne, processNumber: '01', ProcessSecondTitle: 'process-second-title', marketingProcess: 'marketing-process-wrapper'
        },
        {
            id:2,processTitle: ' Conduct Market Research', processPara: " We conduct extensive market research to understand your target audience's demographics, interests, and online behaviors. This knowledge helps us design targeted campaigns and adjust messaging to effectively resonate with your audience.", processImg: processImgTwo, processNumber: '02', ProcessSecondTitle: 'process-second-title', marketingProcess: 'marketing-process-wrapper'
        },
        {
            id:3,processTitle: 'Build a Comprehensive Strategy', processPara: "Based on our findings, we develop a comprehensive digital marketing strategy that encompasses various channels and tactics to achieve your goals.", processImg: processImgThree, processNumber: '03', ProcessSecondTitle: 'process-second-title', marketingProcess: 'marketing-process-wrapper'
        },
        {
            id:4,processTitle: 'Implement and Measure', processPara: "We implement the strategy and continuously monitor performance metrics to measure effectiveness and make data-driven optimizations for ongoing success.", processImg: processImgFour, processNumber: '04', ProcessSecondTitle: 'process-second-title', marketingProcess: 'marketing-process-wrapper'
        }

    ]

    const tabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Search Engine Marketing', para:"Boost your online visibility with our optimization and PPC campaigns, ensuring your brand appears prominently in search engine results.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Social Media Marketing',  para:"Captivate your audience and cultivate brand awareness through strategic social media campaigns tailored to your target demographics.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Mobile Marketing',  para:"Seamlessly connect with your audience on the go with targeted mobile marketing initiatives designed to engage users across various devices and platforms.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    const responsivetabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Search Engine Marketing', serialNumber:"01.", para:"Boost your online visibility with our optimization and PPC campaigns, ensuring your brand appears prominently in search engine results.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Social Media Marketing', serialNumber:"02.", para:"Captivate your audience and cultivate brand awareness through strategic social media campaigns tailored to your target demographics.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Mobile Marketing', serialNumber:"03.", para:"Seamlessly connect with your audience on the go with targeted mobile marketing initiatives designed to engage users across various devices and platforms.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    console.log("tab  ",responsivetabArray)

    const ecommercedata = [
        {
            ecommerceImage: ecommerceImage, 
            ecommerceTitle:'Enhanced Online Visibility', 
            ecommerceText: "Elevate your brand's presence and visibility in the digital landscape.", 
            digitalText: 'digital-text', 
            digitalFeatures: 'digital-responsive-feature'
        },
        {
            ecommerceImage: ecommerceImageTwo, 
            ecommerceTitle:'Lead Generation Excellence', 
            ecommerceText: ' Drive quality leads and boost customer acquisition through strategic digital marketing initiatives.', 
            featureImageWrapper: 'ecommerce-feature-img', 
            digitalText: 'digital-text', 
            digitalFeatures: 'digital-responsive-feature'
        },
        {
            ecommerceImage: ecommerceImageThree, 
            ecommerceTitle:'Traffic Boost and Conversion', 
            ecommerceText: ' Increase website traffic and conversion rates, turning visitors into loyal customers.', 
            digitalText: 'digital-text', 
            digitalFeatures: 'digital-responsive-feature'
        }
    ]

    const ecommercedatasecond = [
        {
            ecommerceImage: ecommerceImageFour, 
            ecommerceTitle:'Measurable ROI', 
            ecommerceText: 'Achieve greater returns on your marketing investment with measurable results and performance tracking.', 
            featureImageWrapper: 'ecommerce-feature-img', 
            digitalText: 'digital-text', 
            digitalFeatures: 'digital-responsive-feature'
        },
        {
            ecommerceImage: ecommerceImageFive, 
            ecommerceTitle:'Customized Strategies', 
            ecommerceText: 'Receive tailored digital marketing strategies designed to address your specific business objectives and challenges.', 
            digitalText: 'digital-text', 
            digitalFeatures: 'digital-responsive-feature'
        },
        {
            ecommerceImage: ecommerceImageSix, 
            ecommerceTitle:'Expert Guidance & Support', 
            ecommerceText: 'Benefit from expert guidance and ongoing support from our dedicated team of digital marketing professionals.', 
            featureImageWrapper: 'ecommerce-feature-img', 
            digitalText: 'digital-text', 
            digitalFeatures: 'digital-responsive-feature'
        }
    ]

    const technologydata = [
        {TechnologyImage: TechnologyImage2, technologyTitle:'Google Analytics', marketingCards: 'marketing-cards-wrapper'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'Semrush', cardImage:'marekting-card-img', marketingCards: 'marketing-cards-wrapper'},
        {TechnologyImage: TechnologyImage, technologyTitle:'Moz', marketingCards: 'marketing-cards-wrapper', cardImage:'marekting-card-img' },
        {TechnologyImage: TechnologyImage4, technologyTitle:'Hootsuite', marketingCards: 'marketing-cards-wrapper'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'Google Ads', marketingCards: 'marketing-cards-wrapper'},
        {TechnologyImage: TechnologyImage7, technologyTitle:'Ahrefs', marketingCards: 'marketing-cards-wrapper', cardImage:'marekting-card-img'}
    ]

    


    // const testimonialSetting = {
    //     arrow: false,
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 1199,
    //           settings: {
    //             slidesToShow: 1,
    //           }
    //         }
    //     ]
    // };

    // const testimonialdata = [
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more'}
    // ]

    const getdonedata = [
        {getDoneTitle:'SEO-Ready', getDoneImage: StatisticImage, getDonePara: 'Optimize your site for search engines to boost visibility.'}, 
        {getDoneTitle:'Online Store Promotion', getDoneImage: StatisticImage, getDonePara: 'Increase sales with targeted marketing for your e-commerce store.'}, 
        {getDoneTitle:'SEO Copywriting', getDoneImage: StatisticImage, getDonePara: 'Captivate visitors with engaging website content.'},
        {getDoneTitle:'Profile Management', getDoneImage: StatisticImage, getDonePara: 'Maintain a strong social media presence for brand building.'}, 
        {getDoneTitle:'PPC Campaigns', getDoneImage: StatisticImage, getDonePara: 'Drive immediate traffic and conversions with expert PPC management.'}, 
        {getDoneTitle:'Mobile Marketing', getDoneImage: StatisticImage, getDonePara: 'Reach your audience on the go with targeted mobile campaigns.'}
    ]

    const accordionData = [
        {
            planEventKey:0, planTabHeading: 'How can digital marketing help my business grow?', planTabText: "Digital marketing can help your business grow by increasing online visibility, generating leads, driving website traffic, and expanding brand awareness."
        }, 
        {
            planEventKey:1, planTabHeading: "What sets Digitech's digital marketing services apart from others?", planTabText: "Digitech's digital marketing services stand out due to our strategic approach, extensive market research, targeted campaigns, and ongoing optimization efforts to achieve measurable results."
        },
        {
            planEventKey:2, planTabHeading: 'How long does it take to see results from digital marketing efforts?', planTabText: "The timeline for seeing results from digital marketing efforts varies depending on factors such as the industry, competition, and the chosen marketing tactics. However, with a well-planned strategy and consistent efforts, businesses can start seeing results within a few months."
        },
        {
            planEventKey:3, planTabHeading: 'How much does digital marketing cost?', planTabText: "The cost of digital marketing services depends on various factors such as the scope of work, the complexity of the campaign, and the desired outcomes. We offer customized solutions tailored to meet your budget and goals."
        }
    ]

    const defaultActiveKey = accordionData.length > 0 ? accordionData[0].planEventKey : null;

    const consultdata = [
        {smallHeading: 'Free Website Audit Report', largeHeading: "Take your digital marketing efforts to the next level?", consultPara: "Consult with us today and receive a complimentary website audit report. Let us help you unlock your online potential and achieve your business objectives.", consultPortfolio: 'faqs-consult-wrapper', pageLink: '/consult', LinkText: 'get started'}
    ]
    
    const marketingSuccessSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                centerPadding: '30px',
              },
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                centerPadding: '0',
              }
            }
          ]
    };

    const text = [
        {
            SliderImage: SlideOneImage, 
            smallTitle: 'Hospitality', 
            largeTitle: 'Varu By Atmosphere', 
            keywordHeading: 'Keyword Ranking', 
            statisticsHeading: 'Statistics',
            marketingstatisticsdata: [
                {
                    statisticsTitle:' 29%', 
                    statisticsImage: StatisticImage, 
                    statisticsPara: 'Repeat Customers'
                }, 
                {
                    statisticsTitle:'37%', 
                    statisticsImage: StatisticImage, 
                    statisticsPara: 'Organic Traffic', 
                    statisticscard: 'marketing-spcae'
                }
            ],
            keywordlistdata: [
                {SerialNumber: '01', ListParaOne: 'varu by atmosphere'},
        
                {SerialNumber: '01', ListParaOne: 'varu by atmosphere maldives' },
                 
                {SerialNumber: '06', ListParaOne: 'atmosphere maldives'}
            ]
        },
        // {  
        //     SliderImage: SlideImageTwo, 
        //     smallTitle: 'Hospitality', 
        //     largeTitle: 'Ozen Reserve Bolifushi', 
        //     keywordHeading: 'Keyword Ranking', 
        //     statisticsHeading: 'Statistics', 
        //     ozenMarketingImage: 'ozen-marketing-img', 
        //     ozenSmallTitle: 'ozen-small-title',
        //     marketingstatisticsdata: [
        //         {
        //             statisticsTitle:' 50%', 
        //             statisticsImage: StatisticImage, 
        //             statisticsPara: 'Website Time Spent',
        //             ozenFirstCard : 'ozen-fist-card'
        //         }, 
        //         {
        //             statisticsTitle:'75%', 
        //             statisticsImage: StatisticImage, 
        //             statisticsPara: 'Data Storage', 
        //             statisticscard: 'marketing-space',
        //             ozenSecondCard: 'ozen-second-card'
        //         }
        //     ],
        //     keywordlistdata: [
        //         {SerialNumber: '01', ListParaOne: 'kanifushi'},
        
        //         {SerialNumber: '01', ListParaOne: 'atmosphere kanifushi maldives' },
                 
        //         {SerialNumber: '01', ListParaOne: 'atmosphere kanifushi maldives resort'}
        //     ]
        // },
        {  
            SliderImage: SlideImageTwo, 
            smallTitle: 'Hospitality', 
            largeTitle: 'Atmosphere Kanifushi', 
            keywordHeading: 'Keyword Ranking', 
            statisticsHeading: 'Statistics', 
            kanifushiBackground: 'kanifushi-marketing-bg', 
            kanifushiSmalltitle: 'kanifushi-small-title',
            marketingstatisticsdata: [
                {
                    statisticsTitle:' 33%', 
                    statisticsImage: StatisticImage, 
                    statisticsPara: 'Engagement',
                    kanifushiFirstCard : 'kanifushi-fist-card'
                }, 
                {
                    statisticsTitle:'28%', 
                    statisticsImage: StatisticImage, 
                    statisticsPara: 'Sales Increased ', 
                    statisticscard: 'marketing-space',
                    kanifushiSecondCard: 'kanifushi-second-card'
                }
            ],
            keywordlistdata: [
                {SerialNumber: '01', ListParaOne: 'kanifushi'},
        
                {SerialNumber: '01', ListParaOne: 'atmosphere kanifushi maldives' },
                 
                {SerialNumber: '01', ListParaOne: 'atmosphere kanifushi maldives resort'}
            ]
        }
    ]

    const packagesdata = [
        {
            smallHeading: 'Consult with us',
            largeHeading: "Ready to Dominate Online? Let's Talk!",
            packagePara: "Whether you're seeking increased visibility, higher conversion rates, or amplified brand engagement, we've got you covered. Reach out today and unleash the full potential of your online presence!"
        }
    ]

    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },

            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

    const images = [
        {softwareImage: TechnologyImage, workText:'Google Analytics', digitalMarketingImg: 'mob-technology-img'},
        {softwareImage: TechnologyImage2, workText:'Semrush', digitalMarketingImg: 'mob-technology-img'},
        {softwareImage: TechnologyImage3, workText:'Moz', digitalMarketingImg: 'mob-technology-img'},
        {softwareImage: TechnologyImage4, workText:'Hootsuite', digitalMarketingImg: 'mob-technology-img'},
        {softwareImage: TechnologyImage6, workText:'Google Ads', digitalMarketingImg: 'mob-technology-img'},
        {softwareImage: TechnologyImage7, workText:'Ahrefs', digitalMarketingImg: 'mob-technology-img'}
    ]


    return (
        <>
            <ServiceBanner servicedata={servicedata}/>

            <section>
                <div className='about-service-wrapper'>
                    <div className='container'>
                        <div className='service-about-para'>
                            <p>{parse(paraaboutservice)}</p>
                            {/* <p>{parse(paraaboutserviceOne)}</p> */}
                            <div className='service-btn-wrapper'>
                                <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */}

            <section>
                <div className='uiux-process-wrapper atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-heading uiux-process-heading'>
                            <h2 className='mb-0'>Our Approach<span className='brand-color'>.</span></h2>
                        </div>

                        <div className='uiux-process-boxes'>
                            <UiuxProcessSlider galleryCardData={galleryCardData} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='uiux-design-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-12 col-md-12 col-sm-12'>
                                <div className='atmosphere-heading'>
                                    <h2 className='mb-0'>Impactful Digital Marketing Channels<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                        </div>
                        <div className='digitechtabs casestudy-desktop-tabs uiux-design-tabs d-flex justify-content-between'>
                            <ExploreAtmosphereTabs tabArray={tabArray}/>
                        </div>
                        
                        <div className='responsive-digitech-tab uiux-responsive-tab'>
                            <SolutionCaseStudyTabs responsivetabArray={responsivetabArray} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='comet-features-wrapper'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='benefits-wrapper d-flex'>
                            <div className='benefits-heading-wrapper'>
                                <div className='features-heading mb-5'>
                                    <h2 className='mb-0 fw-bold'>Maximizing Digital Impact<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            <div className='benefits-content-wrapper '>
                                <div className='features-inner-cards mt-0'>
                                    <div className='row'>
                                        <div className='col-lg-6 mb-0 col-md-12 col-sm-12'>
                                            <EcommerceFeaturesCards ecommercedata={ecommercedata} />
                                        </div>
                                        <div className='col-lg-6 mb-0 col-md-12 col-sm-12 mt-lg-0 mt-md-4'>
                                            <EcommerceFeaturesCardsSecond ecommercedatasecond={ecommercedatasecond} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='marketing-success-wrapper atmosphere-case-study'>
                    <div className='contaainer-fluid custom-container-fluid'>
                        <div className='features-heading mb-5'>
                            <h2 className='mb-0 fw-bold'>Success Stories<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='marketing-stories-slider ecommerce-showcase-slider'>
                            <MarketingSlider marketingSuccessSetting={marketingSuccessSetting} text={text} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='fw-bold'>Tools<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='copywritting-para'>
                            <div className='row technologies-card-wrapper'>
                                <TechnologyCard technologydata={technologydata}/>
                            </div>
                            <div className='testimonal-slider technology-slider-wrapper'>
                                <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                            </div>
                        </div>
                        <div className='looking-packages-wrapper'>
                            <LookingPackages packagesdata={packagesdata} />
                        </div>
                    </div>
                </div>
            </section>
            {/* Technologies used */}

            {/* <section>
                <div className='digitech-services-wrapper pt-0'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            <h2 className='fw-bold'>Testimonials<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='testimonal-slider testimonial-slider-wrapper'>
                            <TestimonialSlider testimonialSetting={testimonialSetting} testimonialdata={testimonialdata} />
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className='company-service-wrapper  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='company-inner-service'>
                            <h2 className='faqs-big-heading  fw-bold'>Services</h2> 
                            <div className='company-service-box'>
                                <div className='row'>
                                    <GetDoneCards getdonedata={getdonedata}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='faqs-wrapper'>  
                        <h6 className='brand-color text-uppercase theme-font-family'>FAQs</h6>   
                        <h2 className='faqs-big-heading fw-bold'>Frequently Asked Questions</h2> 
                        <Accordion className='faqs-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                            <FaqsAccordion accordionData={accordionData} />
                        </Accordion>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} showSmallHeading={false} />
        </>
    )
}

export default DigitalMarketing

import React from 'react'
import TestimonialSlider from './TestimonialSlider'

function Testimonial() {
    const testimonialSetting = {
        arrow: false,
        dots: true,
        infinite: true,
        autoplay: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
              }
            }
        ]
    };

    const testimonialdata = [
        {readMore:'Read More', testimonialHeading:'Pioneering Online Game Retail with Digitech', testimonialParagraph:'Teaming up with Digitech for our e-commerce platform was a game-changer. Their expertise and dedication facilitated a seamless transition, redefined the online gaming shopping experience, and positioned us as pioneers in online game retail.', testimonialName:'- Navneet', smallText:'Founder & CEO, Games Stores', learnMoreLink: 'learn more'},
        {readMore:'Read More', testimonialHeading:"Revolutionising Women's Sportswear E-commerce", testimonialParagraph:'We have an ecommerce store for women’s sportswear. We use Digitech’s ecommerce development services to layout and organise our web and mobile store. They also designed software for inventory management, POS, and customer support. ', testimonialName:'- Terrence Burton', smallText:'Head of Business, Flex Sportswear', learnMoreLink: 'learn more'},
        {readMore:'Read More', testimonialHeading:'Efficient Solutions for Seamless Operations', testimonialParagraph:"We urgently needed a software development company to fix our inventory management system. Unlike other developers, Digitech's efficient approach and sandbox solution had us up and running within months.", testimonialName:'-  Vikram Singh', smallText:'Head of Operations, Blaze Inc.', learnMoreLink: 'learn more'},
        {readMore:'Read More', testimonialHeading:"Transforming Operations with Digitech's Expertise", testimonialParagraph:'We had an old suite of applications which we were using to manage our bookings, internal employees and vendors. The whole system was snaggy and essentially broken. Digitech took up the challenge to transform it and now we are live with our 2.0 avatar.', testimonialName:'- Anne Zielenski', smallText:'CEO, Accio Coffee', learnMoreLink: 'learn more'}
    ]

  return (
    <>
        <section>
            <div className='digitech-services-wrapper testimonial-bottom-space atmosphere-case-study'>
                <div className='container-fluid custom-container-fluid'>
                    <div className='service-card-heading'>
                        <h2 className='fw-bold'>Testimonials<span className='brand-color'>.</span></h2>
                    </div>
                    <div className='testimonal-slider testimonial-slider-wrapper'>
                        <TestimonialSlider testimonialSetting={testimonialSetting} testimonialdata={testimonialdata} />
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default Testimonial

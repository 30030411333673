import React from 'react'

function PrivacyPolicy() {
    return (
        <>
            <section>
                <div className='privacy-policy-wrapper header-space'>
                    <div className='container'>
                        <div className='privacy-policy-heading'>
                            <h2 className='fw-bold'>Privacy Policy<span className='brand-color'>.</span></h2>
                        </div>

                        <div className='privacy-policy-text'>
                            <h4>Data Retention Policy, Managing Information</h4>
                            <p>Digitech Software Solutions will retain User provided data for as long as our product is in use by the client and in some cases even after it ceases to exist. The duration for Automatically collection information is 24 months. In case, you would like us to delete any specific or all of the User Provided Data, please contact us at info@digitechsoft.com and our support staff will get in touch with you soon after.</p>

                            <h4 className='mt-4'>Security</h4>
                            <p>Ensuring safety and confidentiality of the information provided by the client is our topmost concern. We employ physical, electronic and procedural safeguards to protect information that needs to be processed and maintained. For instance, we limit the information’s access to authorized staff for whom the availability of this information is vital in order to operate, develop and refine the product. Kindly note that despite our endeavors to safeguard the information, security breaches remain a possible threat.</p>

                            <h4 className='mt-4'>Changes</h4>
                            <p>The Privacy policy as per changing requirements may be updated at any point of time. If that happens, we’ll be quick to notify you of any resulting changes by via email or text message. Clients and customers are advised to consult this Policy Policy to stay acquainted with the latest changes.</p>

                            <h4 className='mt-4'>Your Consent</h4>
                            <p>By opting into our services, you are consenting to our processing of your information as has been stated in the Privacy Policy. Processing implies making use of cookies on a computer/hand held device or using touch information in any form, including, but not limited to, collecting, storing, deleting, using, combining and disclosing information, all such activities will take place in our global offices.</p>


                            <h4 className='mt-4'>Contact Us:</h4>
                            <p>In case you have any questions regarding our privacy while using our services, or anything about our practices, please get in touch with us via email at info@digitechsoft.com.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default PrivacyPolicy

import React from 'react'
import { Badge} from 'react-bootstrap-v5'
import { Stack } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from "react-icons/md";

function FolioInnerBanner({folioHeading, foliobannerpara, badgedata, createdDate, subHeading, atmospherelivingBackground, ahrBackground, gameStoresBackground, CaseStudyLink}) {
    return (
        <>
            <section>
                <div className={`folio-banner header-space ${atmospherelivingBackground} ${ahrBackground} ${gameStoresBackground}`}>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-8 col-md-12'>
                                <div className='atmosphere-banner-left'>
                                    <h3 className='heading-font-family'>{folioHeading}</h3>
                                    <div className='sub-heading-wrapper'>
                                        <span className='folio-created-date'>{createdDate}</span>
                                        <span className='folio-sub-heading'>{subHeading}</span>
                                    </div>
                                    <p className='mb-0'>{foliobannerpara}</p>
                                    <Link to={CaseStudyLink} className='btn service-get-started-btn'>view case study <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-12'>
                                <div className='atmosphere-banner-right'>
                                    <h3>Deliverables</h3>
                                    <div className='folio-banner-badge'>
                                        {badgedata.map(item =>(
                                            <Stack direction="vertical" className='folio-deliverables-tabs align-items-baseline mt-3' gap={3}>
                                                <Badge pill className='bg-transparent px-5 fs-6 py-3'>{item.TabText}</Badge>
                                            </Stack>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default FolioInnerBanner

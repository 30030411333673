import React from 'react';

function UiuxProcessSlider({ galleryCardData }) {
    const [activeId, setActiveId] = React.useState(1);
    const onClick = (id) => { setActiveId(id); };

    return (
        <>
            {galleryCardData.map(card => (
                <div
                    key={card.id}
                    className={`process-item-wrapper ${card.marketingProcess} overflow-hidden ${activeId === card.id ? 'active' : ''}`} onClick={() => onClick(card.id)}>
                        <span className='process-number'>{card.processNumber}</span>
                        <div className='process-img'>
                            <img src={card.processImg} title='' alt='' />
                        </div>
                        <div className='process-content'>
                            <h4 className={`${card.ProcessSecondTitle} mb-0`}>{card.processTitle}</h4>
                            <p>{card.processPara}</p>
                        </div>
                </div>
            ))}
        </>
    );
}

export default UiuxProcessSlider;

import React from 'react';

function PricingFeatures({ pricingfeaturesdata }) {
    return (
        <>
            {pricingfeaturesdata.map((item, index) => (
                <div className='pricing-main-table' key={index}>
                    <div className={`table-responsive-wrapper ${item.hideMainHeading}`}>
                        <div className='pricing-inner-table responsive-heading-box'>
                            <h6>{item.Starter}</h6>
                            <h4><sup>{item.rupeeSymble}</sup> {item.mainprice}</h4>
                        </div>
                        <div className='pricing-inner-table responsive-heading-box'>
                            <span className='table-recommended-wrapper w-100'>{item.RecommendedText}</span>
                            <h6>{item.StarterTwo}</h6>
                            <h4><sup>{item.rupeeSymble}</sup> {item.mainpriceTwo}</h4>
                        </div>
                        <div className='pricing-inner-table responsive-heading-box border-end-0'>
                            <h6>{item.StarterThree}</h6>
                            <h4><sup>{item.rupeeSymble}</sup> {item.mainpriceThree}</h4>
                        </div>
                    </div>
                    <div className={`blank-box ${item.featureRightBlank} ${item.HideBox}`}></div>
                    <div className={`table-responsive-wrapper ${item.hideTableResponsive}`}>
                        <div className={`pricing-inner-table custom-domain-wrapper ${item.pricingDomain} ${item.bottomBorder}`}>
                            {item.showSmallHeading ? (
                                <span className='mb-0 d-flex'><img src={item.pricingIconOne} alt="" title="" /></span>
                            ) : (
                                <span className='mb-0 d-flex'>{item.pricingTextOne}</span>
                            )}
                        </div>
                        <div className={`pricing-inner-table custom-domain-wrapper ${item.pricingDomain} ${item.bottomBorder}`}>
                            {item.showSmallHeading ? (
                                <span className='mb-0 d-flex'><img src={item.pricingIconTwo} alt="" title="" /></span>
                            ) : (
                                <span className='mb-0 d-flex'>{item.pricingTextTwo}</span>
                            )}
                        </div>
                        <div className={`pricing-inner-table custom-domain-wrapper border-end-0 ${item.pricingDomain} ${item.bottomBorder}`}>
                            {item.showSmallHeading ? (
                                <span className={`mb-0 d-flex ${item.hideText}`}><img src={item.pricingIconThree} alt="" title="" /></span>
                            ) : (
                                <span className={`mb-0 d-flex ${item.hideText}`}>{item.pricingTextThree}</span>
                            )}
                            {item.showText ? (
                                <span className={`mb-0 d-none ${item.showText}`}>{item.pricingTextThree}</span>
                            ) : (
                                <span className={`mb-0 d-none ${item.showText}`}><img src={item.pricingIconThree} alt="" title="" /></span>
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
}

export default PricingFeatures;
import React from 'react'
import parse from 'html-react-parser'
import CasestudyBanner from '../../components/CasestudyBanner'
import solfinImage from '../../assets/images/game-store-bg.webp'
import AboutClient from '../../components/AboutClient'
import ChallengeList from '../../components/ChallengeList'
import DeliverablesBadge from '../../components/DeliverablesBadge'
import ExploreAtmosphereTabs from '../../components/ExploreAtmosphereTabs'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md';
import imageOne from '../../assets/images/game-store-1.webp'
import imageTwo from '../../assets/images/game-store-2.webp'
import imageThree from '../../assets/images/game-store-3.webp'
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import AtmosphereLeftImage from '../../assets/images/game-store-home-page.webp'
import AtmosphereMobile from '../../assets/images/game-store-footer.webp'
import AtmosphereOffer from '../../assets/images/game-store-mob-view.webp'
import AtmosphereLms from '../../assets/images/game-store-lead-management.webp'
import TechnologyCard from '../../components/TechnologyCard'
import TechnologyImage from '../../assets/images/net.webp'
import TechnologyImage2 from '../../assets/images/sql-server.webp'
import TechnologyImage3 from '../../assets/images/nopcommerce.webp'
import TechnologyImage4 from '../../assets/images/figma.webp'
import TechnologyImage5 from '../../assets/images/html.webp'
import TechnologyImage6 from '../../assets/images/bootstrap-logo.webp'
import FeaturesUsedCard from '../../components/FeaturesUsedCard'
import FeaturesImage from '../../assets/images/web-analytics.webp'
import FeaturesImage2 from '../../assets/images/game-lead-management.webp'
import FeaturesImage3 from '../../assets/images/unified-library.webp'
import ConsultUs from '../../components/ConsultUs'
import ResultCards from '../../components/ResultCards'
import ResultImage from '../../assets/images/report-up.svg'
import ResultImage2 from '../../assets/images/report-down.svg'
import SuccessSlider from '../../components/SuccessSlider'
import QuotesImage from '../../assets/images/atmosphere-quotes.svg'
import UserImage from '../../assets/images/user-img.webp'
import WeWorkOnSlider from '../../components/WeWorkOnSlider'
import SolutionCaseStudyTabs from '../../components/SolutionCaseStudyTabs'

function GameStoreCaseStudy() {
    const bannerSmallHeading = 'Case Study'
    
    const bannerLargeHeading = "Games Stores - Revolutionized E-Commerce for Gaming Enthusiasts"
    const bannerBackground = 'game-wrapper'
    const smallHeading = 'game-heading-color'
    const casestudyBannerBackground = 'game-store-banner-background'

    const aboutdata = [
        {AboutHeading: 'About the client', SecondSmallHeading: 'E-Commerce', SecondLargeHeading: 'Games Stores', AboutPara: 'Games Stores, the premier game seller and retailer on Amazon India, embarked on a bold venture to forge its own digital path by launching an independent e-commerce platform. With its established reputation as the go-to destination for gaming enthusiasts, Games Stores aimed to expand its reach and offerings. By curating a diverse inventory spanning games, hardware, consoles, and handheld devices, Games Stores aimed to redefine the online shopping experience for gamers nationwide.', AboutParaTwo: 'With a steadfast commitment to excellence, Games Stores endeavored to transcend conventional boundaries and create a digital realm tailored to the needs of its discerning clientele. By prioritizing innovation and customer satisfaction, Games Stores sought to establish itself as a pioneer in the e-commerce landscape, revolutionizing the accessibility and enjoyment of gaming products across India.', ChallengesHeading: 'Challenges',},
    ]

    const ChallengesHeading = 'Challenges'

    const listdata = [
        {SerialNumber: '01.', paragraphHeading: 'Flexible, Scalable Design and Payment Options:', ListParaOne: "Building a website that could adapt to increasing traffic and sales volume, while offering varied methods of payment to cater to diverse customer preferences, had been essential for Games Stores' success in the long term."},
  
        {SerialNumber: '02.', paragraphHeading: 'Operational Efficiency:', ListParaOne: 'Balancing logistics, inventory, and order fulfillment was challenging for Games Stores. Streamlined coordination across suppliers and shipping partners ensured prompt deliveries and customer satisfaction.' },
         
        {SerialNumber: '03.', paragraphHeading: 'Building Credibility:', ListParaOne: "Games Stores recognized the importance of a visually appealing website to establish trust outside of Amazon's ecosystem. Games Stores aimed to create a professional and reliable online platform, enhancing credibility and fostering customer loyalty."}
    ]

    const badgedata = [
        {TabText: 'Website Design'}, {TabText: 'Website Communication'}, {TabText: 'SEO Copywriting'}, {TabText: 'UI-UX Design'}, {TabText: 'Backend Development'}, {TabText: 'Unified - CMS'}
    ]


    const parafour= 'Acknowledging the impact of skillful copywriting, Games Stores enlisted the expertise of talented writers to produce captivating product descriptions, compelling blog posts, and enticing landing pages. By harnessing industry-specific keywords and crafting engaging narratives around its offerings, Games Stores strategically elevated its presence in search engine results. This initiative not only drew organic traffic but also cultivated a sense of rapport with customers, fostering a loyal and committed following. Through the implementation of SEO-driven content creation strategies, Games Stores effectively established itself as a reputable figure in the gaming industry, effectively connecting with its audience and facilitating sustained engagement and conversion.'

    const parashowcase='We crafted a cutting-edge UI and e-commerce platform for Games Stores, transforming the online shopping experience for gaming enthusiasts across India. Our design seamlessly blends functionality with a sleek, gamer-centric aesthetic, setting a new benchmark for digital retail in the gaming industry.'

    const parafive='Say goodbye to outdated interfaces and hello to sleek, intuitive design. Our redesign boasts a modern aesthetic, ensuring that every interaction is a delight. Whether the guest is a seasoned webuser or a newcomer, navigating through the interface is a breeze, allowing the guest to focus on what matters most – their experience'

    const ozenBeforeImage= 'ozen-before-wrapper'

    const ozenAfterImage= 'ozen-after-wrapper'


    const featuresuseddata = [
        {featuresImage: FeaturesImage, featuresTitle:'Web Analytics', featureText:'Ability to manage multiple websites (main site and sub-brand sites).'},
        {featuresImage: FeaturesImage2, featuresTitle:'Lead Management', featureText:'Centralised repository for media assets (images, videos, documents).', featurecard:'game-feature-card'},
        {featuresImage: FeaturesImage3, featuresTitle:'Unified Content Library', featureText:'Tools to arrange site nvigation & menu for each sub-brand site independently.', featurecard:'feature-card'}
    ]

    const FeaturesPara= "COMET Shop's revolutionary features offer tailored solutions specifically designed for launching your online store, ensuring unparalleled flexibility and control. Games Store experienced fast turnaround and seamless setup, unlocking the full potential of their e-commerce venture. "

    const resultdata=[
        {resultTitle:'30%', resultImage: ResultImage, resultPara:'Improved User Engagement', ozenResultCard:'game-store-result'},
        {resultTitle:'25%', resultImage: ResultImage, resultPara:'Enhanced Sales Performance', ozenResultCard:'game-store-result'},
        {resultTitle:'35%', resultImage: ResultImage, resultPara:'Operations efficiency', ozenResultCard:'game-store-result'},
        {resultTitle:'44%', resultImage: ResultImage2, resultPara:'Order processing time', ozenResultCard:'game-store-result'}
    ]

    const resultPara="Games Stores, a top game retailer on Amazon India, successfully transitioned to its e-commerce platform. Overcoming challenges in design, inventory management, and scalability, the implementation of robust cataloging, streamlined order management, and strategic content creation propelled enhanced engagement, sales, customer base expansion, and operational efficiency."

    const successSetting = {
        arrow: true,
        dots: true,
        centerMode: true,
        infinite: true,
        autoplay: false,
        slidesToShow: 1,
        centerPadding: '0',
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 991,
              settings: {
                slidesToShow: 1,
                centerPadding: '30px',
              },
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                centerPadding: '0',
              }
            }
          ]
    };

    const text = [
        {QuotesImage: QuotesImage, QutesPara: "Teaming up with Digitech for our own e-commerce platform has been a game-changer. Their team's collaborative approach, taking our feedback into account, has created an intuitive and friendly user experience. This has not only enhanced our brand credibility outside of Amazon but also strengthened our online presence.", ClientName: '- Navneet', ClientDesignation: 'CEO of Game Stores', UserImage: UserImage},
        {QuotesImage: QuotesImage, QutesPara: "Teaming up with Digitech for our own e-commerce platform has been a game-changer. Their team's collaborative approach, taking our feedback into account, has created an intuitive and friendly user experience. This has not only enhanced our brand credibility outside of Amazon but also strengthened our online presence.", ClientName: '- Navneet', ClientDesignation: 'CEO of Game Stores', UserImage: UserImage},
        {QuotesImage: QuotesImage, QutesPara: "Teaming up with Digitech for our own e-commerce platform has been a game-changer. Their team's collaborative approach, taking our feedback into account, has created an intuitive and friendly user experience. This has not only enhanced our brand credibility outside of Amazon but also strengthened our online presence.", ClientName: '- Navneet', ClientDesignation: 'CEO of Game Stores', UserImage: UserImage}
    ]

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Ready to Elevate Your E-Commerce Experience?", consultPara: "Let us help you create a powerful, user-friendly e-commerce platform that drives sales and delights customers. Schedule a consultation with our team today to bring your vision to life.", pageLink: '/consult', LinkText: 'get started'}
    ]

    
    const technologydata = [
        {TechnologyImage: TechnologyImage, technologyTitle:'DotNet'},
        {TechnologyImage: TechnologyImage2, technologyTitle:'SQL Server'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'Nopcommerce'},
        {TechnologyImage: TechnologyImage4, technologyTitle:'Figma', cardImage:'technology-img'},
        {TechnologyImage: TechnologyImage5, technologyTitle:'HTML 5'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'Bootstrap'}
    ]

    
    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },

            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

    const images = [
        {softwareImage: TechnologyImage, workText:'DotNet'},
        {softwareImage: TechnologyImage2, workText:'SQL Server'},
        {softwareImage: TechnologyImage3, workText:'Nopcommerce'},
        {softwareImage: TechnologyImage4, workText:'Figma'},
        {softwareImage: TechnologyImage5, workText:'HTML 5'},
        {softwareImage: TechnologyImage6, workText:'Bootstrap'},
    ]

    
    const tabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Cataloging', serialNumber:"01.", para:"Games Stores implemented a robust cataloging system, ensuring products were efficiently organized for seamless browsing.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Attributes and Advanced Filters', serialNumber:"02.", para:"By incorporating advanced filtering options, Games Stores enabled precise search results, enhancing the overall shopping experience for customers.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Order Management and Tracking', serialNumber:"03.", para:" A comprehensive order management system had enabled Games Stores to efficiently process orders, track shipments, and provide real-time updates to customers regarding the status of their purchases.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    const responsivetabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Cataloging', serialNumber:"01.", para:"Games Stores implemented a robust cataloging system, ensuring products were efficiently organized for seamless browsing.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Attributes and Advanced Filters', serialNumber:"02.", para:"By incorporating advanced filtering options, Games Stores enabled precise search results, enhancing the overall shopping experience for customers.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Order Management and Tracking', serialNumber:"03.", para:" A comprehensive order management system had enabled Games Stores to efficiently process orders, track shipments, and provide real-time updates to customers regarding the status of their purchases.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    console.log("tab  ",responsivetabArray)

    return (
        <>
            <CasestudyBanner showLargeHeading={false} bannerSmallHeading={bannerSmallHeading} bannerLargeHeading={bannerLargeHeading} showBannerImage={false} bannerBackground={bannerBackground} smallHeading={smallHeading} solfinImage={solfinImage} casestudyBannerBackground={casestudyBannerBackground} />

            <AboutClient aboutdata={aboutdata} />

            <section>
                <div className='about-atmosphere   atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-heading'>
                            <h2>{ChallengesHeading}<span className="brand-color">.</span></h2>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <div className='about-list-wrapper'>
                                        <ChallengeList listdata={listdata} />
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='right-content'>
                                        <h3 className='about-right-heading fw-semibold'>Deliverables</h3>
                                        <div className='atmosphere-tags'>
                                            <DeliverablesBadge badgedata={badgedata} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* challenges */}

            <section>
                <div className='digitech-solution'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='atmosphere-heading'>
                                    <h2 className='mb-0'>Solution<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                {/* <div className='atmosphere-link text-end'>
                                <Link className='text-uppercase mt-4 d-inline-block theme-btn' to=''>View More <MdArrowOutward /></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className='digitechtabs casestudy-desktop-tabs game-tabs-wrapper d-flex justify-content-between'>
                            <ExploreAtmosphereTabs tabArray={tabArray}/>
                        </div>
                        
                        <div className='responsive-digitech-tab responsive-gamestore-wrapper'>
                            <SolutionCaseStudyTabs responsivetabArray={responsivetabArray} />
                        </div>
                    </div>
                </div>
            </section>
            {/* soluitons */}

            <section>
                <div className='about-atmosphere  atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-heading atmosphere-space mt-0'>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <div className='copywritting-para'>
                                        <h2>SEO Copywriting<span className='brand-color'>.</span></h2>
                                        <p>{parse(parafour)}</p>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='right-content copywriting-tabs'>
                                        
                                        <div className='atmosphere-tags'>
                                            <Stack direction="vertical" className='deliverables-tabs align-items-baseline mt-4' gap={3}>
                                                <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>Engaging Copy</Badge>
                                                <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>Tone of Voice</Badge>
                                                <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>SEO Keywords</Badge>
                                                <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>Brand Story</Badge>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* SEO Copyright */}

            <section>
                <div className='about-atmosphere  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='atmosphere-heading'>
                                    <h2>Showcase<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='atmosphere-link text-end'>
                                    <Link className='text-uppercase mt-4 d-inline-block theme-btn' to='http://gamestores.in/' target='_blank'>Visit Site <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='col-lg-11'>
                                <div className='showcase-para'>
                                    <p>{parse(parashowcase)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='atmosphere-design-wrapper gamestore-showcase'>
                            <div className='atmosphere-left-pannel'>
                                <div className='left-design gamestore-design'>
                                    <h6 className='heading-font'>Home Page</h6>
                                    <img src={AtmosphereLeftImage} title='home' alt='home' className='w-100' />
                                </div>
                            </div>
                            
                            <div className='atmosphere-right-pannel'>
                                <div className='right-design d-flex  justify-content-between'>
                                    <div className='atmosphere-mobile gamestore-design ms-5'>
                                        <h6 className='heading-font'>Footer</h6>
                                        <img src={AtmosphereMobile} title='mobile' alt='mobile' className='w-100' />
                                    </div>
                                    <div className='atmosphere-mobile mt-lg-0 mt-md-0 mt-sm-4 ps-5'>
                                        <h6 className='heading-font'>Mobile View</h6>
                                        <img src={AtmosphereOffer} title='offer' alt='offer' className='w-100' />
                                    </div>
                                </div>
                                <div className='right-design mt-5'>
                                    <div className='atmosphere-mobile gamestore-design'>
                                        <h6 className='heading-font'>Lead Management</h6>
                                        <img src={AtmosphereLms} title='lms' alt='lms' className='w-100'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* showcase */}

            <section>
                <div className='about-atmosphere  atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='fw-bold'>Technologies used<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='copywritting-para'>
                            <div className='row technologies-card-wrapper'>
                                <TechnologyCard technologydata={technologydata}/>
                            </div>
                            <div className='testimonal-slider technology-slider-wrapper'>
                                <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Technologies used */}


            <section>
                <div className='about-atmosphere  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='copywritting-para'>
                            <div className='row align-items-center'>
                                <div className='col-lg-7'>
                                    <div className='unified-heading'>
                                        <h2 className='fw-bold'>Powered by COMET Shop<span className='brand-color'>.</span></h2>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='unified-btn text-end'>
                                        <Link className='text-uppercase mt-4 d-inline-block theme-btn' to='/comet'>Know more <MdArrowOutward /></Link>
                                    </div>
                                </div>
                                <div className='col-lg-9'>
                                    <div className='features-paragraph mt-5'>
                                        <p>{parse(FeaturesPara)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <FeaturesUsedCard featuresuseddata={featuresuseddata}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Unified CMS features */}

            <ConsultUs consultdata={consultdata} showSmallHeading={false} />

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'> 
                                <div className='result-wrapper'>
                                    <h2 className='fw-bold'>End Result<span className='brand-color'>.</span></h2>
                                    <div className='result-suv-heading'>
                                        <h3>We make an impact through our work.</h3>
                                    </div>
                                    <div className='result-btn-wrapper'>
                                        <Link to='/consult' className='btn consult-btn game-result-btn'>Consult Us <MdArrowOutward /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='result-right-wrapper'>
                                    <p>{parse(resultPara)}</p>
                                    <div className='result-inner'>
                                        <div className='row'>
                                            <ResultCards resultdata={resultdata}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Result */}

            <section>
                <div className='about-atmosphere atmosphere-case-study casestudy-result-space games-stores-bottom-space'>
                    <div className='container'>
                        <SuccessSlider successSetting={successSetting} text={text} />
                    </div>
                </div>
            </section>
            {/* End Result */}
        </>
    )
}

export default GameStoreCaseStudy

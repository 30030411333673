import React from 'react'
import { IoIosInformationCircleOutline } from 'react-icons/io';

function FeatureHeadings({pricingfeaturesheading}) {
    return (
        <>
            {pricingfeaturesheading.map(item => (
                <div className='pricing-main-table'>
                    <div className={`blank-box ${item.HideBox}`}></div>
                    <div className={`site-feature-heading border-top-0 ${item.hideMainHeading}`}>
                        <h6>{item.mainHeading}</h6>
                        <span>{item.SeoHeading}</span>
                    </div>
                    <div className='pricing-inner-table custom-domain-wrapper justify-content-start flex-row table-left-border'>
                        <p className='mb-0'>{item.domainHeading}</p>
                        <div class={`tooltip ms-3 ${item.hideToolTip}`}>
                            <IoIosInformationCircleOutline />
                            <span class={`tooltiptext`}>{item.tooltipText}</span>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default FeatureHeadings

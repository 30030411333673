import React from 'react'
import parse from 'html-react-parser'
import CasestudyBanner from '../../components/CasestudyBanner'
import AboutClient from '../../components/AboutClient'
import ChallengeList from '../../components/ChallengeList'
import DeliverablesBadge from '../../components/DeliverablesBadge'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md';
import ExploreAtmosphereTabs from '../../components/ExploreAtmosphereTabs'
import AtmosphereLeftImage from '../../assets/images/solfin-website.webp'
import AtmosphereMobile from '../../assets/images/estimator.webp'
import AtmosphereOffer from '../../assets/images/emi-calculator.webp'
import WhySolorPannel from '../../assets/images/why-solor-pannel.webp'
import GoSolor from '../../assets/images/go-solor.webp'
import EstimateLeads from '../../assets/images/estimator-leads.webp'
import TechnologyCard from '../../components/TechnologyCard'
import TechnologyImage from '../../assets/images/react.webp'
import TechnologyImage2 from '../../assets/images/node-js.webp'
import TechnologyImage3 from '../../assets/images/postgresql.webp'
import TechnologyImage4 from '../../assets/images/figma.webp'
import TechnologyImage5 from '../../assets/images/html.webp'
import TechnologyImage6 from '../../assets/images/bootstrap-logo.webp'
import FeaturesUsedCard from '../../components/FeaturesUsedCard'
import FeaturesImage from '../../assets/images/solfine-feature-one.webp'
import FeaturesImageTwo from '../../assets/images/solfine-feature-two.webp'
import FeaturesImageThree from '../../assets/images/solfin-feature.webp'
import ConsultUs from '../../components/ConsultUs'
import ResultImage from '../../assets/images/report-up.svg'
import ResultImage2 from '../../assets/images/report-down.svg'
import ResultCards from '../../components/ResultCards';
import SuccessSlider from '../../components/SuccessSlider'
import QuotesImage from '../../assets/images/atmosphere-quotes.svg'
import UserImage from '../../assets/images/user.webp'
import imageOne from '../../assets/images/solfin-solution-1.webp'
import imageTwo from '../../assets/images/solfin-solution-2.webp'
import imageThree from '../../assets/images/solfin-solution-3.webp'
import solfinImage from '../../assets/images/solfin-img.webp'
import chartImgOne from '../../assets/images/settings.svg'
import chartImgTwo from '../../assets/images/speed.svg'
import chartImgThree from '../../assets/images/transparency.svg'
import chartImgFour from '../../assets/images/hour-glass.svg'
import WeWorkOnSlider from '../../components/WeWorkOnSlider'
import SolutionCaseStudyTabs from '../../components/SolutionCaseStudyTabs'


function solfinCaseStudy() {
    const bannerSmallHeading = 'Client Spotlight'
    // const bannerLargeHeading = 'How we business site from a unified content management system'
    const bannerLargeHeading = 'Helping Solfin build a sustainable future'
    const bannerBackground = 'solfin-wrapper'
    const smallHeading = 'brand-heading-color'
    const casestudyBannerBackground = 'solfin-banner-background'

    const aboutdata = [
        {AboutHeading: 'About the client', SecondSmallHeading: 'Green finance', SecondLargeHeading: 'Solfin - Sustainable Finance ', AboutPara: 'Vision of Solfin is to empower and facilitate a sustainable future by creating inclusive platforms, innovative products, and facilitating accessible finance for all climate-friendly activities. For this, Solfin is building a state-of-the-art end-to-end platform to deliver the best-in-class solar projects through a network of trusted EPC partners and in-house financing/leasing arm.',  AboutParaTwo: "Digitech was tasked with creating a web-based solution for Solfin where customers would get a cost-effective solution for their Solar needs along with multiple financing options. We would also create a process for onboarding of Customers, EPCs and DSAs, with a separate portal for each of them to track their project statuses and post installation panel tracking. An Admin portal was also setup for the client to manage all the stakeholders and various aspects of their solar financing process of projects and post project management.", ChallengesHeading: 'Challenges', solfinHeading: 'solfin-heading-left'}
    ]

    const listdata = [
      {SerialNumber: '01.', ListParaOne: 'Without a digital presence it was extremely difficult for the client to market themselves and reach their target audience in an effective way to bring in more customers. It was also not scalable in terms of human & financial resources.'},

      {SerialNumber: '02.', ListParaOne: 'Manual enrollment and approval process for partners like EPCs and DSAs was a tedious task and increasingly error prone. Also, their management post onboarding, which included assigning leads and their tracking was not feasible in their previous operational setup.' },
       
      {SerialNumber: '03.', ListParaOne: 'Once the customers submitted their application for a solar project, they had no way to track their application other than calling and checking up with the service provided. All KYC documents, proposal and signed agreements were exchanged manually or over emails.'}
    ]

    const ChallengesHeading = 'Challenges'

    
    const badgedata = [
        {TabText: 'Web Development'}, {TabText: 'UI-UX Design'}, {TabText: 'Admin Panel'}, {TabText: 'Customer Dashboard'}, {TabText: 'Partner Portals'}, {TabText: 'Workflow Creation'}
    ]

    const parashowcase='The solution utilizes Reacts virtual DOM for faster updates along with built-in state management. Its component-based architecture enhances mobile responsiveness while a unified backend with image optimization ensures maintainable and scalable front-end code.'

    const technologydata = [
        {TechnologyImage: TechnologyImage, technologyTitle:'React'},
        {TechnologyImage: TechnologyImage2, technologyTitle:'Node JS'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'PostgreSQL'},
        {TechnologyImage: TechnologyImage4, technologyTitle:'Figma', cardImage:'technology-img'},
        {TechnologyImage: TechnologyImage5, technologyTitle:'HTML 5'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'Bootstrap'}
    ] 

    const featuresuseddata = [
        {featuresImage: FeaturesImage, featuresTitle:'Area Calculator', featureText:'Area is required to estimate installation capacity and customer can highlight their area on the map and its calculated automatically.'},
        {featuresImage: FeaturesImageTwo, featuresTitle:'Performance Monitoring', featureText:'Integration with solar invertors is done so that customers can track their consumed units, bills and other performance indicators of their solar setup.'},
        {featuresImage: FeaturesImageThree, featuresTitle:'Document Inventory', featureText:'All the project related agreements including Loan Sanction letter, Disbursal letter etc. are stored in the system and can be accessed anytime by the customer.'}
    ]

    const FeaturesPara='We developed using COMET Content Manager as a base, providing Solfin with a comprehensive platform equipped with versatile tools for their solar project needs.'

    const resultdata=[
        {resultTitle: '30%', resultImage: ResultImage2, resultPara:'Manual Effort', resultSolfinCards:'result-inner-wrapper',titleImg:chartImgOne},
        {resultTitle:'100%', resultImage: ResultImage, resultPara:'Efficiency', resultSolfinCards:'result-inner-wrapper',
        titleImg:chartImgTwo},
        {resultTitle:'100%', resultImage: ResultImage, resultPara:'Transparency', resultSolfinCards:'result-inner-wrapper', titleImg:chartImgThree},
        {resultTitle:'40%', resultImage: ResultImage2, resultPara:'Application processing time', resultSolfinCards:'result-inner-wrapper',
        titleImg:chartImgFour}
    ]

    

    const resultPara='After 6 months of collaboration, we successfully launched the Solfin suite which included the website, Admin panel and Partner portals. With the launch and continuous optimizations, we managed to reduce manual effort and made the whole process efficient and robust.'

    // const successSetting = {
    //     arrow: true,
    //     dots: true,
    //     centerMode: true,
    //     infinite: true,
    //     autoplay: false,
    //     slidesToShow: 1,
    //     centerPadding: '0',
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 991,
    //           settings: {
    //             slidesToShow: 1,
    //             centerPadding: '30px',
    //           },
    //           breakpoint: 767,
    //           settings: {
    //             slidesToShow: 1,
    //             centerPadding: '0',
    //           }
    //         }
    //       ]
    // };

    // const text = [
    //     {QuotesImage: QuotesImage, QutesPara: 'Digitech has done an amazing work on our project Lorem ipsum dolor sit amet consectetur adipiscing elit. Nunc laoreet augue nec leo gravida faucibus. Nullam aliquet erat nisl quis ornare lorem elementum ut. In luctus eros ut massa varius sollicitudin.', UserImage: UserImage, ClientName: "Client's Full Name"},
    //     {QuotesImage: QuotesImage, QutesPara: 'Digitech has done an amazing work on our project Lorem ipsum dolor sit amet consectetur adipiscing elit. Nunc laoreet augue nec leo gravida faucibus. Nullam aliquet erat nisl quis ornare lorem elementum ut. In luctus eros ut massa varius sollicitudin.', UserImage: UserImage, ClientName: "Client's Full Name"},
    //     {QuotesImage: QuotesImage, QutesPara: 'Digitech has done an amazing work on our project Lorem ipsum dolor sit amet consectetur adipiscing elit. Nunc laoreet augue nec leo gravida faucibus. Nullam aliquet erat nisl quis ornare lorem elementum ut. In luctus eros ut massa varius sollicitudin.', UserImage: UserImage, ClientName: "Client's Full Name"}
    // ]

    const tabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Solar Estimator', serialNumber:"01.", para:"We developed a website providing a digital presence to our client. The Solar Estimator on the website was a hook point for the customers as they could see an overview of the solution based on their inputs, including tentative cost, savings, EMI and yearly cashflow. They could even apply for a financing option there and then if they were interested.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Partner Onboarding', serialNumber:"02.", para:"EPC and DSA partners could now fill an enrollment form giving their details including their experience, charges and areas of operation. They could also upload their documents for KYC. Then Admin now just had to view the application and decide on their empanelment thus making the whole process smooth. On successful empanelment, partners would automatically receive login credentials of their portal.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Application processing', serialNumber:"03.", para:"As the customers register, the application processing starts and they receive their dashboard credentials. On logging in to this dashboard, the customers can track their application and any correspondence with Solfin for documents etc. is done here. Post processing and signing of agreement, when the project starts, customers can check their project status as well.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    const responsivetabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Solar Estimator', serialNumber:"01.", para:"We developed a website providing a digital presence to our client. The Solar Estimator on the website was a hook point for the customers as they could see an overview of the solution based on their inputs, including tentative cost, savings, EMI and yearly cashflow. They could even apply for a financing option there and then if they were interested.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Partner Onboarding', serialNumber:"02.", para:"EPC and DSA partners could now fill an enrollment form giving their details including their experience, charges and areas of operation. They could also upload their documents for KYC. Then Admin now just had to view the application and decide on their empanelment thus making the whole process smooth. On successful empanelment, partners would automatically receive login credentials of their portal.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Application processing', serialNumber:"03.", para:"As the customers register, the application processing starts and they receive their dashboard credentials. On logging in to this dashboard, the customers can track their application and any correspondence with Solfin for documents etc. is done here. Post processing and signing of agreement, when the project starts, customers can check their project status as well.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    console.log("tab  ",responsivetabArray)

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Power Your Vision with Custom Software Solutions", consultPara: "Partner with us to develop innovative, web-based platforms that streamline your projects and financing processes. Consult our expert team to bring your vision to life.", pageLink: '/consult', LinkText: 'get started'}
    ]

    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },

            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

    const images = [
        {softwareImage: TechnologyImage, workText:'React'},
        {softwareImage: TechnologyImage2, workText:'Node Js'},
        {softwareImage: TechnologyImage3, workText:'Postgres'},
        {softwareImage: TechnologyImage4, workText:'Figma'},
        {softwareImage: TechnologyImage5, workText:'HTML 5'},
        {softwareImage: TechnologyImage6, workText:'Bootstrap'},
    ]

  return (
        <>
            <CasestudyBanner showLargeHeading={false} bannerSmallHeading={bannerSmallHeading} bannerLargeHeading={bannerLargeHeading} showBannerImage={false} bannerBackground={bannerBackground} smallHeading={smallHeading} solfinImage={solfinImage} casestudyBannerBackground={casestudyBannerBackground}  />

            <AboutClient aboutdata={aboutdata} />

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-heading'>
                            <h2>{ChallengesHeading}<span className="brand-color">.</span></h2>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <div className='about-list-wrapper'>
                                        <ChallengeList listdata={listdata} />
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='right-content'>
                                        <h3 className='about-right-heading fw-semibold'>Deliverables</h3>
                                        <div className='atmosphere-tags'>
                                            <DeliverablesBadge badgedata={badgedata} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* challenges */}

            <section>
                <div className='digitech-solution'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='atmosphere-heading'>
                                    <h2 className='mb-0'>Solution<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                {/* <div className='atmosphere-link text-end'>
                                <Link className='text-uppercase mt-4 d-inline-block theme-btn' to=''>View More <MdArrowOutward /></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className='digitechtabs casestudy-desktop-tabs solfin-tabs-wrapper d-flex justify-content-between'>
                            <ExploreAtmosphereTabs tabArray={tabArray}/>
                        </div>
                        <div className='responsive-digitech-tab solfin-responsive-tab'>
                            <SolutionCaseStudyTabs responsivetabArray={responsivetabArray} />
                        </div>
                    </div>
                </div>
            </section>
            {/* soluitons */}
            

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='atmosphere-heading'>
                                    <h2>Showcase<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='atmosphere-link text-end'>
                                    <Link className='text-uppercase mt-4 d-inline-block theme-btn' target="_blank" to='https://solfin.co.in/'>visit site <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='col-lg-11'>
                                <div className='showcase-para'>
                                    <p>{parse(parashowcase)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='atmosphere-design-wrapper solfin-showcase'>
                            <div className='atmosphere-left-pannel'>
                                <div className='left-design solfin-design'>
                                    <h6 className='heading-font'>Website design</h6>
                                    <img src={AtmosphereLeftImage} title='home' alt='home' className='w-100' />
                                </div>
                            </div>
                            
                            <div className='atmosphere-right-pannel'>
                                <div className='right-design solfin-right d-flex align-items-center justify-content-between'>
                                    <div className='atmosphere-mobile solfin-design solfin-mobile-space'>
                                        <h6 className='heading-font'>Estimator</h6>
                                        <img src={AtmosphereMobile} title='mobile' alt='mobile' className='w-100' />
                                    </div>
                                    <div className='atmosphere-mobile solfin-design solfin-mobile-space mt-lg-0 mt-md-0 mt-sm-4 ms-5'>
                                        <h6 className='heading-font'>EMI Calculator</h6>
                                        <img src={AtmosphereOffer} title='offer' alt='offer' className='w-100' />
                                    </div>
                                </div>
                                <div className='right-design mt-5 d-flex align-items-center justify-content-between'>
                                    <div className='atmosphere-mobile solfin-design solfin-mobile-space'>
                                        <h6 className='heading-font'>Why Solor Panels</h6>
                                        <img src={WhySolorPannel} title='mobile' alt='mobile' className='w-100' />
                                    </div>
                                    <div className='atmosphere-mobile solfin-design  ms-5'>
                                        <h6 className='heading-font'>Go Solor</h6>
                                        <img src={GoSolor} title='offer' alt='offer' className='w-100' />
                                    </div>
                                </div>
                                <div className='right-design px-5  mt-5'>
                                    <div className='atmosphere-mobile solfin-design'>
                                        <h6 className='heading-font'>Estimator Leads</h6>
                                        <img src={EstimateLeads} title='lms' alt='lms' className='w-100 mx-auto'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* SEO Copyright */}

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='fw-bold'>Technologies used<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='copywritting-para'>
                            <div className='row technologies-card-wrapper'>
                                <TechnologyCard technologydata={technologydata}/>
                            </div>
                            <div className='testimonal-slider technology-slider-wrapper'>
                                <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* technology */}

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='copywritting-para'>
                            <div className='row align-items-center'>
                                <div className='col-lg-7'>
                                    <div className='unified-heading'>
                                        <h2 className='fw-bold'>Empowering Solar Projects with COMET CMS<span className='brand-color'>.</span></h2>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='unified-btn text-end'>
                                        <Link className='text-uppercase mt-4 d-inline-block theme-btn' to='/comet'>know More <MdArrowOutward /></Link>
                                    </div>
                                </div>
                                <div className='col-lg-9'>
                                    <div className='features-paragraph mt-5'>
                                        <p>{parse(FeaturesPara)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <FeaturesUsedCard featuresuseddata={featuresuseddata}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Technologies used */}

            <ConsultUs consultdata={consultdata} showSmallHeading={false} />

            <section>
                <div className='about-atmosphere atmosphere-case-study  casestudy-result-space'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'> 
                                <div className='result-wrapper'>
                                    <h2 className='fw-bold'>End Result<span className='brand-color'>.</span></h2>
                                    <div className='result-suv-heading'>
                                        <h3>We make an impact through our work.</h3>
                                    </div>
                                    <div className='result-btn-wrapper'>
                                        <Link to='/consult' className='btn consult-btn solfin-btn-bg'>Consult Us <MdArrowOutward /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='result-right-wrapper'>
                                    <p>{parse(resultPara)}</p>
                                    <div className='result-inner'>
                                        <div className='row'>
                                            <ResultCards resultdata={resultdata} showTitleImg={false} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* features */}

            {/* <section>
                <div className='about-atmosphere pt-0'>
                    <div className='container'>
                        <SuccessSlider successSetting={successSetting} text={text} />
                    </div>
                </div>
            </section> */}
        </>
  )
}

export default solfinCaseStudy

import React from 'react'
import { Card } from 'react-bootstrap-v5'

function ResultCards({resultdata, showTitleImg=true}) {
  return (
    <>
      {resultdata.map(item=>(
        <div className='col-lg-6 col-md-6'>
            <Card className={`rounded-0 border-0 result-card-wrapper ${item.resultSolfinCards} ${item.ozenResultCard}`}>
                <Card.Body>
                    <Card.Title className='mb-0 fs-2 fw-semibold'>
                      {showTitleImg ? item.resultTitle : <img src={item.titleImg} alt='' className='w-100' />}
                    </Card.Title>
                    <Card.Img src={item.resultImage} className='result-card-img' alt='result' title='result' />
                </Card.Body>
                <Card.Text className='mt-4'>{item.resultPara}</Card.Text>
            </Card>
        </div>
      ))}
    </>
  )
}

export default ResultCards

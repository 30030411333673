import React from 'react'
import { ListGroup } from 'react-bootstrap-v5'

function KeywordList({keywordlistdata}) {
    return (
        <>
            {keywordlistdata.map(item => (
                <ListGroup>
                    <ListGroup.Item className='d-flex justify-content-between align-items-center mb-3'>
                            <p className='mb-0'><strong>{item.paragraphHeading}</strong> {item.ListParaOne}</p>
                            <h6 className='d-inline-block mb-0 keyword-list-number fw-semibold'>{item.SerialNumber}</h6>
                        </ListGroup.Item>
                </ListGroup>
            ))}
        </>
    )
}

export default KeywordList

import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import parse from "html-react-parser";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { MdArrowOutward } from "react-icons/md";
import HangoutIcon from "../../assets/images/hangout-icon.webp";
import UserImage from "../../assets/images/user-img.webp";
import { HiMiniArrowLongLeft } from "react-icons/hi2";
import { IoIosStar } from "react-icons/io";
import { Formik, useFormik } from "formik";
import * as yup from "yup";
import { RxLinkedinLogo } from "react-icons/rx";


export const handelvalidation = yup.object().shape({
  name: yup.string().required("Please enter your name  "),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Please enter email "),
});

function Consult() {
  const key = process.env.REACT_APP_APIKEY;
  const [mailInfo, setMainInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const UserName = "- Sandeep Ahuja";
  const para =
    "Digitech established our brand's online presence with a stunning website and impactful brand communication. Their professionalism, creativity, and attention to detail were exceptional. Working with them was a pleasure, and they exceeded our expectations at every step. Kudos to the whole team!";

  const [value, setValue] = useState();

  const initialValues = {
    name: "",
    email: "",
    about: "",
    projectConcern: "",
    checkBox1: false,
    checkBox2: false,
    checkBox3: false,
    checkBox4: false,
    checkBox5: false,
    checkBox6: false,
  };
  const sent = async () => {
    let checkboxData = [];
    if (values?.checkBox1) {
      checkboxData.push("Website Development");
    }
    if (values?.checkBox2) {
      checkboxData.push("Software Development");
    }
    if (values?.checkBox3) {
      checkboxData.push("Mobile Apps");
    }
    if (values?.checkBox4) {
      checkboxData.push("UI/UX Design");
    }
    if (values?.checkBox5) {
      checkboxData.push("Digital Marketing");
    }
    if (values?.checkBox6) {
      checkboxData.push("Brand Design");
    }
    const dummy = { ...values, value, checkboxData };
    console.log("send function is running and the values are  : ", dummy);

    try {
      setLoading(true);
      const response = await fetch(`${key}/get`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dummy),
      });
      console.log("api request  : ", `${key}/get`);

      if (!response) {
        return "invalid response ";
      }

      const data = await response.json();
      if (data) {
        setLoading(false);
      }
      if (data) {
        setMainInfo(true);
        formik.resetForm();
      } else {
        setMainInfo(false);
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  // validation
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: handelvalidation,
    onSubmit: async () => {
      sent(initialValues);
    },
  });
  const { handleChange, handleSubmit, values, errors, touched } = formik;


  return (
    <>
      <section>
        <div className="digitech-consult-wrapper header-space">
          <div className="container-fluid custom-container-fluid">
            <div className="back-home-btn text-end mb-4">
              <Link to="/" className="btn">
                {" "}
                <HiMiniArrowLongLeft />{" "}
                <span className="ms-1">Back to Home</span>
              </Link>
            </div>
            <div className="consult-inner-wrapper">
              <div className="consult-left-pannel">
                <div className="consult-inner-text">
                  <div className="consult-form">
                    <h2 className="fw-bold">What would you like to get done?</h2>
                  </div>
                  <div className="row ps-0 flex-wrap mt-5">
                    <div className="col-xl-4 col-md-4 col-sm-6">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckbox"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Website Development"
                          className="fw-medium"
                          name="checkBox1"
                          onChange={handleChange}
                          value={values.checkBox1}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckboxSecond"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Software Development"
                          className="fw-medium"
                          name="checkBox2"
                          onChange={handleChange}
                          value={values.checkBox2}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckboxThird"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Mobile Apps"
                          className="fw-medium"
                          name="checkBox3"
                          onChange={handleChange}
                          value={values.checkBox3}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckboxFour"
                      >
                        <Form.Check
                          type="checkbox"
                          label="UI/UX Design"
                          className="fw-medium"
                          name="checkBox4"
                          onChange={handleChange}
                          value={values.checkBox4}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-4 col-md-4 col-sm-6">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckboxFive"
                      >
                        <Form.Check
                          type="checkbox"
                          label=" Digital Marketing"
                          className="fw-medium"
                          name="checkBox5"
                          onChange={handleChange}
                          value={values.checkBox5}
                        />
                      </Form.Group>
                    </div>
                    <div className="col-xl-4 col-md-4  col-sm-6">
                      <Form.Group
                        className="mb-3"
                        controlId="formBasicCheckboxSix"
                      >
                        <Form.Check
                          type="checkbox"
                          label="Brand Design"
                          className="fw-medium"
                          name="checkBox6"
                          onChange={handleChange}
                          value={values.checkBox6}
                        />
                      </Form.Group>
                    </div>
                  </div>
                  <div className="digitech-form-wrapper">
                    <div className="row mt-5">
                      <div className="col-lg-12 mb-3">
                        <Form.Control
                          type="text"
                          className="rounded-0 py-3 px-3  custom-input"
                          placeholder="Your Name*"
                          value={values.name}
                          name="name"
                          onChange={handleChange}
                        />
                      </div>
                      {errors.name && touched.name ? (
                        <div className="form-error text-danger">
                          {errors.name}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-lg-12 mb-3">
                        <Form.Control
                          type="email"
                          className="rounded-0 py-3 px-3  custom-input"
                          placeholder="Your Email address*"
                          value={values.email}
                          name="email"
                          onChange={handleChange}
                        />
                      </div>
                      {errors.email && touched.email ? (
                        <div className="form-error text-danger">
                          {errors.email}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="col-lg-12 mb-3">
                        <PhoneInput
                          international
                          className="custom-input"
                          defaultCountry="IN"
                          value={value}
                          onChange={setValue}
                        />
                      </div>
                      {errors.phone && touched.phone ? (
                        <div className="form-error text-danger">
                          {errors.phone}
                        </div>
                      ) : (
                        ""
                      )}

                      <div className="col-lg-12 mb-3">
                        <Form.Control
                          type="text"
                          className="rounded-0 py-3  custom-input"
                          placeholder="Where did you hear about us? "
                          value={values.about}
                          name="about"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-12 mb-4">
                        <Form.Control
                          className="rounded-0  custom-input"
                          as="textarea"
                          rows={5}
                          placeholder="Tell us about your project concerns.."
                          value={values.projectConcern}
                          name="projectConcern"
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-lg-12">
                        {
                          mailInfo?(
                            ""
                          ):(
                            <button
                          className="btn consult-btn text-uppercase consult-fix-width"
                          onClick={handleSubmit}
                          disabled={mailInfo || loading}
                        >
                          {loading ? (
                            <div class="spinner-border" role="status">
                            
                            </div>
                          ) : (
                            "Send us a message"
                          )}
                          
              

                          {/* <MdArrowOutward /> */}
                        </button>
                          )
                        }
                        {
                          mailInfo ? (
                            "Thank you! We'll contact you soon"
                          ):(
                            ""
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="consult-right-pannel">
                <div className="consult-testimonial-wrapper">
                  <div className="hangout-icon text-end">
                    <img src={HangoutIcon} alt="hangout" title="hangout" />
                  </div>
                  <div className="custult-testimonial-content">
                    <div className="user-img">
                      <img
                        src={UserImage}
                        className="rounded-2"
                        alt="user"
                        title="user"
                      />
                    </div>
                    <div className="user-review-content  mt-5">
                      <p className="fw-medium">{parse(para)}</p>
                      {/* <div className="star mb-5">
                        <span>
                          <IoIosStar />
                        </span>
                        <span>
                          <IoIosStar />
                        </span>
                        <span>
                          <IoIosStar />
                        </span>
                        <span>
                          <IoIosStar />
                        </span>
                        <span>
                          <IoIosStar />
                        </span>
                      </div> */}
                      <h5 className="theme-font-family mt-5 work-heading">
                        {parse(UserName)} <Link className="linkedin-link" to='https://www.linkedin.com/in/sandeep-ahuja-5771a0b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app' target="_blank"><RxLinkedinLogo /></Link>
                      </h5>
                      <span>Managing Director, Atmosphere Living </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Consult;

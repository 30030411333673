import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function ShowcaseSlider({showcaseSettings, showcasedata}) {
    const settings = {...showcaseSettings}
    return (
        <>
            <Slider {...settings}>
                {showcasedata.map(item=> (
                    <div className={`showcase-slider-wrapper ${item.sliderClass}`}>
                        <div className='showcase-inner-wrapper'>
                            <div className='showcase-left-text'>
                                <h6 className='text-decoration-underline'>{item.smallHeding}</h6>
                                <h5>{item.LargeHeading}</h5>
                            </div>
                            <div className='showcase-right-img'>
                                <img src={item.showcaseImage} alt='Showcase' title='Showcase' />
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    )
}

export default ShowcaseSlider

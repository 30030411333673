import React, { useState } from 'react';
import { Button, ListGroup } from 'react-bootstrap-v5';
import CollabratorsList from './CollabratorsList';
import { Link } from 'react-router-dom';
import Modals from './modals';

function PricingList({ pricinglistdata }) {
    const [modalIndex, setModalIndex] = useState(null); // Track which modal to show

    const handleClose = () => setModalIndex(null);
    const handleShow = (index) => setModalIndex(index);

    return (
        <>
            {pricinglistdata.map((item, index) => (
                <ListGroup.Item as="li" key={index}>
                    {item.recommendedText && (
                        <div className='recommended-wrapper'>
                            <span className='d-block text-center text-uppercase recommended-text'>{item.recommendedText}</span>
                        </div>
                    )}
                    <div className='package-info w-100'>
                        <div className='package-heading'>
                            <h4 className='fw-semibold text-center'>{item.packageHeading}</h4>
                            {item.packageSmallText && <span className='d-block text-center'>{item.packageSmallText}</span>}
                            <div className='cut-off-price d-flex align-items-end justify-content-between'>
                                <h3 className='package-price'><sup className='price-symble'>₹</sup> {item.packagePrice}<span className='month-text'>{item.monthText}</span></h3>
                                <h6 className='cut-heading d-inline-block'><sup>₹</sup> <del>{item.cutPrice}</del></h6>
                            </div>
                            <span className='discount-text'>{item.discountText}</span>
                        </div>
                        {item.collabratorslistdata && (
                            <div className='collabrators-list-wrapper'>
                                <ListGroup as="ol" className='flex-column'>
                                    <CollabratorsList collabratorslistdata={item.collabratorslistdata} />
                                </ListGroup>
                            </div>
                        )}
                        <div className='pricing-btn'>
                            {item.showButton ? (
                                <div className={`modal-button-wrapper package-btn-wrapper ${item.contactPricingBtn}`}>
                                    <Button variant="primary" className={`btn text-uppercase d-block w-100 service-get-started-btn ${item.getStartedPurpleBtn}`} onClick={() => handleShow(index)}>
                                        {item.packageButton}
                                    </Button>
                                    <Modals show={modalIndex === index} handleClose={handleClose} /> 
                                </div>
                            ) : (
                                <div className={`package-btn-wrapper w-100 ${item.contactShowBtn}`}>
                                    <Link to={item.getstartedLink} className={`btn text-uppercase d-block service-get-started-btn ${item.getStartedPurpleBtn}`}>
                                        {item.packageButton}
                                    </Link>
                                </div>
                            )}
                        </div>
                    </div>
                </ListGroup.Item>
            ))}
        </>
    );
}

export default PricingList;

import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function MobShowcaseSlider({mobshowcaseSettings, mobshowdata}) {
    const settings = {...mobshowcaseSettings}
    return (
        <>
            <Slider {...settings}>
                {mobshowdata.map(item => (
                    <div className='mob-slider-wrapper'>
                        <div className='mob-slider-img'>
                            <img src={item.mobSlideImg} title='' alt=''/>
                        </div>
                        <div className='mob-slider-content'>
                            <span>{item.showcaseSmallTitle}</span>
                            <h4 className='theme-font-family work-heading mt-2'>{item.showcaseLargeTitle}</h4>
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    )
}

export default MobShowcaseSlider

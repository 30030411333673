import React from 'react'
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import TabImage from './TabImage';


function ExploreAtmosphereTabs  ( { tabArray=[] }  )  {

    console.log("explore Atmosphere component is loaded ")
    const [activeTab, setActiveTab] = React.useState(0);
    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };
    
    console.log("tab array is  : ",tabArray)
  return (
    <>
    <Tabs activeTab={activeTab} onTabClick={onTabClick} tabsArray={tabArray}>
        {/* generating an array to loop through it  */}

        {tabArray.map((item) => (
            <Tab key={item.coverId} className={`item ${activeTab === item.coverId ? 'active text-start border-0' : 'text-start border-0'}`}>
                <div className='d-flex align-items-center'>
                    <span className='serial-number'>{item.serialNumber}</span>
                    <h5 className='tab-name mb-0'>{item.name}</h5>
                </div>
                <p>{item.para}</p>
            </Tab>
        ))}
    </Tabs>

    
    {tabArray.map((screen)=>(
    <TabScreen
    key={screen.coverId}
    activeTab={activeTab}
    index={screen.coverId}

    // You can add animation with adding a custom class
    >
    <TabImage className='tab-image-right' imageUrl={screen.imageUrl} ImageShadow={screen.ImageShadow} />
    </TabScreen>
    ))}
    </>
  )
}

export default ExploreAtmosphereTabs

import React from 'react'
import { Card } from 'react-bootstrap-v5'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from "react-icons/md";

function SoftwareTechnologiesCard({technologiescarddata}) {
    return (
        <>
            {technologiescarddata.map(item => (
                <div className='col-lg-6 col-md-6'>
                    <Card className='software-technologies-card'>
                        <div className='technologies-left-icon'>
                            <Card.Img className='m-auto' src={item.technologiesImage} alt='' title='Branding' />
                        </div>
                        <Card.Body>
                            <Card.Title className='fw-semibold'>{item.technologiesTitle}</Card.Title>
                            <Card.Text>{item.technologiesPara}</Card.Text>
                            <Link to={item.technologiesLink} className='btn text-uppercase get-started-btn text-decoration-none p-0'>
                            {item.technologiesGetStarted}  <MdArrowOutward />
                            </Link>
                        </Card.Body>
                    </Card>
                </div>
            ))}
        </>
    )
}

export default SoftwareTechnologiesCard

import React from 'react'
import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';

function BannerCards({bannerdata,handleHover,bannerHeading,para}) {
  return (
    <>
        {bannerdata.map(item => (
          <div className='col-lg-2 col-md-4 col-6' key={item.cardBanner}  onMouseEnter={() => handleHover(item.bannerHeading,item.para)}
          onMouseLeave={() => handleHover(bannerHeading,para)}>
            <Card className={`digitech-services-card text-center ${item.cardBanner}`}>
              <div className='card-img m-auto'>
                  <img src={item.ServiceImage}  title='' alt='' />
              </div>
              <Card.Body className='p-0'>
                <Card.Title className='mb-0 text-uppercase'>{item.bannerTitle}</Card.Title>
                <Link to={item.CardLink} className='btn text-uppercase get-started-btn text-decoration-none p-0'>
                  {item.GetStarted}
                </Link>
              </Card.Body>
            </Card>
          </div>
        ))}
    </>
  )
}
export default BannerCards
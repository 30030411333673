import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function WeWorkOnSlider({images,weworkonSetting}) {
    const settings = {...weworkonSetting}
    return (
        <>
            <Slider {...settings}>
                {images.map(item=>(
                    <div className={`software-wrapper text-center m-auto ${item.digitalMarketing}`}>
                        <div className='we-work-img'>
                            <img className={`m-auto ${item.SliderImg} ${item.digitalMarketingImg}`} src={item.softwareImage} alt='Logo' title='Logo' />
                        </div>
                        <h6 className='theme-font-family work-heading mt-3 mb-0'>{item.workText}</h6>
                    </div>
                ))}
            </Slider>
        </>
    )
}

export default WeWorkOnSlider

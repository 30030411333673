import React from 'react'
import parse from 'html-react-parser'
import CasestudyBanner from '../../components/CasestudyBanner'
import solfinImage from '../../assets/images/ozen-mockup.webp'
import AboutClient from '../../components/AboutClient'
import ChallengeList from '../../components/ChallengeList'
import DeliverablesBadge from '../../components/DeliverablesBadge'
import ExploreAtmosphereTabs from '../../components/ExploreAtmosphereTabs'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md';
import imageOne from '../../assets/images/ozen-solution-1.webp'
import imageTwo from '../../assets/images/ozen-solution-2.webp'
import imageThree from '../../assets/images/ozen-solution-3.webp'
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import AtmosphereLeftImage from '../../assets/images/ozen-website-dark.webp'
import AtmosphereMobile from '../../assets/images/ozen-web-light.webp'
import AtmosphereOffer from '../../assets/images/ozen-web-two.webp'
import AtmosphereLms from '../../assets/images/ozen-lead-management.webp'
import ResizeSlider from '../../components/ResizeSlider'
import TechnologyCard from '../../components/TechnologyCard'
import TechnologyImage from '../../assets/images/react.webp'
import TechnologyImage2 from '../../assets/images/node-js.webp'
import TechnologyImage3 from '../../assets/images/postgresql.webp'
import TechnologyImage4 from '../../assets/images/figma.webp'
import TechnologyImage5 from '../../assets/images/html.webp'
import TechnologyImage6 from '../../assets/images/bootstrap-logo.webp'
import FeaturesUsedCard from '../../components/FeaturesUsedCard'
import FeaturesImage from '../../assets/images/multi-site-management.webp'
import FeaturesImage2 from '../../assets/images/unified-library.webp'
import FeaturesImage3 from '../../assets/images/user-role.webp'
import ConsultUs from '../../components/ConsultUs'
import ResultCards from '../../components/ResultCards'
import ResultImage from '../../assets/images/report-up.svg'
import ResultImage2 from '../../assets/images/report-down.svg'
import SuccessSlider from '../../components/SuccessSlider'
import QuotesImage from '../../assets/images/atmosphere-quotes.svg'
import UserImage from '../../assets/images/user.webp'
import WeWorkOnSlider from '../../components/WeWorkOnSlider'
import SolutionCaseStudyTabs from '../../components/SolutionCaseStudyTabs'


function OzenCollection() {
    const bannerSmallHeading = 'client spotlight'
    
    const bannerLargeHeading = "Elevating THE OZEN COLLECTION's Luxury Hospitality Experience"
    const bannerBackground = 'ozen-wrapper'
    const smallHeading = 'Ozen-heading-color'
    const casestudyBannerBackground = 'game-store-banner-background'
    
    const aboutdata = [
        {AboutHeading: 'About the client', SecondSmallHeading: 'Hospitality', SecondLargeHeading: 'The Ozen Collection', AboutPara: 'Introducing THE OZEN COLLECTION, where luxury meets personalized service, standing as a beacon of opulence and refinement in the world of hospitality. Renowned for its uber-luxury resorts and personified services, THE OZEN COLLECTION has consistently set the standard for unparalleled extravagance and guest satisfaction. However, even amidst its prestigious reputation, THE OZEN COLLECTION recognized the need for evolution.', AboutParaTwo: 'Tasked with upgrading design and brand copy while unifying backend CMS operations, the client sought to streamline efficiency and elevate their online presence. With a focus on simplifying content management and enabling seamless deal and offer creation, THE OZEN COLLECTION aimed to reflect its unparalleled luxury across all digital touchpoints, ensuring a captivating experience for its discerning clientele.', ChallengesHeading: 'Challenges',},
    ]

    const ChallengesHeading = 'Challenges'

    const listdata = [
        {SerialNumber: '01.', ListParaOne: 'Separate website CMS for each website hindered operational efficiency and could lead to challenges in maintaining consistent branding and content standards across different online properties.'},
  
        {SerialNumber: '02.', ListParaOne: 'Duplication of data & effort in managing content across websites often led to inefficiencies, inconsistencies, increased chances of errors, and susceptibility to malware due to manual synchronization.' },
         
        {SerialNumber: '03.', ListParaOne: 'Outdated website design, coupled with subpar website copy, exacerbated engagement and latency issues. Slow website loading impacted user experience, as traditional CMS struggled with content delivery optimization.'}
    ]

    const badgedata = [
        {TabText: 'Website Design'}, {TabText: 'Website Communication'}, {TabText: 'SEO Copywriting'}, {TabText: 'UI-UX Design'}, {TabText: 'Backend Development'}, {TabText: 'Unified - CMS'}
    ]

    

    const parafour= 'Crafting the website copy for THE OZEN COLLECTION and its resort website goes beyond traditional SEO strategies, delving into the realm of evocative storytelling and finely curated experiences. This deliberate approach is tailored to elevate brand communication, captivating visitors with enchanting narratives that immerse them in unforgettable journeys while ensuring a seamless and extraordinary user experience. By weaving together rich narratives and intuitive design, we invite guests to embark on a voyage of luxury and discovery, leaving them longing to return again and again.'

    const parashowcase='Experience the evolution of luxury hospitality with THE OZEN COLLECTION, as we redefine sophistication in the digital realm. Streamlining operations and captivating audiences with tailored solutions, we set a new standard in online engagement, where every click unveils bespoke luxury.'

    const parafive='Say goodbye to outdated interfaces and hello to sleek, intuitive design. Our redesign boasts a modern aesthetic, ensuring that every interaction is a delight. Whether the guest is a seasoned web user or a newcomer, navigating through the interface is a breeze, allowing the guest to focus on what matters most – their experience'

    const ozenBeforeImage= 'ozen-before-wrapper'

    const ozenAfterImage= 'ozen-after-wrapper'

    const technologydata = [
        {TechnologyImage: TechnologyImage, technologyTitle:'React'},
        {TechnologyImage: TechnologyImage2, technologyTitle:'Node'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'Postgres'},
        {TechnologyImage: TechnologyImage4, technologyTitle:'Figma', cardImage:'technology-img'},
        {TechnologyImage: TechnologyImage5, technologyTitle:'HTML 5'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'Bootstrap'}
    ]

    const featuresuseddata = [
        {featuresImage: FeaturesImage, featuresTitle:'Multi-Site Management', featureText:'Ability to manage multiple websites (main site and sub-brand sites).'},
        {featuresImage: FeaturesImage2, featuresTitle:'Unified Content Library', featureText:'Centralised repository for media assets (images, videos, documents).'},
        {featuresImage: FeaturesImage3, featuresTitle:'User Role management', featureText:'Tools to arrange site navigation & menu for each sub-brand site independently.', featurecard:'feature-card'}
    ]

    const FeaturesPara='Experience the power of Unified Content Manager features utilized by THE OZEN COLLECTION, which helped optimize their resort website operations. This powerful tool facilitated the seamless management of content and media across resorts.'

    const resultdata=[
        {resultTitle:'35%', resultImage: ResultImage, resultPara:'Website Speed', ozenResultCard:'ozen-result-card'},
        {resultTitle:'2x', resultImage: ResultImage, resultPara:'Operation Efficiency', ozenResultCard:'ozen-result-card'},
        {resultTitle:'90%', resultImage: ResultImage2, resultPara:'Data Duplicacy', ozenResultCard:'ozen-result-card'},
        {resultTitle:'40%', resultImage: ResultImage, resultPara:'Load Times', ozenResultCard:'ozen-result-card'}
    ]

    const resultPara="THE OZEN COLLECTION adopts React and Node.js with a PostgreSQL database, leveraging the virtual DOM for quick updates and integrated state management for efficient page tracking. Reacts component-based architecture enables the creation of reusable UI components, ensuring scalable and maintainable front-end code."

    // const successSetting = {
    //     arrow: true,
    //     dots: true,
    //     centerMode: true,
    //     infinite: true,
    //     autoplay: false,
    //     slidesToShow: 1,
    //     centerPadding: '0',
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 991,
    //           settings: {
    //             slidesToShow: 1,
    //             centerPadding: '30px',
    //           },
    //           breakpoint: 767,
    //           settings: {
    //             slidesToShow: 1,
    //             centerPadding: '0',
    //           }
    //         }
    //       ]
    // };

    // const text = [
    //     {QuotesImage: QuotesImage, QutesPara: 'Digitech has done an amazing work on our project Lorem ipsum dolor sit amet consectetur adipiscing elit. Nunc laoreet augue nec leo gravida faucibus. Nullam aliquet erat nisl quis ornare lorem elementum ut. In luctus eros ut massa varius sollicitudin.', UserImage: UserImage, ClientName: "Client's Full Name"},
    //     {QuotesImage: QuotesImage, QutesPara: 'Digitech has done an amazing work on our project Lorem ipsum dolor sit amet consectetur adipiscing elit. Nunc laoreet augue nec leo gravida faucibus. Nullam aliquet erat nisl quis ornare lorem elementum ut. In luctus eros ut massa varius sollicitudin.', UserImage: UserImage, ClientName: "Client's Full Name"},
    //     {QuotesImage: QuotesImage, QutesPara: 'Digitech has done an amazing work on our project Lorem ipsum dolor sit amet consectetur adipiscing elit. Nunc laoreet augue nec leo gravida faucibus. Nullam aliquet erat nisl quis ornare lorem elementum ut. In luctus eros ut massa varius sollicitudin.', UserImage: UserImage, ClientName: "Client's Full Name"}
    // ]

    const consultdata = [
        {smallHeading: 'Elevating luxury hospitality ', largeHeading: "THE OZEN COLLECTION's Comprehensive Approach", consultPara: "Seamless integration of intuitive tools adds restaurant offerings and villas effortlessly, while enhanced image quality captivates. This approach streamlines operations, boosts inquiries, and positions The OZEN COLLECTION as a pinnacle of luxury hospitality.", pageLink: '/consult', LinkText: 'get started'}
    ]

    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },

            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

    const images = [
        {softwareImage: TechnologyImage, workText:'React'},
        {softwareImage: TechnologyImage2, workText:'Node Js'},
        {softwareImage: TechnologyImage3, workText:'Postgres'},
        {softwareImage: TechnologyImage4, workText:'Figma'},
        {softwareImage: TechnologyImage5, workText:'HTML 5'},
        {softwareImage: TechnologyImage6, workText:'Bootstrap'},
    ]

    const responsivetabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Unified Content Manager', serialNumber:"01.", para:"Unified CMS streamlines website management by enabling control over multiple sites with one login, simplifying tasks like content updates, user management, and media handling, while ensuring consistency and control.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Create deals and offers', serialNumber:"02.", para:"Utilize custom design tools to craft enticing offers, showcase Booking, Spa, and Dining deals on the homepage with clear start and end dates, and seamlessly share them on social media for enhanced visibility and engagement.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Manage Villas and Suites', serialNumber:"03.", para:"Listing & Categorizing tools streamline the process for Hotels & Resorts to list rooms, suites, and villas swiftly and intuitively. They enable quick categorization and listings while effortlessly incorporating signature features.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    const tabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Unified Content Manager', serialNumber:"01.", para:"Unified CMS streamlines website management by enabling control over multiple sites with one login, simplifying tasks like content updates, user management, and media handling, while ensuring consistency and control.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Create deals and offers', serialNumber:"02.", para:"Utilize custom design tools to craft enticing offers, showcase Booking, Spa, and Dining deals on the homepage with clear start and end dates, and seamlessly share them on social media for enhanced visibility and engagement.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Manage Villas and Suites', serialNumber:"03.", para:"Listing & Categorizing tools streamline the process for Hotels & Resorts to list rooms, suites, and villas swiftly and intuitively. They enable quick categorization and listings while effortlessly incorporating signature features.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    console.log("tab  ",responsivetabArray)

    return (
        <>
            <CasestudyBanner showLargeHeading={false} bannerSmallHeading={bannerSmallHeading} bannerLargeHeading={bannerLargeHeading} showBannerImage={false} bannerBackground={bannerBackground} smallHeading={smallHeading} solfinImage={solfinImage} casestudyBannerBackground={casestudyBannerBackground}  />

            <AboutClient aboutdata={aboutdata} />

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-heading'>
                            <h2>{ChallengesHeading}<span className="brand-color">.</span></h2>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <div className='about-list-wrapper'>
                                        <ChallengeList listdata={listdata} />
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='right-content'>
                                        <h3 className='about-right-heading fw-semibold'>Deliverables</h3>
                                        <div className='atmosphere-tags'>
                                            <DeliverablesBadge badgedata={badgedata} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* challenges */}

            <section>
                <div className='digitech-solution'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                <div className='atmosphere-heading'>
                                    <h2 className='mb-0'>Solution<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            <div className='col-lg-6 col-md-6 col-sm-6'>
                                {/* <div className='atmosphere-link text-end'>
                                <Link className='text-uppercase mt-4 d-inline-block theme-btn' to=''>View More <MdArrowOutward /></Link>
                                </div> */}
                            </div>
                        </div>
                        <div className='digitechtabs casestudy-desktop-tabs ozen-tabs-wrapper d-flex justify-content-between'>
                            <ExploreAtmosphereTabs tabArray={tabArray}/>
                        </div>
                        <div className='responsive-digitech-tab ozen-responsive-tab'>
                            <SolutionCaseStudyTabs responsivetabArray={responsivetabArray} />
                        </div>
                    </div>
                </div>
            </section>
            {/* soluitons */}

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-heading atmosphere-space mt-0'>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <div className='copywritting-para'>
                                        <h2>SEO Copywriting<span className='brand-color'>.</span></h2>
                                        <p>{parse(parafour)}</p>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='right-content copywriting-tabs'>
                                        
                                        <div className='atmosphere-tags'>
                                            <Stack direction="vertical" className='deliverables-tabs align-items-baseline mt-4' gap={3}>
                                                <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>Engaging Copy</Badge>
                                                <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>Tone of Voice</Badge>
                                                <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>SEO Keywords</Badge>
                                                <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>Brand Story</Badge>
                                            </Stack>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* SEO Copyright */}

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-6 col-md-6 col-12'>
                                <div className='atmosphere-heading'>
                                    <h2>Showcase<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            {/* <div className='col-lg-6 col-md-6 col-12'>
                                <div className='atmosphere-link text-end'>
                                    <Link className='text-uppercase mt-4 d-inline-block theme-btn' to=''>visit site <MdArrowOutward /></Link>
                                </div>
                            </div> */}
                            <div className='col-lg-11'>
                                <div className='showcase-para'>
                                    <p>{parse(parashowcase)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='atmosphere-design-wrapper ozen-showcase-wrapper'>
                            <div className='atmosphere-left-pannel'>
                                <div className='left-design ozen-design'>
                                    <h6 className='heading-font'>Website design - Dark theme</h6>
                                    <img src={AtmosphereLeftImage} title='home' alt='home' className='w-100' />
                                </div>
                            </div>
                            
                            <div className='atmosphere-right-pannel'>
                                <div className='right-design d-flex align-items-center justify-content-between'>
                                    <div className='atmosphere-mobile ozen-design ozen-reserve-space'>
                                        <h6 className='heading-font'>The Reserve Plan & Villas</h6>
                                        <img src={AtmosphereMobile} title='mobile' alt='mobile' className='w-100' />
                                    </div>
                                    <div className='atmosphere-mobile  ozen-design mt-lg-0 mt-md-0 mt-sm-4 ps-5'>
                                        <h6 className='heading-font'>Experiences - Renewal of Vows</h6>
                                        <img src={AtmosphereOffer} title='offer' alt='offer' className='w-100' />
                                    </div>
                                </div>
                                <div className='right-design mt-5'>
                                    <div className='atmosphere-mobile ozen-design'>
                                        <h6 className='heading-font'>Lead Management</h6>
                                        <img src={AtmosphereLms} title='lms' alt='lms' className='w-100'/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* showcase */}

            <section>
                <div className='container'>
                    <div className='atmosphere-heading atmosphere-case-study'>
                        <div className='row'>
                            <div className='col-lg-12'>
                                <div className='copywritting-para'>
                                    <h2 className='fw-bold'>Modern Design<span className='brand-color'>.</span></h2>
                                    <p className='mt-5'>{parse(parafive)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='atmosphere-resizable-pannel'>
                            <div className='diffrence-text d-flex align-items-center justify-content-between'>
                                <h6 className='fw-semibold'>Before</h6>
                                <h6 className='fw-semibold'>After</h6>
                            </div>
                            <ResizeSlider ozenBeforeImage={ozenBeforeImage} ozenAfterImage={ozenAfterImage} />
                        </div>
                    </div>
                </div>
            </section>
            {/* morden design */}

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='fw-bold'>Technologies used<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='copywritting-para'>
                            <div className='row technologies-card-wrapper'>
                                <TechnologyCard technologydata={technologydata}/>
                            </div>
                            <div className='testimonal-slider technology-slider-wrapper'>
                                <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Technologies used */}


            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='copywritting-para'>
                            <div className='row align-items-center'>
                                <div className='col-lg-7'>
                                    <div className='unified-heading'>
                                        <h2 className='fw-bold'>Unified Content Manager<span className='brand-color'>.</span></h2>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='unified-btn text-end'>
                                        <Link className='text-uppercase mt-4 d-inline-block theme-btn' to='/comet'>know More <MdArrowOutward /></Link>
                                    </div>
                                </div>
                                <div className='col-lg-9'>
                                    <div className='features-paragraph mt-5'>
                                        <p>{parse(FeaturesPara)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='row mt-5'>
                                <FeaturesUsedCard featuresuseddata={featuresuseddata}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Unified CMS features */}

            <ConsultUs consultdata={consultdata} showSmallHeading={false} />

            <section>
                <div className='about-atmosphere atmosphere-case-study casestudy-result-space'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-6'> 
                                <div className='result-wrapper'>
                                    <h2 className='fw-bold'>End Result<span className='brand-color'>.</span></h2>
                                    <div className='result-suv-heading'>
                                        <h3>Revolutionizing Digital Presence with React and Node.js</h3>
                                    </div>
                                    <div className='result-btn-wrapper'>
                                        <Link to='/consult' className='btn consult-btn ozen-result-btn'>Consult Us <MdArrowOutward /></Link>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <div className='result-right-wrapper'>
                                    <p>{parse(resultPara)}</p>
                                    <div className='result-inner'>
                                        <div className='row'>
                                            <ResultCards resultdata={resultdata}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Result */}

            {/* <section>
                <div className='about-atmosphere pt-0'>
                    <div className='container'>
                        <SuccessSlider successSetting={successSetting} text={text} />
                    </div>
                </div>
            </section> */}
            {/* End Result */}
        </>
    )
}

export default OzenCollection

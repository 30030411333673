import React from 'react'
import { Card } from 'react-bootstrap-v5'

function EcommerceFeaturesCardsSecond({ecommercedatasecond}) {
    return (
        <>
            {ecommercedatasecond.map(item=>(
                <Card className={`ecommerce-features-card text-center rounded-0  ${item.brandFeatures} ${item.digitalFeatures} ${item.brandresponsive}`}>
                    <div className={`card-img-wrapper ${item.featureImageWrapper}`}>
                        <Card.Img className={`m-auto ${item.ecommerceCardImage}`} src={item.ecommerceImage} alt='' title='' />
                    </div>
                    <Card.Body  className='p-0'>
                        <Card.Title className='mb-3'>{item.ecommerceTitle}</Card.Title>
                        <Card.Text className={`${item.digitalText}`}>{item.ecommerceText}</Card.Text>
                    </Card.Body>
                </Card>
            ))}
        </>
    )
}

export default EcommerceFeaturesCardsSecond

import React from 'react'
import { ListGroupItem } from 'react-bootstrap-v5'

function AhrColorUsage({colordata}) {
    return (
        <>
            {colordata.map (item =>(
                <ListGroupItem as='li' className='border-0'>
                    <div className='color-wrapper'>
                        <h6 className='heading-font-family'>{item.AhrColorCode}</h6>
                        <p className='mb-0'>{item.AhrColorName}</p>
                    </div>
                </ListGroupItem>
            ))}
        </>
    )
}

export default AhrColorUsage

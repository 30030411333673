import React from 'react'
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';

function DeliverablesBadge({badgedata}) {
    return (
        <>
            {badgedata.map(item =>(
                <Stack direction="vertical" className='deliverables-tabs align-items-baseline mt-3' gap={3}>
                    <Badge pill className='bg-transparent border text-dark fw-normal px-5 fs-6 py-3'>{item.TabText}</Badge>
                </Stack>
            ))}
        </>
    )
}

export default DeliverablesBadge

import React from 'react'
import FolioInnerBanner from '../../components/FolioInnerBanner'
import atmosphereLogoImg from '../../assets/images/ozen-large-logo.svg'
import ozenLogoOne from "../../assets/images/ahr-logo.svg"
import ozenLogoTwo from "../../assets/images/kanifushi-logo.svg"
import ozenLogoThree from "../../assets/images/varu-logo.svg"
import ozenLogoFour from "../../assets/images/raaya-logo.svg"
import { ListGroup } from 'react-bootstrap-v5'
import FolioLogo from '../../components/FolioLogo'
import AhrColorUsage from '../../components/AhrColorUsage'
import FolioWebsiteTabs from '../../components/FolioWebsiteTabs'
import imageOne from '../../assets/images/ahr-homepage.webp'
import imageTwo from '../../assets/images/kanifushi-homepage.webp'
import imageThree from '../../assets/images/varu-homepage.webp'   
import imageFour from '../../assets/images/raaya-homepage.webp'
import frameImage from '../../assets/images/upper-frame.webp'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from "react-icons/md";
import iconDarkOne from '../../assets/images/ahr-outline-one.svg'
import iconDarkTwo from '../../assets/images/ahr-outline-two.svg'
import iconDarkThree from '../../assets/images/ahr-outline-three.svg'
import iconDarkFour from '../../assets/images/ahr-outline-four.svg'
import iconDarkFive from '../../assets/images/ahr-outline-five.svg'
import iconDarkSix from '../../assets/images/ahr-outline-six.svg'
import iconDarkSeven from '../../assets/images/ahr-outline-seven.svg'
import iconDarkEight from '../../assets/images/ahr-outline-eight.svg'
import iconDarkNine from '../../assets/images/ahr-outline-nine.svg'
import iconShadowOne from '../../assets/images/ahr-filled-icon-one.svg'
import iconShadowTwo from '../../assets/images/ahr-filled-icon-two.svg'
import iconShadowThree from '../../assets/images/ahr-filled-icon-three.svg'
import iconShadowFour from '../../assets/images/ahr-filled-icon-four.svg'
import iconShadowFive from '../../assets/images/ahr-filled-icon-five.svg'
import iconShadowSix from '../../assets/images/ahr-filled-icon-six.svg'
import iconShadowSeven from '../../assets/images/ahr-filled-icon-seven.svg'
import iconShadowEight from '../../assets/images/ahr-filled-icon-eight.svg'
import iconShadowNine from '../../assets/images/ahr-filled-icon-nine.svg'
import iconShadowTen from '../../assets/images/ahr-colored-icon-one.svg'
import iconShadowEleven from '../../assets/images/ahr-colored-icon-two.svg'
import iconShadowTwelve from '../../assets/images/ahr-colored-icon-three.svg'
import iconShadowThirteen from '../../assets/images/ahr-colored-icon-four.svg'
import iconShadowFourteen from '../../assets/images/ahr-colored-icon-five.svg'
import iconShadowFifteen from '../../assets/images/ahr-colored-icon-six.svg'
import iconShadowSixteen from '../../assets/images/ahr-colored-icon-seven.svg'
import iconShadowSeventeen from '../../assets/images/ahr-colored-icon-eight.svg'
import iconShadowEighteen from '../../assets/images/ahr-colored-icon-nine.svg'
import OzenColorsIcons from '../../components/OzenColorsIcons'
import OzenColorsIconSecond from '../../components/OzenColorsIconSecond'
import MobileAppImage from '../../assets/images/ahr-mobile-view.webp'
import BrochureImage from '../../assets/images/ahr-ui-sheet-left.webp'
import BrochureImageSecond from '../../assets/images/ahr-ui-sheet-right.webp'
import AhrColorsIcons from '../../components/AhrColorsIcons'
import seoCopywritingImage from '../../assets/images/ahr-seo-copywriting-img.webp'
import SeoCopywritingList from '../../components/SeoCopywritingList'
import FolioUcmsTabs from '../../components/FolioUcmsTabs'
import imageUcmsOne from '../../assets/images/solution-1.webp'
import imageUcmsTwo from '../../assets/images/solution-1.webp'
import ConsultUs from '../../components/ConsultUs'

function AtmosphereHotelResortFolio() {
    const folioHeading = 'Atmosphere Hotels & Resorts'
    const createdDate = 'March, 2024'
    const subHeading = 'Hospitality'
    const CaseStudyLink = '/atmosphere'
    const foliobannerpara = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries."
    const ahrBackground = 'ahr-background'
    
    const badgedata = [
        {TabText: 'Website Design'}, {TabText: 'Website Communication'}, {TabText: 'SEO Copywriting'}, {TabText: 'UI-UX Design'}, {TabText: 'Backend Development'}, {TabText: 'Unified - CMS'}
    ]

    const logodata = [
        {ozenLogo: ozenLogoOne}, {ozenLogo: ozenLogoTwo}, {ozenLogo: ozenLogoThree}, {ozenLogo: ozenLogoFour}
    ]

    const colordata = [
        {AhrColorCode: '#6E9A95', AhrColorName: 'Dark Green'},
        {AhrColorCode: '#A9D1C5', AhrColorName: 'Pale Green'},
        {AhrColorCode: '#FDFAF7', AhrColorName: 'OFF White'},
        {AhrColorCode: '#B07C56', AhrColorName: 'Chamoisee'},
        {AhrColorCode: '#549DAE', AhrColorName: 'Moonstone'},
        {AhrColorCode: '#333333', AhrColorName: 'Jet'}
    ] 

    const tabArray = [
        {
          coverId: 0, name: 'Atmosphere Hotels and Resorts', imageUrl: imageOne, imageFrameUrl: frameImage, ahrtabContent: 'ahr-tab-content', ahrDropShadow: 'ahr-dropshadow'
        }, 
        {
          coverId: 1, name: 'Atmosphere Kanifushi', imageUrl: imageTwo, imageFrameUrl: frameImage, ahrtabContent: 'ahr-tab-content', ahrDropShadow: 'ahr-dropshadow'
        },
        {
          coverId: 2, name: 'Varu By Atmosphere', imageUrl: imageThree, imageFrameUrl: frameImage, ahrtabContent: 'ahr-tab-content', ahrDropShadow: 'ahr-dropshadow'
        },
        {
          coverId: 3, name: 'Raaya By Atmosphere', imageUrl: imageFour, imageFrameUrl: frameImage, ahrtabContent: 'ahr-tab-content', ahrDropShadow: 'ahr-dropshadow'
        }
    ];

    const atmospherePara = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries."

    const ahriconcolorsdata = [
        {iconOne : iconDarkOne, iconTwo : iconDarkTwo, iconThree : iconDarkThree, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconDarkFour, iconTwo : iconDarkFive, iconThree : iconDarkSix, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconDarkSeven, iconTwo : iconDarkEight, iconThree : iconDarkNine, ahrInnerIcon : 'ahr-inner-icon'}
    ]

    const iconcolorsdata = [
        {iconOne : iconShadowOne, iconTwo : iconShadowFour, iconThree : iconShadowSeven, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconShadowTwo, iconTwo : iconShadowFive, iconThree : iconShadowEight, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconShadowThree, iconTwo : iconShadowSix, iconThree : iconShadowNine, ahrInnerIcon : 'ahr-inner-icon'}
    ]

    const iconcolorsdatasecond = [
        {iconOne : iconShadowTen, iconTwo : iconShadowThirteen, iconThree : iconShadowSixteen, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconShadowEleven, iconTwo : iconShadowFourteen, iconThree : iconShadowSeventeen, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconShadowTwelve, iconTwo : iconShadowFifteen, iconThree : iconShadowEighteen, ahrInnerIcon : 'ahr-inner-icon'}
    ]

    const copywritingPara = "Crafting the website copy for Atmosphere Hotel & Resort transcends traditional SEO strategies, delving deep into the realm of evocative storytelling and finely curated experiences. This deliberate approach is tailored to elevate brand communication, captivating visitors with enchanting narratives that immerse them in unforgettable journeys while ensuring a seamless and extraordinary user experience."


    const seocopywritingdata = [
        {serialNumber: '1.', copywritingTitle:'Engaging Copy', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'},
        {serialNumber: '2.', copywritingTitle:'Tone of Voice', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'},
        {serialNumber: '3.', copywritingTitle:'SEO Keywords', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'},
        {serialNumber: '4.', copywritingTitle:'Brand Story', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'}
    ]

    const ucmstabArray = [
        {
            coverId:0, name:'Lorem ipsum ipsum', imageUrl:imageUcmsOne,  imageFrameUrl: frameImage, hideScrollAnimation:'hide-scroll-animation', ucmsTabImage: 'ucms-tab-image', ahrUcmstabs: 'ahr-ucms-wrapper'
        }, 
        {
            coverId:1, name:'Lorem ipsum ipsum', imageUrl:imageUcmsTwo,  imageFrameUrl: frameImage, hideScrollAnimation:'hide-scroll-animation', ucmsTabImage: 'ucms-tab-image', ahrUcmstabs: 'ahr-ucms-wrapper'
        }
    ]

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Let's get started!", consultPara: "Whether you're optimising operations, expanding reach, or enhancing customer satisfaction, our solutions cover software, mobile app development, and websites.", consultPortfolio: 'faqs-consult-wrapper', pageLink: '/consult', LinkText: 'get started'}
    ]

    return (
        <>
            <FolioInnerBanner atmosphereLogo={atmosphereLogoImg} folioHeading= {folioHeading} foliobannerpara= {foliobannerpara} badgedata={badgedata}  createdDate={createdDate} subHeading={subHeading} ahrBackground={ahrBackground} CaseStudyLink={CaseStudyLink} />

            <section>
                <div className='about-atmosphere'>
                    <div className='container'>
                        <div className='folio-logo-wrapper ahr-logo-wrapper'>
                            <ListGroup as='ul'>
                                <FolioLogo logodata={logodata}/>
                            </ListGroup>
                        </div>
                        <div className='colo-usage-main'>
                            <div className='color-usage-heading'>
                                <h3 className='heading-font-family'>Color Usage</h3>
                            </div>
                            <div className='color-usage-wrapper d-flex'>
                                <div className='primary-color-usage w-100 ahr-color-wrapper'>
                                    <ListGroup as='ul' className='ozen-color-usage'>
                                        <AhrColorUsage colordata={colordata} />
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study position-relative'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-website-folio'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Website<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link to='https://varu-atmosphere.com/' target="_blank" className='text-uppercase d-inline-block theme-btn'>Visit Site <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='website-tabs-wrapper'>
                                <FolioWebsiteTabs tabArray={tabArray} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='typography-wrapper'>
                    <div className='container'>
                        <div className='typography-heading'>
                            <h3>Typography</h3>
                        </div>
                        <div className='typography-inner-wrapper ahr-typography-wrapper'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='typography-family-info'>
                                        <div className='alphabet-font-wrapper d-flex align-items-center'>
                                            <h1 className='mb-0'>Aa</h1>
                                            <span>Georgia</span>
                                        </div>
                                        <div className='alphabets-wrapper'>
                                            <h2>abcdefghijklmnopqrstuvwxyz</h2>
                                            <h3>abcdefghijklmnopqrstuvwxyz</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='typography-family-info typography-family-right'>
                                        <div className='alphabet-font-wrapper d-flex align-items-center'>
                                            <h1 className='mb-0'>Aa</h1>
                                            <span>Arial</span>
                                        </div>
                                        <div className='alphabets-wrapper'>
                                            <h2>abcdefghijklmnopqrstuvwxyz</h2>
                                            <h3>abcdefghijklmnopqrstuvwxyz</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='typography-wrapper iconography-wrapper'>
                    <div className='container'>
                        <div className='typography-heading'>
                            <h3>Iconography</h3>
                        </div>
                        <div className='typography-inner-wrapper ahr-icon-typography-wrapper'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='ozen-icon-wrapper'>
                                        <h6>Line art icons</h6>
                                        <div className='d-flex flex-wrap justify-content-between responsive-icon-wrapper'>
                                            <AhrColorsIcons ahriconcolorsdata={ahriconcolorsdata}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='ozen-icon-wrapper'>
                                        <h6>filled  icons</h6>
                                        <div className='d-flex flex-wrap justify-content-between responsive-icon-wrapper'>
                                            <OzenColorsIcons iconcolorsdata={iconcolorsdata}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='ozen-icon-wrapper'>
                                        <h6>colored icons</h6>
                                        <div className='d-flex flex-wrap justify-content-between responsive-icon-wrapper'>
                                            <OzenColorsIconSecond iconcolorsdatasecond={iconcolorsdatasecond}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-mobile-folio'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Mobile View<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link to='https://varu-atmosphere.com/' target="_blank"  className='text-uppercase d-inline-block theme-btn'>View All <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='website-img'>
                                <img src={MobileAppImage} title='' alt='' className='w-100' />
                            </div>
                        </div>
                        <div className='atmosphere-ui-sheet'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>UI Sheet<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='ahr-ui-sheet-wrapper'>
                                        <img src={BrochureImage} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='ahr-ui-sheet-wrapper'>
                                        <img src={BrochureImageSecond} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-ui-sheet pt-0'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>SEO Copywriting<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper w-100 d-flex align-item-center justify-content-between'>
                                    <p className='mb-0'>{copywritingPara}</p>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-lg-7 col-md-7'>
                                    <div className='seo-copywriting-wrapper'>
                                        <img src={seoCopywritingImage} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-5'>
                                    <div className='copywriting-list-wrapper'>
                                        <ListGroup as='ul' className='align-items-center justify-content-center'>
                                            <SeoCopywritingList seocopywritingdata={seocopywritingdata}/>
                                        </ListGroup>            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study position-relative'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-website-folio pb-0'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>UCMS Design<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                            </div>
                            <div className='website-tabs-wrapper'>
                                <FolioUcmsTabs ucmstabArray={ucmstabArray}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <Link to="/ozen-collection" className='healthism-btn-wrapper ahr-ozen-wrapper mt-0 w-100 text-center'>
                            <span className='d-block'>next project</span>
                            <h2>The Ozen Collection</h2>
                        </Link>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} showheading={false} showSmallHeading={false} /> 
        </>
    )
}

export default AtmosphereHotelResortFolio

import React from 'react'

function OzenDarkIcons({icondata}) {
    return (
        <>
            {icondata.map(item =>(
                <div className={`ozen-inner-icons justify-content-between  d-flex align-items-center ${item.ahrInnerIcon}`}>
                    <div className='icon-wrapper'>
                        <img src={item.iconOne} title='' alt='' />
                    </div>
                    <div className='icon-wrapper'>
                        <img src={item.iconTwo} title='' alt='' />
                    </div>
                    <div className='icon-wrapper me-0 mb-0'>
                        <img src={item.iconThree} title='' alt='' />
                    </div>
                </div>
            ))}
        </>
    )
}

export default OzenDarkIcons

import React from 'react'
import { ListGroupItem } from 'react-bootstrap-v5'

function FolioLogo({logodata}) {
    return (
        <>
            {logodata.map (item =>(
                <ListGroupItem as='li'>
                    <div className={`logo-image-wrapper ${item.atmosphereLivingLogo}`}>
                        <img src={item.ozenLogo} title='' alt='' className='atmosphere' />
                    </div>
                </ListGroupItem>
            ))}
        </>
    )
}

export default FolioLogo

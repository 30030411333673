import React from 'react'
import FolioInnerBanner from '../../components/FolioInnerBanner'
import atmosphereLogoImg from '../../assets/images/ozen-large-logo.svg'
import AhrColorUsage from '../../components/AhrColorUsage'
import { ListGroup } from 'react-bootstrap-v5'
import FolioLogo from '../../components/FolioLogo'
import ozenLogoOne from "../../assets/images/atmosphere-living-logo.svg"
import FolioWebsiteTabs from '../../components/FolioWebsiteTabs'
import imageOne from '../../assets/images/atmosphere-living-home.webp'
import imageTwo from '../../assets/images/atmosphere-living-sustainability.webp'
import imageThree from '../../assets/images/atmosphere-living-sustainability.webp'   
import frameImage from '../../assets/images/upper-frame.webp'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from "react-icons/md";
import iconDarkOne from '../../assets/images/al-filled-one.svg'
import iconDarkTwo from '../../assets/images/al-filled-four.svg'
import iconDarkThree from '../../assets/images/al-filled-seven.svg'
import iconDarkFour from '../../assets/images/al-filled-four.svg'
import iconDarkFive from '../../assets/images/al-filled-five.svg'
import iconDarkSix from '../../assets/images/al-filled-six.svg'
import iconDarkSeven from '../../assets/images/al-filled-three.svg'
import iconDarkEight from '../../assets/images/al-filled-eight.svg'
import iconDarkNine from '../../assets/images/al-filled-nine.svg'
import iconShadowOne from '../../assets/images/al-filled-ten.svg'
import iconShadowTwo from '../../assets/images/al-filled-eleven.svg'
import iconShadowThree from '../../assets/images/al-filled-twelve.svg'
import iconShadowFour from '../../assets/images/al-filled-thirteen.svg'
import iconShadowFive from '../../assets/images/al-filled-fourteen.svg'
import iconShadowSix from '../../assets/images/al-filled-fifteen.svg'
import iconShadowSeven from '../../assets/images/al-filled-sixteen.svg'
import iconShadowEight from '../../assets/images/al-filled-seventeen.svg'
import iconShadowNine from '../../assets/images/al-filled-eighteen.svg'
import iconShadowTen from '../../assets/images/al-green-one.svg'
import iconShadowEleven from '../../assets/images/al-green-two.svg'
import iconShadowTwelve from '../../assets/images/al-green-three.svg'
import iconShadowThirteen from '../../assets/images/al-green-four.svg'
import iconShadowFourteen from '../../assets/images/al-green-five.svg'
import iconShadowFifteen from '../../assets/images/al-green-six.svg'
import iconShadowSixteen from '../../assets/images/al-green-seven.svg'
import iconShadowSeventeen from '../../assets/images/al-green-eight.svg'
import iconShadowEighteen from '../../assets/images/al-green-nine.svg'
import AhrColorsIcons from '../../components/AhrColorsIcons'
import OzenColorsIcons from '../../components/OzenColorsIcons'
import OzenColorsIconSecond from '../../components/OzenColorsIconSecond'
import MobileAppImage from '../../assets/images/al-mobile-view.webp'
import BrochureImage from '../../assets/images/al-ui-sheet-left.webp'
import BrochureImageSecond from '../../assets/images/al-ui-sheet-right.webp'
import SeoCopywritingList from '../../components/SeoCopywritingList'
import seoCopywritingImage from '../../assets/images/al-seo-copywriting.webp'
import AlBrochure from '../../assets/images/al-brochure.webp'
import ConsultUs from '../../components/ConsultUs'
import CollateralsOne from '../../assets/images/collaterals-one.webp'
import CollateralsTwo from '../../assets/images/collaterals-two.webp'
import CollateralsThree from '../../assets/images/collaterals-three.webp'
import CollateralsFour from '../../assets/images/collaterals-four.webp'
import CollateralsFive from '../../assets/images/collaterals-five.webp'
import CollateralsSix from '../../assets/images/collaterals-six.webp'

function AtmosphereFolio() {

    const folioHeading = 'The Atmosphere Living'
    const createdDate = 'June, 2024'
    const subHeading = 'Hospitality'
    const foliobannerpara = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries."
    const atmospherelivingBackground = "atmosphere-living-background"
    
    const badgedata = [
        {TabText: 'Website Design'}, {TabText: 'Website Communication'}, {TabText: 'SEO Copywriting'}, {TabText: 'UI-UX Design'}, {TabText: 'Backend Development'}, {TabText: 'Unified - CMS'}
    ]

    const logodata = [
        {ozenLogo: ozenLogoOne, atmosphereLivingLogo: 'atmosphere-living-logo'}
    ]

    const colordata = [
        {AhrColorCode: '#2D5E59', AhrColorName: 'Dark Slate Grey'},
        {AhrColorCode: '#FFFBF4', AhrColorName: 'Floral White'},
        {AhrColorCode: '#D2AE5F', AhrColorName: 'Satin Sheen Gold'},
        {AhrColorCode: '#E7C16D', AhrColorName: 'Gold (Metallic)'},
        {AhrColorCode: '#606060', AhrColorName: 'Dim Grey'},
        {AhrColorCode: '#333333', AhrColorName: 'Jet'}
    ] 

    const tabArray = [
        {
          coverId: 0, name: 'Home Page', imageUrl: imageOne, imageFrameUrl: frameImage, ahrtabContent: 'atmosphere-living-tab-content',
        }, 
        {
          coverId: 1, name: 'Inner Page 1', imageUrl: imageTwo, imageFrameUrl: frameImage, ahrtabContent: 'atmosphere-living-tab-content',
        },
        {
          coverId: 2, name: 'Inner page 2', imageUrl: imageThree, imageFrameUrl: frameImage, ahrtabContent: 'atmosphere-living-tab-content',
        }
    ];

    const atmospherePara = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries."

    const ahriconcolorsdata = [
        {iconOne : iconDarkOne, iconTwo : iconDarkTwo, iconThree : iconDarkThree, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconDarkFour, iconTwo : iconDarkFive, iconThree : iconDarkSix, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconDarkSeven, iconTwo : iconDarkEight, iconThree : iconDarkNine, ahrInnerIcon : 'ahr-inner-icon'}
    ]

    const iconcolorsdata = [
        {iconOne : iconShadowOne, iconTwo : iconShadowFour, iconThree : iconShadowSeven, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconShadowTwo, iconTwo : iconShadowFive, iconThree : iconShadowEight, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconShadowThree, iconTwo : iconShadowSix, iconThree : iconShadowNine, ahrInnerIcon : 'ahr-inner-icon'}
    ]

    const iconcolorsdatasecond = [
        {iconOne : iconShadowTen, iconTwo : iconShadowThirteen, iconThree : iconShadowSixteen, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconShadowEleven, iconTwo : iconShadowFourteen, iconThree : iconShadowSeventeen, ahrInnerIcon : 'ahr-inner-icon'}, 
        {iconOne : iconShadowTwelve, iconTwo : iconShadowFifteen, iconThree : iconShadowEighteen, ahrInnerIcon : 'ahr-inner-icon'}
    ]

    const seocopywritingdata = [
        {serialNumber: '1.', copywritingTitle:'Engaging Copy', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'},
        {serialNumber: '2.', copywritingTitle:'Tone of Voice', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'},
        {serialNumber: '3.', copywritingTitle:'SEO Keywords', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'},
        {serialNumber: '4.', copywritingTitle:'Brand Story', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'}
    ]

    const copywritingPara = "Crafting the website copy for Atmosphere Hotel & Resort transcends traditional SEO strategies, delving deep into the realm of evocative storytelling and finely curated experiences. This deliberate approach is tailored to elevate brand communication, captivating visitors with enchanting narratives that immerse them in unforgettable journeys while ensuring a seamless and extraordinary user experience."


    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Let's get started!", consultPara: "Whether you're optimising operations, expanding reach, or enhancing customer satisfaction, our solutions cover software, mobile app development, and websites.", consultPortfolio: 'faqs-consult-wrapper', pageLink: '/consult', LinkText: 'get started'}
    ]

    return (
        <>
            <FolioInnerBanner atmosphereLogo={atmosphereLogoImg} folioHeading= {folioHeading} foliobannerpara= {foliobannerpara} badgedata={badgedata}  createdDate={createdDate} subHeading={subHeading} atmospherelivingBackground={atmospherelivingBackground}  />

            <section>
                <div className='about-atmosphere'>
                    <div className='container'>
                        <div className='folio-logo-wrapper'>
                            <ListGroup as='ul'>
                                <FolioLogo logodata={logodata}/>
                            </ListGroup>
                        </div>
                        <div className='colo-usage-main'>
                            <div className='color-usage-heading'>
                                <h3 className='heading-font-family'>Color Usage</h3>
                            </div>
                            <div className='color-usage-wrapper d-flex'>
                                <div className='primary-color-usage w-100'>
                                    <ListGroup as='ul' className='ozen-color-usage'>
                                        <AhrColorUsage colordata={colordata} />
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study position-relative'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-website-folio'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Website<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link to='https://theatmosphereliving.com/' target="_blank" className='text-uppercase d-inline-block theme-btn'>Visit Site <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='website-tabs-wrapper'>
                                <FolioWebsiteTabs tabArray={tabArray} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='typography-wrapper'>
                    <div className='container'>
                        <div className='typography-heading'>
                            <h3>Typography</h3>
                        </div>
                        <div className='typography-inner-wrapper atmosphere-living-typography'>
                            <div className='row'>
                                <div className='col-lg-6'>
                                    <div className='typography-family-info'>
                                        <div className='alphabet-font-wrapper d-flex align-items-center'>
                                            <h1 className='mb-0'>Aa</h1>
                                            <span>Dunbar Tall</span>
                                        </div>
                                        <div className='alphabets-wrapper'>
                                            <h2>abcdefghijklmnopqrstuvwxyz</h2>
                                            <h3>abcdefghijklmnopqrstuvwxyz</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-6'>
                                    <div className='typography-family-info typography-family-right'>
                                        <div className='alphabet-font-wrapper d-flex align-items-center'>
                                            <h1 className='mb-0'>Aa</h1>
                                            <span>Public Sans</span>
                                        </div>
                                        <div className='alphabets-wrapper'>
                                            <h2>abcdefghijklmnopqrstuvwxyz</h2>
                                            <h3>abcdefghijklmnopqrstuvwxyz</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='typography-wrapper iconography-wrapper'>
                    <div className='container'>
                        <div className='typography-heading'>
                            <h3>Iconography</h3>
                        </div>
                        <div className='typography-inner-wrapper ahr-icon-typography-wrapper'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='ozen-icon-wrapper'>
                                        <h6>filled  icons</h6>
                                        <div className='d-flex flex-wrap justify-content-between responsive-icon-wrapper'>
                                            <AhrColorsIcons ahriconcolorsdata={ahriconcolorsdata}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='ozen-icon-wrapper'>
                                        <h6>filled  icons</h6>
                                        <div className='d-flex flex-wrap justify-content-between responsive-icon-wrapper'>
                                            <OzenColorsIcons iconcolorsdata={iconcolorsdata}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='ozen-icon-wrapper'>
                                        <h6>green icons</h6>
                                        <div className='d-flex flex-wrap justify-content-between responsive-icon-wrapper'>
                                            <OzenColorsIconSecond iconcolorsdatasecond={iconcolorsdatasecond}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-mobile-folio'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Mobile View<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link  to='https://theatmosphereliving.com/' target="_blank"   className='text-uppercase d-inline-block theme-btn'>View All <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='website-img'>
                                <img src={MobileAppImage} title='' alt='' className='w-100' />
                            </div>
                        </div>
                        <div className='atmosphere-ui-sheet'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>UI Sheet<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='al-ui-sheet-wrapper'>
                                        <img src={BrochureImage} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='al-ui-sheet-wrapper'>
                                        <img src={BrochureImageSecond} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-ui-sheet pt-0'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>SEO Copywriting<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper w-100 d-flex align-item-center justify-content-between'>
                                    <p className='mb-0'>{copywritingPara}</p>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-lg-7 col-md-7'>
                                    <div className='seo-copywriting-wrapper'>
                                        <img src={seoCopywritingImage} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-5'>
                                    <div className='copywriting-list-wrapper'>
                                        <ListGroup as='ul' className='align-items-center justify-content-center'>
                                            <SeoCopywritingList seocopywritingdata={seocopywritingdata}/>
                                        </ListGroup>            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study position-relative'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-website-folio pb-0'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Brochure<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                            </div>
                            <div className='al-brochure-wrapper'>
                                <img src={AlBrochure} alt='' title='' className='w-100' />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study position-relative'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-website-folio pb-0'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Brochure<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                            </div>
                            <div className='collateral-wrapper'>
                                <div className='row'>
                                    <div className='col-lg-4 col-md-6 justify-content-between'>
                                        <div className='al-collaterals-wrapper'>
                                            <img src={CollateralsOne} title='' alt='' className="w-100" />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 justify-content-between'>
                                        <div className='al-collaterals-wrapper'>
                                            <img src={CollateralsTwo} title='' alt='' className="w-100" />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 justify-content-between'>
                                        <div className='al-collaterals-wrapper'>
                                            <img src={CollateralsThree} title='' alt='' className="w-100" />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 justify-content-between'>
                                        <div className='al-collaterals-wrapper'>
                                            <img src={CollateralsFour} title='' alt='' className="w-100" />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 justify-content-between'>
                                        <div className='al-collaterals-wrapper'>
                                            <img src={CollateralsFive} title='' alt='' className="w-100" />
                                        </div>
                                    </div>
                                    <div className='col-lg-4 col-md-6 justify-content-between'>
                                        <div className='al-collaterals-wrapper'>
                                            <img src={CollateralsSix} title='' alt='' className="w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <Link to="/atmosphere-hotels-resorts" className='healthism-btn-wrapper atmosphere-living-wrapper mt-0 w-100 text-center'>
                            <span className='d-block'>next project</span>
                            <h2>Atmosphere Hotels & Resorts</h2>
                        </Link>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} showheading={false} showSmallHeading={false} /> 
        </>
    )
}

export default AtmosphereFolio

import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';

function ProcessAccordion({ processaccordionData }) {
    // Set the initial activeKey to the event key of the first item
    const [activeKey, setActiveKey] = useState(processaccordionData.length > 0 ? processaccordionData[0].planEventKey : null);

    const toggleAccordion = (eventKey) => {
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    return (
        <Accordion activeKey={activeKey} onSelect={toggleAccordion}>
            {processaccordionData.map((item, index) => (
                <Accordion.Item key={index} eventKey={item.planEventKey}>
                    <Accordion.Header>{item.planTabHeading}</Accordion.Header>
                    <Accordion.Body>
                        <div className='accordion-number'>
                            <span>{item.accordionNumber}</span>
                        </div>
                        <p>{item.planTabText}</p>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default ProcessAccordion;

import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SuccessSlider({text, successSetting}) {
    const settings = {...successSetting}
  return (
    <>
      <Slider {...settings}>
        {text.map(item =>(
            <div className='success-slider'>
                <div className='quotes-heading'>
                    <h2 className='fw-bold'>Success Stories<span className='brand-color'>.</span></h2>
                </div>
                <div className='row'>
                    <div className='col-lg-1 col-md-2'>
                        <div className='quotes-wrapper'>
                            <img src={item.QuotesImage} title='quotes' alt='quotes' />
                        </div>
                    </div>

                    <div className='col-lg-11 col-md-10'>
                        <div className='quotes-text'>
                            <p>{item.QutesPara}</p>
                        </div>
                        <div className='user-information mt-5 d-flex align-items-center'>
                            {/* <div className='user-name pe-4'>
                                <img src={item.UserImage} title='User' alt='user' />
                            </div> */}
                            <div className='user-text'>
                                <h4 className='theme-font-family work-heading'>{item.ClientName}</h4>
                                <span>{item.ClientDesignation}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        ))}
      </Slider>
    </>
  )
}

export default SuccessSlider

import React from 'react'
import { Link } from 'react-router-dom'
import { HiMiniArrowLongLeft } from "react-icons/hi2";
import AtmosphereImage from '../assets/images/atmosphere-mockup.webp'

function CasestudyBanner({bannerSmallHeading, bannerLargeHeading, showLargeHeading=true, showBannerImage=true, bannerBackground, smallHeading, solfinImage, casestudyBannerBackground}) {
  return (
    <>
      <section>
            <div className={`digitech-hero-banner atmosphere-banner header-space ${casestudyBannerBackground}`}>
                <div className='container-fluid custom-container-fluid'>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-12'>
                            <div className='casestudy-banner-wrapper'>
                                <div className='atmosophere-banner-text'>
                                    <span className={`atmosphere-small-heading text-uppercase ${smallHeading}`}>{bannerSmallHeading}</span>
                                    {showLargeHeading ? (<h2>{bannerLargeHeading}</h2>):(<h2 className='solfin-heading'>{bannerLargeHeading}</h2>)}
                                </div>
                                <div className='back-home-btn'>
                                    <Link to='/' className='btn p-0 mt-2'> <HiMiniArrowLongLeft /> <span className='ms-1'>Back to Home</span></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-12'>
                            {showBannerImage ? (<div className={`banner-img ${bannerBackground}`}><img src={AtmosphereImage} title='atmosphere' alt='atmosphere' /></div>):(<div className={`banner-img  solfin-bg ${bannerBackground}`}><img src={solfinImage} title='atmosphere' alt='atmosphere'/></div>)}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default CasestudyBanner

import React, { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap-v5';
import { IoIosInformationCircleOutline } from 'react-icons/io';
import PhoneInput from 'react-phone-number-input';

function Payment() {
    const paymentHeading = 'Payment to Digitech';
    
    const paymentPara = "We've made your payments to Digitech faster and convenient with flexible payment methods. Enter the relevant details, choose your payment method and get receipts on your email after payment success.";

    const [value, setValue] = useState();

    const [transactionType, setTransactionType] = useState('');

    const handleRadioChange = (event) => {
        setTransactionType(event.target.value);
    };

    return (
        <>
            <section>
                <div className='payment-wrapper header-space'>
                    <div className='container'>
                        <div className='payment-inner-wrapper'>
                            <h2>
                                {paymentHeading}
                                <span className='brand-color'>.</span>
                            </h2>
                            <p>{paymentPara}</p>
                            <div className='payment-radio-wrapper mt-5 mb-4 d-flex align-item-center justify-content-between'>
                                <div className='payment-tooltip d-flex align-items-center'>
                                    <span>Transaction Type </span>
                                    <div class="tooltip ms-3">
                                        <IoIosInformationCircleOutline />
                                        <span class="tooltiptext">If you're paying from an India based account, choose 'Domestic'; for international transactions choose 'International'.</span>
                                    </div>
                                </div>
                                <div className='payment-radio d-flex'>
                                    <Form.Check
                                        type='radio'
                                        label='Domestic'
                                        name='transactionType'
                                        value='option1'
                                        checked={transactionType === 'option1'}
                                        onChange={handleRadioChange}
                                        className='me-5'
                                    />
                                    <Form.Check
                                        type='radio'
                                        label='International'
                                        name='transactionType'
                                        value='option2'
                                        checked={transactionType === 'option2'}
                                        onChange={handleRadioChange}
                                    />
                                </div>
                            </div>
                            <div className='custom-payment-wrapper'>
                                <Form>
                                    <Form.Group className='mb-3'>
                                        <Form.Control
                                            className='rounded-0 py-3 px-3  custom-input form-control'
                                            type='text'
                                            placeholder='Your name*'
                                        />
                                    </Form.Group>
                                    <Form.Group className='mb-3'>
                                        <Form.Control
                                            className='rounded-0 py-3 px-3  custom-input form-control'
                                            type='email'
                                            placeholder='Your email address*'
                                        />
                                    </Form.Group>
                                    <PhoneInput
                                        international
                                        className='custom-input mb-3'
                                        defaultCountry='IN'
                                        value={value}
                                        onChange={setValue}
                                    />
                                    <Form.Group className='mb-3'>
                                        <Form.Control
                                            className='rounded-0 py-3 px-3  custom-input form-control'
                                            type='text'
                                            placeholder='Invoice Number'
                                        />
                                    </Form.Group>
                                    {transactionType === 'option2' ? (
                                        <div className='payment-select-wrapper mb-3 d-flex align-items-center'>
                                            <Form.Control
                                                className='rounded-0 px-3 py-3  border-0 custom-input form-control border-end-0'
                                                type='text'
                                                placeholder='Amount*'
                                            />
                                            <Form.Select
                                                aria-label='Default select example'
                                                className='rounded-0 px-3 border-0 py-3  payment-custom-select custom-input form-control border-start-0'
                                            >
                                                <option>USD</option>
                                            </Form.Select>
                                        </div>
                                    ) : (
                                        <InputGroup className='mb-3'>
                                            <Form.Control
                                                className='rounded-0 py-3 px-3 w-100 custom-input form-control'
                                                placeholder='Amount*'
                                                aria-label="Recipient's username"
                                                aria-describedby='basic-addon2'
                                            />
                                            <InputGroup.Text
                                                id='basic-addon2'
                                                className='bg-transparent border-0 position-absolute end-0 top-0 bottom-0 m-auto text-uppercase'
                                            >
                                                inr
                                            </InputGroup.Text>
                                        </InputGroup>
                                    )}
                                    <Form.Group controlId='floatingTextarea2'>
                                        <Form.Control
                                            className='rounded-0 py-3 px-3 w-100 custom-input form-control'
                                            as='textarea'
                                            placeholder='Remarks..'
                                            style={{ height: '100px' }}
                                        />
                                    </Form.Group>
                                    <div className='payment-btn-wrapper mt-4'>
                                        <Button className='btn consult-btn text-uppercase border-0'>Pay</Button>
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Payment;

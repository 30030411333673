import React from 'react'

function ServiceCardPage({servicecarddata}) {
    return (
        <>
            {servicecarddata.map(card=> (
                <div className='col-lg-6 col-md-6'>
                    <div className={`card-inner-data ${card.cardSpace}`}>
                        <h4 className='text-uppercase work-heading theme-font-family fw-medium'>{card.cardHeading}</h4>
                        <p>{card.cardPara}</p>
                    </div>
                </div>
            ))}
        </>
    )
}

export default ServiceCardPage

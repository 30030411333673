import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import KeywordList from './KeywordList';
import MarketingstatisticCards from './MarketingstatisticCards';

function MarketingSlider({text, marketingSuccessSetting}) {
    const settings = {...marketingSuccessSetting}
    return (
        <>  
            <Slider {...settings}>
                {text.map(item =>(
                    <div className='marketing-slide-wrapper d-flex align-items-center'>
                        <div className={`marketing-img ${item.ozenMarketingImage} ${item.kanifushiBackground}`}>
                            <img src={item.SliderImage} title='' alt='' />
                        </div>
                        <div className='marekting-content-wrapper'>
                            <span className={`fw-bold marketing-small-title text-uppercase mb-1 d-block ${item.ozenSmallTitle} ${item.kanifushiSmalltitle}`}>{item.smallTitle}</span>
                            <h4 className='fw-semibold marketing-large-title'>{item.largeTitle}</h4>
                            <div className='keywords-ranking-wrapper'>
                                <span className='keyword-title'>{item.keywordHeading}</span>
                                <div className='keywords-list-wrapper'>
                                    <KeywordList keywordlistdata={item.keywordlistdata}/>
                                </div>
                            </div>
                            <div className='statistics-wrapper'>
                                <span className='keyword-title'>{item.statisticsHeading}</span>
                                <div className='keywords-cards-wrapper d-flex'>
                                    <MarketingstatisticCards marketingstatisticsdata={item.marketingstatisticsdata}/>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    )
}

export default MarketingSlider

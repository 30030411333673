import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md';
import AtmosphereLeftImage from '../../assets/images/atmosphere-homepage.webp'
import AtmosphereMobile from '../../assets/images/atmosphere-mobile.webp'
import AtmosphereOffer from '../../assets/images/atmosphere-offer.webp'
import AtmosphereLms from '../../assets/images/atmosphere-lms.webp'
import TechnologyCard from '../../components/TechnologyCard';
import TechnologyImage from '../../assets/images/react.webp'
import TechnologyImage2 from '../../assets/images/node-js.webp'
import TechnologyImage3 from '../../assets/images/postgresql.webp'
import TechnologyImage4 from '../../assets/images/figma.webp'
import TechnologyImage5 from '../../assets/images/html.webp'
import TechnologyImage6 from '../../assets/images/bootstrap-logo.webp'
import FeaturesImage from '../../assets/images/multi-site-management.webp'
import FeaturesImage2 from '../../assets/images/unified-library.webp'
import FeaturesImage3 from '../../assets/images/user-role.webp'
import FeaturesUsedCard from '../../components/FeaturesUsedCard';
import ConsultUs from '../../components/ConsultUs';
import ResultCards from '../../components/ResultCards';
import ResultImage from '../../assets/images/report-up.svg'
import ResultImage2 from '../../assets/images/report-down.svg'
import QuotesImage from '../../assets/images/atmosphere-quotes.svg'
import UserImage from '../../assets/images/user.webp'
import CasestudyBanner from '../../components/CasestudyBanner';
import SuccessSlider from '../../components/SuccessSlider';
import AboutClient from '../../components/AboutClient';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import ChallengeList from '../../components/ChallengeList';
import DeliverablesBadge from '../../components/DeliverablesBadge';
import ExploreAtmosphereTabs from '../../components/ExploreAtmosphereTabs';
import ResizeSlider from '../../components/ResizeSlider';
import imageOne from '../../assets/images/solution-1.webp'
import imageTwo from '../../assets/images/solution-2.webp'
import imageThree from '../../assets/images/solution-3.webp'
import WeWorkOnSlider from '../../components/WeWorkOnSlider';
import SolutionCaseStudyTabs from '../../components/SolutionCaseStudyTabs';

function AtmosphereCaseStudy() {

    const parafour= 'Crafting the website copy for Atmosphere Hotel & Resort transcends traditional SEO strategies, delving deep into the realm of evocative storytelling and finely curated experiences. This deliberate approach is tailored to elevate brand communication, captivating visitors with enchanting narratives that immerse them in unforgettable journeys while ensuring a seamless and extraordinary user experience.'

    const parashowcase='The solution utilizes Reacts virtual DOM for faster updates along with built-in state management. Its component-based architecture enhances mobile responsiveness while a unified backend with image optimization ensures maintainable and scalable front-end code.'

    const parafive='Atmosphere Hotel and Resorts online presence received a modern design overhaul, offering a full facelift for the website. Narrative-driven communication enhanced the brands story ensuring an engaging website experience.'
    
    const technologydata = [
        {TechnologyImage: TechnologyImage, technologyTitle:'React'},
        {TechnologyImage: TechnologyImage2, technologyTitle:'Node'},
        {TechnologyImage: TechnologyImage3, technologyTitle:'Postgres'},
        {TechnologyImage: TechnologyImage4, technologyTitle:'Figma', cardImage:'technology-img'},
        {TechnologyImage: TechnologyImage5, technologyTitle:'HTML 5'},
        {TechnologyImage: TechnologyImage6, technologyTitle:'Bootstrap'}
    ]

    const featuresuseddata = [
        {featuresImage: FeaturesImage, featuresTitle:'Multi-Site Management', featureText:'Ability to manage multiple websites (main site and sub-brand sites).'},
        {featuresImage: FeaturesImage2, featuresTitle:'Unified Content Library', featureText:'Centralized repository for media assets (images, videos, documents).'},
        {featuresImage: FeaturesImage3, featuresTitle:'User Role management', featureText:'Tools to arrange site navigation & menu for each sub-brand site independently.', featurecard:'feature-card'}
    ]

    const resultdata=[
        {resultTitle:'30%', resultImage: ResultImage, resultPara:'Website Time Spent'},
        {resultTitle:'100%', resultImage: ResultImage, resultPara:'Operational Efficiency'},
        {resultTitle:'100%', resultImage: ResultImage2, resultPara:'Data storage'},
        {resultTitle:'40%', resultImage: ResultImage, resultPara:'Website loading'}
    ]

    const resultPara='React uses virtual DOM making updates easier and faster. React also has a built-in state management system which helps you keep track of what is happening on the page. Its component-based architecture allows for creating isolated and reusable UI components, leading to more maintainable and scalable front-end code.'

    const FeaturesPara='With the power of Comments Multi-Site Management, Atmosphere Hotel and Resorts can now effortlessly oversee, update, control, and optimize all their brand and resort websites from a single login.'

    // const successSetting = {
    //     arrow: true,
    //     dots: true,
    //     centerMode: true,
    //     infinite: true,
    //     autoplay: false,
    //     slidesToShow: 1,
    //     centerPadding: '0',
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 991,
    //           settings: {
    //             slidesToShow: 1,
    //             centerPadding: '30px',
    //           },
    //           breakpoint: 767,
    //           settings: {
    //             slidesToShow: 1,
    //             centerPadding: '0',
    //           }
    //         }
    //       ]
    // };

    // const text = [
    //     {QuotesImage: QuotesImage, QutesPara: 'Digitech has done an amazing work on our project Lorem ipsum dolor sit amet consectetur adipiscing elit. Nunc laoreet augue nec leo gravida faucibus. Nullam aliquet erat nisl quis ornare lorem elementum ut. In luctus eros ut massa varius sollicitudin.', UserImage: UserImage, ClientName: "Client's Full Name"},
    //     {QuotesImage: QuotesImage, QutesPara: 'Digitech has done an amazing work on our project Lorem ipsum dolor sit amet consectetur adipiscing elit. Nunc laoreet augue nec leo gravida faucibus. Nullam aliquet erat nisl quis ornare lorem elementum ut. In luctus eros ut massa varius sollicitudin.', UserImage: UserImage, ClientName: "Client's Full Name"},
    //     {QuotesImage: QuotesImage, QutesPara: 'Digitech has done an amazing work on our project Lorem ipsum dolor sit amet consectetur adipiscing elit. Nunc laoreet augue nec leo gravida faucibus. Nullam aliquet erat nisl quis ornare lorem elementum ut. In luctus eros ut massa varius sollicitudin.', UserImage: UserImage, ClientName: "Client's Full Name"}
    // ]

    const aboutdata = [
        {AboutHeading: 'About the client', SecondSmallHeading: 'Hospitality', SecondLargeHeading: 'Atmosphere Hotels & Resorts', AboutPara: 'Atmosphere Hotels & Resorts is emerging as a dynamic and rapidly expanding hospitality enterprise, presently overseeing eight opulent resorts nestled within the enchanting Maldives archipelago. With ambitious plans for further expansion across India, Sri Lanka, Nepal, and Bhutan, the brand embodies a spirit of growth and innovation.', ChallengesHeading: 'Challenges', AboutParaTwo: "Each Hotel & Resort location boasts a unique narrative, pulsating with its own distinct energy. This vitality emanates from the vibrant interplay between the people, the land, and the surrounding natural splendor."}
    ]

    const bannerSmallHeading = 'Success stories'
    const bannerLargeHeading = 'Transforming Website Management for Atmosphere Hotel and Resorts'

    const listdata = [
        {SerialNumber: '01.', ListParaOne: 'Separate website CMS for each website hindered operational efficiency and could lead to challenges in maintaining consistent branding and content standards across different online properties.'},

        {SerialNumber: '02.', ListParaOne: 'Duplication of data & effort in managing content across websites often led to inefficiencies, inconsistencies, increased chances of errors, and susceptibility to malware due to manual synchronization.' },
         
        {SerialNumber: '03.', ListParaOne: 'Outdated website design, coupled with subpar website copy, exacerbated engagement and latency issues. Slow website loading impacted user experience, as traditional CMS struggled with content delivery optimization.'}
    ]

    const ChallengesHeading = 'Challenges'

    const badgedata = [
        {TabText: 'Website Design'}, {TabText: 'Website Communication'}, {TabText: 'SEO Copywriting'}, {TabText: 'UI-UX Design'}, {TabText: 'Backend Development'}, {TabText: 'Unified - CMS'}
    ]
    const tabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Unified CMS', serialNumber:"01.", para:"Unified CMS streamlines website management by enabling control over multiple sites with one login, simplifying tasks like content updates, user management, and media handling, while ensuring consistency and control.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Make deals and offers', serialNumber:"02.", para:"Utilize custom design tools to craft enticing offers, showcase Booking, Spa, and Dining deals on the homepage with clear start and end dates, and seamlessly share them on social media for enhanced visibility and engagement.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Manage Villas and Suites', serialNumber:"03.", para:"Listing & Categorizing tools streamline the process for Hotels & Resorts to list rooms, suites, and villas swiftly and intuitively. They enable quick categorization and listings while effortlessly incorporating signature features.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

   console.log("tab  ",tabArray)

   const responsivetabArray = [
        {
            coverId:0, villaNameTabImage:"", name:'Unified CMS', serialNumber:"01.", para:"Unified CMS streamlines website management by enabling control over multiple sites with one login, simplifying tasks like content updates, user management, and media handling, while ensuring consistency and control.", imageUrl:imageOne, ImageShadow:'atmosphere-image-wrapper'
        }, 
        {
            coverId:1, villaNameTabImage:"", name:'Make deals and offers', serialNumber:"02.", para:"Utilize custom design tools to craft enticing offers, showcase Booking, Spa, and Dining deals on the homepage with clear start and end dates, and seamlessly share them on social media for enhanced visibility and engagement.", imageUrl:imageTwo, ImageShadow:'atmosphere-image-wrapper'
        },
        {
            coverId:2, villaNameTabImage:"", name:'Manage Villas and Suites', serialNumber:"03.", para:"Listing & Categorizing tools streamline the process for Hotels & Resorts to list rooms, suites, and villas swiftly and intuitively. They enable quick categorization and listings while effortlessly incorporating signature features.", imageUrl:imageThree, ImageShadow:'atmosphere-image-wrapper'
        }
    ]

    console.log("tab  ",responsivetabArray)

   const consultdata = [
        {smallHeading: 'Elevating luxury hospitality', largeHeading: "Transform Your Hospitality Brand with Cutting-Edge Digital Solutions", consultPara: "Unlock the full potential of your online presence. Schedule a consultation with our expert team to see how our custom website and multi-site content management solutions can drive your success.", consultPortfolio: 'bottom-space', pageLink: '/consult', LinkText: 'get started'}
    ]

    const weworkonSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 576,
                settings: {
                slidesToShow: 2,
                }
            },

            {
                breakpoint: 380,
                settings: {
                slidesToShow: 1,
                }
            }
        ]
    };

    const images = [
        {softwareImage: TechnologyImage, workText:'React'},
        {softwareImage: TechnologyImage2, workText:'Node Js'},
        {softwareImage: TechnologyImage3, workText:'Postgres'},
        {softwareImage: TechnologyImage4, workText:'Figma'},
        {softwareImage: TechnologyImage5, workText:'HTML 5'},
        {softwareImage: TechnologyImage6, workText:'Bootstrap'},
    ]

  return (
    <>
        <CasestudyBanner bannerSmallHeading={bannerSmallHeading} bannerLargeHeading={bannerLargeHeading} />

        
        <AboutClient aboutdata={aboutdata} />

        <section>
            <div className='about-atmosphere atmosphere-case-study'>
                <div className='container'>
                    <div className='atmosphere-heading'>
                        <h2>{ChallengesHeading}<span className="brand-color">.</span></h2>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='about-list-wrapper'>
                                    <ChallengeList listdata={listdata} />
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='right-content'>
                                    <h3 className='about-right-heading fw-semibold'>Deliverables</h3>
                                    <div className='atmosphere-tags'>
                                        <DeliverablesBadge badgedata={badgedata} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* challenges */}
        
        <section>
            <div className='digitech-solution'>
                <div className='container-fluid custom-container-fluid'>
                    <div className='row'>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            <div className='atmosphere-heading'>
                                <h2 className='mb-0'>Solution<span className='brand-color'>.</span></h2>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-6'>
                            {/* <div className='atmosphere-link text-end'>
                            <Link className='text-uppercase mt-4 d-inline-block theme-btn' to=''>View More <MdArrowOutward /></Link>
                            </div> */}
                        </div>
                    </div>
                    <div className='digitechtabs casestudy-desktop-tabs d-flex justify-content-between'>
                        <ExploreAtmosphereTabs tabArray={tabArray}/>
                    </div>
                    <div className='responsive-digitech-tab'>
                        <SolutionCaseStudyTabs responsivetabArray={responsivetabArray} />
                    </div>
                </div>
            </div>
        </section>
        {/* soluitons */}

        <section>
            <div className='about-atmosphere atmosphere-case-study'>
                <div className='container'>
                    <div className='atmosphere-heading atmosphere-space mt-0'>
                        <div className='row'>
                            <div className='col-lg-7'>
                                <div className='copywritting-para'>
                                    <h2>SEO Copywriting<span className='brand-color'>.</span></h2>
                                    <p>{parse(parafour)}</p>
                                </div>
                            </div>
                            <div className='col-lg-5'>
                                <div className='right-content copywriting-tabs'>
                                    
                                    <div className='atmosphere-tags'>
                                        <Stack direction="vertical" className='deliverables-tabs align-items-baseline mt-4' gap={3}>
                                            <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>Engaging Copy</Badge>
                                            <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>Tone of Voice</Badge>
                                            <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>SEO Keywords</Badge>
                                            <Badge pill className='bg-transparent border text-dark fw-normal w-100 px-5 fs-4 py-3'>Brand Story</Badge>
                                        </Stack>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* SEO Copyright */}

        <section>
            <div className='about-atmosphere atmosphere-case-study'>
                <div className='container-fluid custom-container-fluid'>
                    <div className='row mb-lg-0 mb-md-0 mb-sm-3'>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='atmosphere-heading'>
                                <h2>Showcase<span className='brand-color'>.</span></h2>
                            </div>
                        </div>
                        <div className='col-lg-6 col-md-6 col-12'>
                            <div className='atmosphere-link text-end'>
                                <Link className='text-uppercase mt-4 d-inline-block theme-btn' to='https://atmosphere-kanifushi.com/' target='_blank'>Visit Site <MdArrowOutward /></Link>
                            </div>
                        </div>
                        <div className='col-lg-11'>
                            <div className='showcase-para'>
                                <p>{parse(parashowcase)}</p>
                            </div>
                        </div>
                    </div>
                    <div className='atmosphere-design-wrapper'>
                        <div className='atmosphere-left-pannel'>
                            <div className='left-design atmosphere-design'>
                                <h6 className='heading-font'>Website  design</h6>
                                <img src={AtmosphereLeftImage} title='home' alt='home' className='w-100' />
                            </div>
                        </div>
                        
                        <div className='atmosphere-right-pannel'>
                            <div className='right-design d-flex align-items-center justify-content-between'>
                                <div className='atmosphere-mobile  mobile-sapce me-5'>
                                    <h6 className='heading-font'>Mobile  design</h6>
                                    <img src={AtmosphereMobile} title='mobile' alt='mobile' className='w-100 m-auto' />
                                </div>
                                <div className='atmosphere-mobile atmosphere-design mt-lg-0 mt-md-0 mt-sm-4'>
                                    <h6 className='heading-font'>Offers  design</h6>
                                    <img src={AtmosphereOffer} title='offer' alt='offer' className='w-100' />
                                </div>
                            </div>
                            <div className='right-design mt-5'>
                                <div className='atmosphere-mobile atmosphere-design'>
                                    <h6 className='heading-font'>Lead Management</h6>
                                    <img src={AtmosphereLms} title='lms' alt='lms' className='w-100'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* showcase */}

        <section>
            <div className='container'>
                <div className='atmosphere-heading  atmosphere-case-study'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='copywritting-para'>
                                <h2 className='fw-bold'>Modern Design<span className='brand-color'>.</span></h2>
                                <p>{parse(parafive)}</p>
                            </div>
                        </div>
                    </div>
                    <div className='atmosphere-resizable-pannel'>
                        <div className='diffrence-text d-flex align-items-center justify-content-between'>
                            <h6 className='fw-semibold'>Before</h6>
                            <h6 className='fw-semibold'>After</h6>
                        </div>
                        <ResizeSlider />
                    </div>
                </div>
            </div>
        </section>
        {/* morden design */}

        <section>
            <div className='about-atmosphere atmosphere-case-study'>
                <div className='container'>
                    <div className='service-features'>
                        <h2 className='fw-bold'>Technologies used<span className='brand-color'>.</span></h2>
                    </div>
                    <div className='copywritting-para'>
                        <div className='row technologies-card-wrapper'>
                            <TechnologyCard technologydata={technologydata}/>
                        </div>
                        <div className='testimonal-slider technology-slider-wrapper'>
                            <WeWorkOnSlider weworkonSetting={weworkonSetting} images={images} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Technologies used */}

        <section>
            <div className='about-atmosphere   atmosphere-case-study'>
                <div className='container-fluid custom-container-fluid'>
                    <div className='copywritting-para'>
                        <div className='row align-items-center'>
                            <div className='col-lg-8'>
                                <div className='unified-heading'>
                                    <h2 className='fw-bold'> Powered by COMET's unified CMS.<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            <div className='col-lg-4'>
                                <div className='unified-btn text-end'>
                                    <Link className='text-uppercase mt-4 d-inline-block theme-btn' to='/comet'>Know more <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <div className='features-paragraph mt-5'>
                                    <p>{parse(FeaturesPara)}</p>
                                </div>
                            </div>
                        </div>
                        <div className='row mt-5'>
                            <FeaturesUsedCard featuresuseddata={featuresuseddata}/>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Unified CMS features */}

        <ConsultUs consultdata={consultdata} showSmallHeading={false} />

        
        <section>
            <div className='about-atmosphere atmosphere-case-study casestudy-result-space'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-6'> 
                            <div className='result-wrapper'>
                                <h2 className='fw-bold'>End Result<span className='brand-color'>.</span></h2>
                                <div className='result-suv-heading'>
                                    <h3>We make an impact through our work.</h3>
                                </div>
                                <div className='result-btn-wrapper'>
                                    <Link to='/consult' className='btn consult-btn result-btn'>Consult Us <MdArrowOutward /></Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='result-right-wrapper'>
                                <p>{parse(resultPara)}</p>
                                <div className='result-inner'>
                                    <div className='row'>
                                        <ResultCards resultdata={resultdata}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* End Result */}
        
        {/* <section>
            <div className='about-atmosphere  pt-0'>
                <div className='container'>
                    <SuccessSlider successSetting={successSetting} text={text} />
                </div>
            </div>
        </section> */}
        {/* End Result */}
        
    </>
  )
}

export default AtmosphereCaseStudy

import React from 'react'

function TermsOfUse() {
    return (
        <>
            <section>
                <div className='privacy-policy-wrapper header-space'>
                    <div className='container'>
                        <div className='privacy-policy-heading'>
                            <h2 className='fw-bold'>Terms of use<span className='brand-color'>.</span></h2>
                        </div>

                        <div className='privacy-policy-text'>
                            <p>Here are the following terms of a legal agreement between you and Digitech Software Solutions. Here, we clearly define the aspects of our business relationship with you.</p>

                            <div className='term-use-list-wrapper'>
                                <ul>
                                    <li>
                                        <p>All site content (text and multimedia) will be the sole responsibility of the client to provide to Digitech Software Solutions. Such should be provided prior to commencing the work.</p>
                                    </li>
                                    <li>
                                        <p>The client is solely responsible to take a proper back-up of all content on their site prior to letting Digitech Software Solutions undertake the required course of action towards meeting the contract. Any loss or damage to existing data shall not be a responsibility of Digitech Software Solutions under any circumstances.
                                        </p>
                                    </li>
                                    <li>
                                        <p>The Contract does not hold Digitech Software Solutions responsible for any data entry, web hosting or custom artwork/graphics related work/tasks unless otherwise specifically mentioned, paid for and agreed to by both the parties towards such. Any artwork, images, or text supplied and/or designed by Digitech Software Solutions on behalf of the client, will remain the property of Digitech Software Solutions and/or its suppliers unless otherwise agreed.
                                        </p>
                                    </li>
                                    <li>
                                        <p>While Digitech Software Solutions will do its best to achieve all deliveries within the estimated time, there may, at times, be the need to extend or adjust time in cases of any unavoidable and non-forecasted situations like those of deployment issues, dependencies, 3rd-Party support, development bottlenecks, resource unavailability due to emergency, communication delays and the like.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Digitech Software Solutions will provide the Client an opportunity to review the appearance and content of the Website during the design and once they are completed. Digitech Software Solutions shall wait for a period of 7 days to hear any feedback on such shared work/outputs from the client. In the event of client not reply within this period, such material will be deemed to have been automatically accepted and approved by the Client.
                                        </p>
                                    </li>
                                    <li>
                                        <p>The Client retains the copyright to data, files and graphic logos provided by the Client and grant Digitech Software Solutions the rights to publish and use such material. The Client must obtain permission and rights to use any information or files that are copyrighted by a third party. The Client is further responsible for granting Digitech Software Solutions permission and rights for use of the same and agrees to indemnify and hold harmless Digitech Software Solutions from any and all claims resulting from the Client’s negligence or inability to obtain proper copyright permissions. A contract for Web site design and/or placement shall be regarded as a guarantee by the Client to Digitech Software Solutions that all such permissions and authorities have been obtained. Evidence of permissions and authorities may be requested.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Digitech Software Solutions will not accept responsibility for any alterations caused by the Client or a third party occurring to the Client’s pages/website once installed/deployed. Such alterations include, but are not limited to additions, modifications, or deletions. Digitech Software Solutions may require a one-off Web Development charge before resolving any issues that may arise.
                                        </p>
                                    </li>
                                    <li>
                                        <p>A cancellation fee may be charged if the Customer cancels the Service prior to completion. The fee will be equal to the amount of work completed at the point of cancellation.
                                        </p>
                                    </li>
                                    <li>
                                        <p>A non-payment of cancellation fee and/or overdue amount will result in the legal action upon necessity.
                                        </p>
                                    </li>
                                </ul>

                                <h4 className='mt-4 mb-4'>Support and 3rd-Party</h4>
                                <ul>
                                    <li>
                                        <p>As the site launches, we offer free support for the first month. After one month of free service, we charge according to our various price packages best-suited to client’s requirement. We also provide attractive discounts if the client chooses a higher price package. The scope of support only includes any bug fixing and email support and excludes any issues related to the site architecture, rule changes, and add-ons/enhancements.</p>
                                    </li>
                                    <li>
                                        <p>No guarantees or warranties shall be provided by Digitech Software Solutions for the accuracy or performance of such 3rd-Party product/service.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Any upgrade in the 3rd-Party product/service being used in the project shall not be part of scope at Digitech Software Solutions. Such shall be addressed per feasibility and revision of price and time may be called for by Digitech Software Solutions.
                                        </p>
                                    </li>
                                </ul>

                                <h4 className='mt-4 mb-4'>Re-work, Enhancements/Add-ons and Billing</h4>
                                <ul>
                                    <li>
                                        <p>Any additional features not envisaged in the scope of work would be entertained through a Change Management process and be additionally billed. Scope Creeps after wireframe sign off would be billed as additional and timelines and cost for delivery would increase.</p>
                                    </li>
                                    <li>
                                        <p>Whilst we try our best to cover most changes within the budget of the site, some changes are classified as enhancements/add-ons to the system and become chargeable; we will advise you before we start work of any such items.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Any re-work, change, or tweak request by the client post-approval and/or further to the subsequent stage of the project process might be treated as add-on work and be additionally billed.
                                        </p>
                                    </li>
                                    <li>
                                        <p>The client must pay the fee charged by Digitech Software Solutions without any deductions, discounts or debt settlement by the agreed due dates.
                                        </p>
                                    </li>
                                </ul>

                                <h4 className='mt-4 mb-4'>Limitations of Liability</h4>
                                <ul>
                                    <li>
                                        <p>Digitech Software Solutions will use reasonable skill and care in providing the Service. However, we make no representation and exclude any warranty, express or implied, as to the availability, quality, accuracy, timeliness, completeness, performance or fitness of the Service.</p>
                                    </li>
                                    <li>
                                        <p>Digitech Software Solutions hereby excludes itself, its Employees, and/or Agents from: all and any liability for loss or damage caused by any inaccuracy; omission; delay or error, whether the result of negligence or other cause in the production of the website; All and any liability for loss or damage to clients’ artwork/photos, data/content supplied for the site. This is whether the loss or damage results from negligence or otherwise.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Except in the event of death or personal injury caused by our negligent acts or omissions, we shall not be liable in any way for any damages arising in contract, tort or otherwise in respect of loss or damage arising out of or in connection with this Agreement or operation of the Service. In no event will we be liable for any direct, indirect or consequential damages in contract or tort, including loss of profit, loss or damage to property or relating to claims made by third parties.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Digitech Software Solutions cannot make guarantees of service on behalf of third party organizations and will not be held liable for the failure in any service provided by third parties.
                                        </p>
                                    </li>
                                </ul>

                                <h4 className='mt-4 mb-4'>Approvals and Delivery</h4>
                                <ul>
                                    <li>
                                        <p>The project will involve various stages and the work for the next stage will only start after receiving the sign off on and relevant payments for the previous stage as agreed.</p>
                                    </li>
                                    <li>
                                        <p>All code and material developed will be transferred post completion of the project and after sign-offs. The code ownership will reside with the client after final payments.
                                        </p>
                                    </li>
                                    <li>
                                        <p>Digitech Software Solutions holds the Right to publish and use the completed work and/or even the deployed final produce/website for references to other potential clients. In circumstances such is required to be withheld, the client shall notify Digitech Software Solutions well in advance and request prior and proper approvals towards the same.
                                        </p>
                                    </li>
                                </ul>

                                <h4 className='mt-4 mb-4'>Severability</h4>
                                <ul>
                                    <li>
                                        <p>In the event any one or more of the provisions of this Agreement and/or Order shall be held to be invalid, illegal or unenforceable, the remaining provisions of this Agreement and/or Order shall be unimpaired and the Agreement and/or Order shall not be void for this reason alone. Such invalid, illegal or unenforceable provision shall be replaced by a mutually acceptable valid, legal and enforceable provision, which comes closest to the intention of the parties underlying the invalid, illegal, or unenforceable provision.</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default TermsOfUse

import React from 'react'
import parse from 'html-react-parser'
import { MdArrowOutward } from 'react-icons/md'
import BannerImage from '../../assets/images/software-dev-banner.webp'
import ServiceBanner from '../../components/ServiceBanner'
import { Link } from 'react-router-dom'
import ServiceCardPage from '../../components/ServiceCardPage'
import GetDoneCards from '../../components/GetDoneCards'
import FaqsAccordion from '../../components/FaqsAccordion'
import ConsultUs from '../../components/ConsultUs'
import Accordion from 'react-bootstrap/Accordion';
import StatisticImage  from '../../assets/images/report-up.svg'
import mobFeatureImg from '../../assets/images/mob-1.webp'
import MobFeatures from '../../components/MobFeatures'
import mobIconOne from '../../assets/images/robust-architecture.svg'
import mobIconTwo from '../../assets/images/streamlined-operations.svg'
import mobIconThree from '../../assets/images/comprehensive-support.svg'
import MobFeaturesSecond from '../../components/MobFeaturesSecond'
import mobIconFour from '../../assets/images/flexible-frameworks.svg'
import mobIconFive from '../../assets/images/integranted-solutions.svg'
import mobIconSix from '../../assets/images/ai-driven-analytics.svg'
import EcommerceShowcaseSlider from '../../components/EcommerceShowcaseSlider'
import offerImgOne from '../../assets/images/software-showcase-1.webp'
import offerImgTwo from '../../assets/images/software-showcase-2.webp'
import offerImgThree from '../../assets/images/software-showcase-3.webp'
import offerImgFour from '../../assets/images/software-showcase-4.webp'
import offerImgFive from '../../assets/images/software-showcase-5.webp'
import ProcessAccordion from '../../components/ProcessAccordion'
import SoftwareTechnologiesCard from '../../components/SoftwareTechnologiesCard'
import technologiesImageOne from '../../assets/images/react.webp'
import technologiesImageTwo from '../../assets/images/node-js.webp'
import technologiesImageThree from '../../assets/images/php-logo.webp'
import technologiesImageFour from '../../assets/images/mongodb.webp'
import technologiesImageFive from '../../assets/images/postgresql.webp'
import technologiesImageSix from '../../assets/images/react.webp'
import SoftwareTechnologiesCardSecond from '../../components/SoftwareTechnologiesCardSecond'
import StatisticsCards from '../../components/StatisticsCards'
import StatisticImageLarge from '../../assets/images/software-statistics-img.webp'
import StatisticImageDown from '../../assets/images/report-down.svg'
import SoftwareTechnologiesSlider from '../../components/SoftwareTechnologiesSlider'
import responsiveOne from '../../assets/images/software-mob-1.webp'
import responsiveTwo from '../../assets/images/software-mob-5.webp'
import responsiveThree from '../../assets/images/software-mob-4.webp'
import responsiveFour from '../../assets/images/software-mob-3.webp'
import responsiveFive from '../../assets/images/software-mob-2.webp'



function SoftwareDevelopment() {
    const servicedata= [
        {bannerSmallHeading:'software development', bannerHeading:"Unlock innovation with our cutting-edge software development", bannerImage: BannerImage, cometMainBanner: 'mob-main-banner', smallHeadingColor: 'mob-heaidng-color', webBannerHeading:'ecommerce-heading'}
    ]

    const paraaboutservice= "<span className=\"letter-size\">W</span>elcome to the forefront of digital innovation! At Digitech, we don't just develop software; we engineer transformative digital solutions tailored to propel your business into the digital age. With our software development service, we're not just writing code; we're architecting your digital future with scalable, cutting-edge technology that adapts and evolves alongside your business needs."

    // const paraaboutserviceOne= 'Lorem Ipsum has been the industrys standard dummy text. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text.'

    const servicecarddata= [
        {cardHeading: 'Agile Methodology', cardPara: 'Seamlessly integrate functionality and elegance using agile principles.'}, 
        {cardHeading: 'Tailored Solutions', cardPara: 'Customized software aligns perfectly with your objectives, following sprint-based development and scrum.'}, 
        {cardHeading: 'Development Approach', cardPara: 'Crafting software solutions by closely incorporating client inputs and user feedback.'}, 
        {cardHeading: 'User-Centric Design', cardPara: 'Prioritize user satisfaction with intuitive interfaces and seamless interactions.'}
    ]

    const getdonedata = [
        {getDoneTitle:'Product Development', getDoneImage: StatisticImage, getDonePara: 'Building software/SaaS products from conception to launch.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'ERP Systems', getDoneImage: StatisticImage, getDonePara: 'Mange core business processes like accounting, HR, inventory management, and CRM.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Accounting Software', getDoneImage: StatisticImage, getDonePara: 'Manage financial transactions, invoicing, reporting, and GST compliance.', getDonecard: 'comet-get-done-wrapper'},
        {getDoneTitle:'Management Systems', getDoneImage: StatisticImage, getDonePara: 'Streamline administrative tasks and processes across various business verticals.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'Content Management', getDoneImage: StatisticImage, getDonePara: 'Facilitate easy creation, editing, & publishing of web content.', getDonecard: 'comet-get-done-wrapper'}, 
        {getDoneTitle:'E-commerce Development', getDoneImage: StatisticImage, getDonePara: 'Enable online sales with catalogs, carts, payments, and deliveries.', getDonecard: 'comet-get-done-wrapper'}
    ]

    const accordionData = [
        {
            planEventKey:0, planTabHeading: 'How do your software development solutions optimize operations, automate tasks, and enhance productivity?', planTabText: "We meticulously analyze your business processes to identify opportunities for improvement. By implementing streamlined solutions such as task automation and workflow enhancements, we ensure smoother operations and productivity. Additionally, our in-house expertise in software architecture and development ensures scalability, reliability, and performance."
        }, 
        {
            planEventKey:1, planTabHeading: 'How do you ensure that software aligns with my business?', planTabText: "We prioritize user-centric design principles and conduct rigorous usability testing to craft intuitive interfaces and seamless interactions. By focusing on user needs and preferences, we create software solutions that deliver unmatched user experiences tailored specifically to your business requirements."
        },
        {
            planEventKey:2, planTabHeading: 'What technologies do you specialize in for software development?', planTabText: "Our expertise spans a wide array of technologies, including React, Node.js, PHP, .NET, and SQL. Our team remains updated on the latest technological advancements to provide cutting-edge solutions tailored to meet your business objectives."
        },
        {
            planEventKey:3, planTabHeading: 'How do you ensure the security of software solutions?', planTabText: "Security is paramount in all our software development endeavors. We adhere to industry best practices and implement stringent security protocols to safeguard your data and shield against cyber threats, ensuring the confidentiality, integrity, and availability of your information."
        }
    ]

    const defaultActiveKey = accordionData.length > 0 ? accordionData[0].planEventKey : null;

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Empower your vision by partnering with us for software development.", consultPara: "Are you ready to bring your software vision to life? Let's collaborate and turn your ideas into reality. Our team is here to provide expert guidance and support every step of the way.", consultPortfolio: 'faqs-consult-wrapper', cometConsultBtn: 'mob-consult-btn', pageLink: '/consult', LinkText: 'get started'}
    ]

    const mobfeaturedata = [
        {mobHeading: 'Robust Architecture', mobPara: 'Ensuring stability, scalability, and security.', mobicon: mobIconOne}, 
        {mobHeading: 'Streamlined Operations', mobPara: ' Enhancing efficiency with automated workflows.', mobicon: mobIconTwo, mobIconColorChange: 'mob-feature-bg'}, 
        {mobHeading: 'Comprehensive Support', mobPara: 'Receive ongoing assistance for optimal performance.', mobicon: mobIconThree, mobcardspace: 'mob-feature-space'}
    ]

    const mobfeaturedatasecond = [
        {mobHeading: 'Flexible Frameworks', mobPara: 'Easily customize and adapt your software.', mobicon: mobIconFour, mobIconColorChange: 'mob-feature-bg'}, 
        {mobHeading: 'Integrated Solutions', mobPara: 'Seamlessly integrate with existing systems.', mobicon: mobIconFive}, 
        {mobHeading: 'AI-driven Analytics', mobPara: ' Utilize advanced analytics and AI for data-driven decisions.', mobicon: mobIconSix, mobIconColorChange: 'mob-feature-bg', mobcardspace: 'mob-feature-space'}
    ]

    const ecommerceSettings = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
            {
              breakpoint: 1199,
              settings: {
                slidesToShow: 1,
              }
            },{
                breakpoint: 767,
                settings: {
                    arrow: false,
                }
              }
          ]
    };

    const ecommarcedata = [
        {ecommarceimageUrl:offerImgOne, ecommerceSubTitle: 'Fintech', ecommerceTitle:'Payment Gateway Services', MobResponsiveImage: responsiveOne},
        {ecommarceimageUrl:offerImgTwo, ecommerceSubTitle: 'Green Finance', ecommerceTitle:'Solfin', MobResponsiveImage: responsiveTwo},
        {ecommarceimageUrl:offerImgThree, ecommerceSubTitle: 'Manufacturing', ecommerceTitle:'Bizzpulse', MobResponsiveImage: responsiveThree},
        {ecommarceimageUrl:offerImgFour, ecommerceSubTitle: 'Telecom', ecommerceTitle:'Aztel', MobResponsiveImage: responsiveFour},
        {ecommarceimageUrl:offerImgFive, ecommerceSubTitle: 'Education', ecommerceTitle:'3ischools', MobResponsiveImage: responsiveFive}
    ]

    const processaccordionData = [
        {
            planEventKey:0, planTabHeading: 'Project Analysis', planTabText: "Thorough analysis of project requirements and objectives to ensure alignment with business goals.", accordionNumber: '01'
        }, 
        {
            planEventKey:1, planTabHeading: 'Prototyping', planTabText: "Creation of prototype models to visualize and validate software functionalities and user experiences.", accordionNumber: '02'
        },
        {
            planEventKey:2, planTabHeading: 'Designing', planTabText: "Development of comprehensive design plans and wireframes for the software interface and user interactions.", accordionNumber: '03'
        },
        {
            planEventKey:3, planTabHeading: 'Database Design', planTabText: "Structuring and designing the database architecture to efficiently store and manage data.", accordionNumber: '04'
        },
        {
            planEventKey:4, planTabHeading: 'Development', planTabText: " Implementation of software features and functionalities using cutting-edge technologies such as React, Node.js, PHP, .NET, and SQL.", accordionNumber: '05'
        },
        {
            planEventKey:5, planTabHeading: 'Deployment', planTabText: "Seamless deployment of the software solution, ensuring smooth transition and minimal disruption to business operations.", accordionNumber: '06'
        }
    ]


    const technologiescarddata = [
        {
            technologiesImage: technologiesImageOne, technologiesTitle: 'React', technologiesPara: "Revolutionizing front-end development with its component-based architecture and efficient rendering.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageFour, technologiesTitle: 'Mongodb', technologiesPara: "NoSQL database, document-oriented, flexible schema, scalable, high performance, widely used in modern applications.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageTwo, technologiesTitle: 'PHP', technologiesPara: "Driving dynamic web content and server-side scripting with its simplicity and versatility.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },
        {
            technologiesImage: technologiesImageFive, technologiesTitle: 'PostgreSQL', technologiesPara: "Open-source relational database management system. Known for robustness, extensibility, and support for advanced features like JSON.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageThree, technologiesTitle: 'Node JS', technologiesPara: " Powering server-side applications with its lightweight, event-driven architecture and extensive ecosystem of modules.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageSix, technologiesTitle: 'React Native', technologiesPara: "Robust framework for building cross-platform mobile applications using JavaScript and React.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        }
    ]

 

    const technologiessecondcarddata = [
        {
            technologiesImage: technologiesImageFour, technologiesTitle: 'Mongodb', technologiesPara: "NoSQL database, document-oriented, flexible schema, scalable, high performance, widely used in modern applications.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageFive, technologiesTitle: 'PostgreSQL', technologiesPara: "Open-source relational database management system. Known for robustness, extensibility, and support for advanced features like JSON.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageSix, technologiesTitle: 'React Native', technologiesPara: "Robust framework for building cross-platform mobile applications using JavaScript and React.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        }
    ]


    const softwaredataSetting = {
        arrow: true,
        dots: false,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    const technologiessliderdata = [
        {
            technologiesImage: technologiesImageOne, technologiesTitle: 'React', technologiesPara: "Revolutionizing front-end development with its component-based architecture and efficient rendering.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageTwo, technologiesTitle: 'Node JS', technologiesPara: " Powering server-side applications with its lightweight, event-driven architecture and extensive ecosystem of modules.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageThree, technologiesTitle: 'PHP', technologiesPara: "Driving dynamic web content and server-side scripting with its simplicity and versatility.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },
        {
            technologiesImage: technologiesImageFour, technologiesTitle: 'Mongodb', technologiesPara: "NoSQL database, document-oriented, flexible schema, scalable, high performance, widely used in modern applications.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageFive, technologiesTitle: 'PostgreSQL', technologiesPara: "Open-source relational database management system. Known for robustness, extensibility, and support for advanced features like JSON.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        },

        {
            technologiesImage: technologiesImageSix, technologiesTitle: 'React Native', technologiesPara: "Robust framework for building cross-platform mobile applications using JavaScript and React.", technologiesGetStarted: 'get started', technologiesLink: '/consult'
        }
    ]

    // const testimonialSetting = {
    //     arrow: false,
    //     dots: true,
    //     infinite: true,
    //     autoplay: true,
    //     slidesToShow: 2,
    //     slidesToScroll: 1,
    //     responsive: [
    //         {
    //           breakpoint: 1199,
    //           settings: {
    //             slidesToShow: 1,
    //           }
    //         }
    //     ]
    // };

    // const testimonialdata = [
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'},
    //     {readMore:'Read More', testimonialHeading:'It was a great experience!', testimonialParagraph:'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer.', testimonialName:'- Eliana Austin', smallText:'(Founder)', learnMoreLink: 'learn more', testimonialInnerPage: 'testimonial-inner-page'}
    // ]

    const statisticsdata = [
        {statisticsTitle:' 29.3%', statisticsImage: StatisticImage, statisticsPara: 'Operational Efficiencies', softwareStatistics: 'software-inner-statistics'}, 
        {statisticsTitle:'15.7%', statisticsImage: StatisticImageDown, statisticsPara: 'Business Expenses', statisticscard: 'statistics-wrapper-two', softwareStatistics: 'software-inner-statistics'}, 
        {statisticsTitle:'20%', statisticsImage: StatisticImage, statisticsPara: 'in Avg. Profitability', resultCardTwo: 'statitics-cards-third', softwareStatistics: 'software-inner-statistics'}
    ]

    const statisticHeading= 'Real Results'
    const parastatistic= "Through our custom software development, we've helped numerous clients achieve significant milestones by optimizing operational efficiencies, enhancing user engagement, and driving business growth, resulting in unprecedented success and profitability across industries. "



    return (
        <>
            <ServiceBanner servicedata={servicedata}/>

            <section>
                <div className='about-service-wrapper'>
                    <div className='container'>
                        <div className='service-about-para'>
                            <p>{parse(paraaboutservice)}</p>
                            {/* <p>{parse(paraaboutserviceOne)}</p> */}
                            <div className='service-btn-wrapper'>
                                <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end service about */} 

            <section>
                <div className='about-service-wrapper atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-wrapper service-web-cards d-flex'>
                            <div className='service-left'>
                                <div className='service-clean-heading'>
                                    <h2 className='service-banner-heading fw-bold'>Crafted with Precision & Development Expertise<span className='brand-color'>.</span></h2>
                                    <Link to='/consult' className='btn service-get-started-btn mt-5'>Get Started <MdArrowOutward /></Link> 
                                </div>
                            </div>
                            <div className='service-right'>
                                <div className='Service-cards-innre'>
                                    <div className='row'>
                                        <ServiceCardPage servicecarddata={servicecarddata} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study'>
                    <div className='container'>
                        <div className='feature-responsive-wrapper enterprise-application-wrapper d-flex align-content-center'>
                            <div className='enterprise-application-left'>
                                <div className='service-features'>
                                    <h2 className='service-banner-heading fw-bold'>Features That Empower<span className='brand-color'>.</span></h2>
                                </div>
                                <div className='software-feature-wrapper'>
                                    <div className='software-feature-left'>
                                        <MobFeatures mobfeaturedata={mobfeaturedata} />
                                    </div>
                                    <div className='software-feature-left'>
                                        <MobFeaturesSecond mobfeaturedatasecond={mobfeaturedatasecond} />
                                    </div>
                                </div>
                            </div>
                            <div className='enterprise-application-right'>
                                <div className='mob-feature-img software-feature-img'>
                                    <img src={mobFeatureImg} title='' alt='' className='w-100'/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='our-process-wrapper position-relative atmosphere-case-study'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-lg-5'>
                                <div className='service-features'>
                                    <h2 className='service-banner-heading fw-bold'>Our Process<span className='brand-color'>.</span></h2>
                                </div>
                            </div>
                            <div className='col-lg-7'>
                                <div className='process-accordion'>
                                <Accordion className='process-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                                    <ProcessAccordion processaccordionData={processaccordionData} />
                                </Accordion>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper overflow-hidden atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='features-heading'>
                            <h2 className='mb-0 fw-bold'>Showcase<span className='brand-color'>.</span></h2>
                        </div>  
                        <div className='ecommerce-showcase-slider'>
                            <EcommerceShowcaseSlider ecommerceSettings={ecommerceSettings} ecommarcedata={ecommarcedata} />
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study overflow-hidden'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-12'>
                                <div className='statistics-wrapper-left'>
                                    <h2 className='fw-bold'>{statisticHeading}<span className='brand-color'>.</span></h2>
                                    <p className='statistics-para'>{parse(parastatistic)}</p>
                                    <Link to='/consult' className='btn service-get-started-btn'>Get Started <MdArrowOutward /></Link> 
                                </div>
                            </div>
                            <div className='col-lg-7 col-md-12'>
                                <div className='statistics-right d-flex'>
                                    <div className='statistics-inner-wrapper software-statistics-wrapper'>
                                        <StatisticsCards statisticsdata={statisticsdata} />
                                    </div>
                                    <div className='statistics-img'>
                                        <img src={StatisticImageLarge} alt='' title='' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-service-wrapper  atmosphere-case-study'>
                    <div className='container'>
                        <div className='service-features'>
                            <h2 className='service-banner-heading fw-bold'>Technologies<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='row technologies-card-wrapper'>
                            <SoftwareTechnologiesCard technologiescarddata={technologiescarddata}/>
                        </div>
                        <div className='software-technologies-slider testimonal-slider  technology-slider-wrapper'>
                            <SoftwareTechnologiesSlider softwaredataSetting={softwaredataSetting}  technologiessliderdata={technologiessliderdata} />
                        </div>
                    </div>
                </div>
            </section>

            {/* <section>
                <div className='digitech-services-wrapper pt-0'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='service-card-heading'>
                            <h2 className='fw-bold'>Testimonials<span className='brand-color'>.</span></h2>
                        </div>
                        <div className='testimonal-slider testimonial-slider-wrapper'>
                            <TestimonialSlider testimonialSetting={testimonialSetting} testimonialdata={testimonialdata} />
                        </div>
                    </div>
                </div>
            </section> */}

            <section>
                <div className='company-service-wrapper  atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='company-inner-service'>
                            <h2 className='faqs-big-heading  fw-bold'>What would you like to get done?</h2> 
                            <div className='company-service-box'>
                                <div className='row'>
                                    <GetDoneCards getdonedata={getdonedata}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='container'>
                    <div className='faqs-wrapper'>  
                        <h6 className='comet-brand-color text-uppercase theme-font-family'>FAQs</h6>   
                        <h2 className='faqs-big-heading fw-bold'>Frequently Asked Questions</h2> 
                        <Accordion className='faqs-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                            <FaqsAccordion accordionData={accordionData} />
                        </Accordion>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} />
        </>
    )
}

export default SoftwareDevelopment

import React from 'react';
import { Tabs, Tab, TabScreen } from "react-tabs-scrollable";
import BannerTabsContent from './BannerTabsContent';

function BannerTabs({ tabArray = [] }) {
    const [activeTab, setActiveTab] = React.useState(Math.floor(tabArray.length / 2)); // Start from the center

    const onTabClick = (e, index) => {
        setActiveTab(index);
    };
    
    console.log("tab array is  : ",tabArray);

    return (
        <>
            <div className='col-lg-11'>
                {tabArray.map((screen) => (
                    <TabScreen
                        key={screen.coverId}
                        activeTab={activeTab}
                        index={screen.coverId}
                    >
                        <BannerTabsContent tabContent={screen.tabContent} />
                    </TabScreen>
                ))}
                <Tabs mode="scrollSelectedToCenterFromView" activeTab={activeTab} onTabClick={onTabClick} tabsArray={tabArray} centerMode={true}>
                    {tabArray.map((item) => (
                        <Tab key={item.coverId} className={`item ${activeTab === item.coverId ? 'active border-0' : 'border-0'}`}>
                            <div className={`card bg-transparent  border-0 ${item.cardBrand}`}>
                                <div className='card-header bg-transparent border-0 p-0'>
                                    <div className='card-img'>
                                        <img src={item.ServiceIcon} title='' alt='' />
                                    </div>
                                    <h3>{item.headinTitle}</h3>
                                </div>
                                <div className='card-body p-0'>
                                    <p className='card-paragraph text-uppercase mt-3'>{item.CardParagraph}</p>
                                </div>
                            </div>
                        </Tab>
                    ))}
                </Tabs>
            </div>
        </>
    );
}

export default BannerTabs;

import React from 'react';
import { Tabs, Tab, TabScreen } from 'react-tabs-scrollable';
import WebsiteTabImage from './WebsiteTabImage';

function FolioUcmsTabs({ ucmstabArray = [] }) {
    console.log("explore Atmosphere component is loaded ");
    const [activeTab, setActiveTab] = React.useState(0);

    const onTabClick = (e, index) => {
        console.log(e);
        setActiveTab(index);
    };

    console.log("tab array is: ", ucmstabArray);

    return (
        <>
            <Tabs mode="scrollSelectedToCenterFromView" activeTab={activeTab} onTabClick={onTabClick} tabsArray={ucmstabArray} centerMode={true}>
                {ucmstabArray.map((item) => (
                    <Tab
                        eventKey={item.coverId}
                        key={item.coverId}
                        className={`item ${activeTab === item.coverId ? 'active text-start border-0' : 'text-start border-0'}`}
                    > {item.name}</Tab>
                ))}
            </Tabs>
            {ucmstabArray.map((screen) => (
                <TabScreen
                    className={`ucmstab-image-content ${screen.ucmsTabImage} ${screen.ahrUcmstabs}`}
                    key={screen.coverId}
                    activeTab={activeTab}
                    index={screen.coverId}
                >
                    <WebsiteTabImage
                        imageUrl={screen.imageUrl}
                        hideScrollAnimation={screen.hideScrollAnimation} 
                        imageFrameUrl= {screen.imageFrameUrl}
                    />
                    <div className={`ahr-website-dropshadow ${screen.ucmsOzenDropShadow}`}></div>
                </TabScreen>
            ))}
        </>
    );
}

export default FolioUcmsTabs;

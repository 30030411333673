import React from 'react'
import FolioInnerBanner from '../../components/FolioInnerBanner'
import atmosphereLogoImg from '../../assets/images/ozen-large-logo.svg'
import MobileAppImage from '../../assets/images/ozen-collection-mobile.webp'
import BrochureImage from '../../assets/images/ui-sheet-left.webp'
import BrochureImageSecond from '../../assets/images/ui-sheet-right.webp'
import { Link } from 'react-router-dom'
import ConsultUs from '../../components/ConsultUs'
import { ListGroup } from 'react-bootstrap-v5'
import AhrColorUsage from '../../components/AhrColorUsage'
import { MdArrowOutward } from "react-icons/md";
import ozenLogoOne from "../../assets/images/ozen-large-logo.svg"
import ozenLogoTwo from "../../assets/images/bolifushi-logo.svg"
import ozenLogoThree from "../../assets/images/maadhoo-logo.svg"
import FolioLogo from '../../components/FolioLogo'
import FolioWebsiteTabs from '../../components/FolioWebsiteTabs'
import imageOne from '../../assets/images/ozen-collection-website.webp'
import imageTwo from '../../assets/images/bolifushi-website.webp'
import imageThree from '../../assets/images/maadhoo-website.webp'
import frameImage from '../../assets/images/upper-frame.webp'
import iconDarkOne from '../../assets/images/outline-icon-one.svg'
import iconDarkTwo from '../../assets/images/outline-icon-two.svg'
import iconDarkThree from '../../assets/images/outline-icon-three.svg'
import iconDarkFour from '../../assets/images/outline-icon-four.svg'
import iconDarkFive from '../../assets/images/outline-icon-five.svg'
import iconDarkSix from '../../assets/images/outline-icon-six.svg'
import iconDarkSeven from '../../assets/images/outline-icon-seven.svg'
import iconDarkEight from '../../assets/images/outline-icon-eight.svg'
import iconDarkNine from '../../assets/images/outline-icon-nine.svg'
import iconDarkTen from '../../assets/images/outline-icon-ten.svg'
import iconDarkEleven from '../../assets/images/outline-icon-eleven.svg'
import iconDarkTwelve from '../../assets/images/outline-icon-twelve.svg'
import OzenDarkIcons from '../../components/OzenDarkIcons'
import OzenColorsIcons from '../../components/OzenColorsIcons'
import iconShadowOne from '../../assets/images/shadow-icon-one.svg'
import iconShadowTwo from '../../assets/images/shadow-icon-two.svg'
import iconShadowThree from '../../assets/images/shadow-icon-three.svg'
import iconShadowFour from '../../assets/images/shadow-icon-four.svg'
import iconShadowFive from '../../assets/images/shadow-icon-five.svg'
import iconShadowSix from '../../assets/images/shadow-icon-six.svg'
import iconShadowSeven from '../../assets/images/shadow-icon-seven.svg'
import iconShadowEight from '../../assets/images/shadow-icon-eight.svg'
import iconShadowNine from '../../assets/images/shadow-icon-nine.svg'
import iconShadowTen from '../../assets/images/shadow-icon-ten.svg'
import iconShadowEleven from '../../assets/images/shadow-icon-eleven.svg'
import iconShadowTwelve from '../../assets/images/shadow-icon-twelve.svg'
import iconShadowThirteen from '../../assets/images/shadow-icon-thirteen.svg'
import iconShadowFourteen from '../../assets/images/shadow-icon-fourteen.svg'
import iconShadowFifteen from '../../assets/images/shadow-icon-fifteen.svg'
import iconShadowSixteen from '../../assets/images/shadow-icon-sixteen.svg'
import iconShadowSeventeen from '../../assets/images/shadow-icon-seventeen.svg'
import iconShadowEighteen from '../../assets/images/shadow-icon-eighteen.svg'
import OzenColorsIconSecond from '../../components/OzenColorsIconSecond'
import seoCopywritingImage from '../../assets/images/seo-copywriting-img.webp'
import SeoCopywritingList from '../../components/SeoCopywritingList'
import FolioUcmsTabs from '../../components/FolioUcmsTabs'
import imageUcmsOne from '../../assets/images/ozen-solution-1.webp'
import imageUcmsTwo from '../../assets/images/ozen-solution-1.webp'

function TheOzenCollectionFolio() {    
    
    const folioHeading = 'The Ozen Collection'
    const createdDate = 'June, 2024'
    const subHeading = 'Hospitality'
    const CaseStudyLink = '/ozen'
    const foliobannerpara = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries."

    const consultdata = [
        {smallHeading: 'Consult with Us', largeHeading: "Let's get started!", consultPara: "Whether you're optimising operations, expanding reach, or enhancing customer satisfaction, our solutions cover software, mobile app development, and websites.", consultPortfolio: 'faqs-consult-wrapper', pageLink: '/consult', LinkText: 'get started'}
    ]

    const colordata = [
        {AhrColorCode: '#323E48', AhrColorName: 'Charcoal'},
        {AhrColorCode: '#D9D8D6', AhrColorName: 'Timberwolf'},
        {AhrColorCode: '#031C2D', AhrColorName: 'Rich Black'},
        {AhrColorCode: '#798C9B', AhrColorName: 'Slate Grey'},
        {AhrColorCode: '#D1AD6E', AhrColorName: 'Gold'},
        {AhrColorCode: '#9C9994', AhrColorName: 'Silver'}
    ]   

    const atmospherePara = "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries."

    const logodata = [
        {ozenLogo: ozenLogoOne}, {ozenLogo: ozenLogoTwo}, {ozenLogo: ozenLogoThree}
    ]

    const badgedata = [
        {TabText: 'Website Design'}, {TabText: 'Website Communication'}, {TabText: 'SEO Copywriting'}, {TabText: 'UI-UX Design'}, {TabText: 'Backend Development'}, {TabText: 'Unified - CMS'}
    ]

    

    const icondata = [
        {iconOne : iconDarkOne, iconTwo : iconDarkTwo, iconThree : iconDarkThree}, 
        {iconOne : iconDarkFour, iconTwo : iconDarkFive, iconThree : iconDarkSix}, 
        {iconOne : iconDarkSeven, iconTwo : iconDarkEight, iconThree : iconDarkNine}, 
        {iconOne : iconDarkTen, iconTwo : iconDarkEleven, iconThree : iconDarkTwelve}
    ]

    const iconcolorsdata = [
        {iconOne : iconShadowOne, iconTwo : iconShadowFour, iconThree : iconShadowSeven, yellowIcon: 'yellow-icons', greyIcon: 'grey-icon'},  
        {iconOne : iconShadowTwo, iconTwo : iconShadowFive, iconThree : iconShadowEight, yellowIcon: 'yellow-icons', greyIcon: 'grey-icon'},  
        {iconOne : iconShadowThree, iconTwo : iconShadowSix, iconThree : iconShadowNine, yellowIcon: 'yellow-icons', greyIcon: 'grey-icon', iconSpacing: 'ozen-icon-space'} 
    ]

    const iconcolorsdatasecond = [
        {iconOne : iconShadowTen, iconTwo : iconShadowThirteen, iconThree : iconShadowSixteen, yellowIcon: 'yellow-icons', greyIcon: 'grey-icon'}, 
        {iconOne : iconShadowEleven, iconTwo : iconShadowFourteen, iconThree : iconShadowSeventeen, yellowIcon: 'yellow-icons', greyIcon: 'grey-icon'}, 
        {iconOne : iconShadowTwelve, iconTwo : iconShadowFifteen, iconThree : iconShadowEighteen, yellowIcon: 'yellow-icons', greyIcon: 'grey-icon', iconSpacing: 'ozen-icon-space'}
    ]

    const copywritingPara = "Crafting the website copy for Atmosphere Hotel & Resort transcends traditional SEO strategies, delving deep into the realm of evocative storytelling and finely curated experiences. This deliberate approach is tailored to elevate brand communication, captivating visitors with enchanting narratives that immerse them in unforgettable journeys while ensuring a seamless and extraordinary user experience."

    const seocopywritingdata = [
        {serialNumber: '1.', copywritingTitle:'Engaging Copy', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'},
        {serialNumber: '2.', copywritingTitle:'Tone of Voice', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'},
        {serialNumber: '3.', copywritingTitle:'SEO Keywords', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'},
        {serialNumber: '4.', copywritingTitle:'Brand Story', seoCopyeritingPara:'Lorem ipsum dolor sit amet, consectetur porta elit.'}
    ]

    const tabArray = [
        {
          coverId: 0, name: 'The Ozen Collection', imageUrl: imageOne, imageFrameUrl: frameImage, ozenDropShadow: 'ozen-dropshadow'
        }, 
        { 
          coverId: 1, name: 'Ozen Reserve Bolifushi', imageUrl: imageTwo, imageFrameUrl: frameImage, ozenDropShadow: 'ozen-dropshadow'
        },
        {
          coverId: 2, name: 'Ozen Life Maadhoo', imageUrl: imageThree, imageFrameUrl: frameImage, ozenDropShadow: 'ozen-dropshadow'
        }
    ];


    
    const ucmstabArray = [
        {
            coverId:0, name:'Lorem ipsum ipsum', imageUrl:imageUcmsOne,  imageFrameUrl: frameImage, hideScrollAnimation:'hide-scroll-animation', ucmsTabImage: 'ucms-tab-image', ucmsOzenDropShadow: 'ucms-dropshadow'
        }, 
        {
            coverId:1, name:'Lorem ipsum ipsum', imageUrl:imageUcmsTwo,  imageFrameUrl: frameImage, hideScrollAnimation:'hide-scroll-animation', ucmsTabImage: 'ucms-tab-image', ucmsOzenDropShadow: 'ucms-dropshadow'
        }
    ]

    return (
        <>
            <FolioInnerBanner atmosphereLogo={atmosphereLogoImg} folioHeading= {folioHeading} foliobannerpara= {foliobannerpara} badgedata={badgedata}  createdDate={createdDate} subHeading={subHeading} CaseStudyLink={CaseStudyLink} />

            <section>
                <div className='about-atmosphere'>
                    <div className='container'>
                        <div className='folio-logo-wrapper'>
                            <ListGroup as='ul'>
                                <FolioLogo logodata={logodata}/>
                            </ListGroup>
                        </div>
                        <div className='colo-usage-main'>
                            <div className='color-usage-heading'>
                                <h3 className='heading-font-family'>Color Usage</h3>
                            </div>
                            <div className='color-usage-wrapper d-flex'>
                                <div className='primary-color-usage w-100 ozen-color-wrapper'>
                                    <ListGroup as='ul' className='ozen-color-usage'>
                                        <AhrColorUsage colordata={colordata} />
                                    </ListGroup>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <section>
                <div className='about-atmosphere atmosphere-case-study position-relative'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-website-folio'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Website<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link to='' target="_blank" className='text-uppercase d-inline-block theme-btn'>Visit Site <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='website-tabs-wrapper'>
                                <FolioWebsiteTabs tabArray={tabArray} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='typography-wrapper'>
                    <div className='container'>
                        <div className='typography-heading'>
                            <h3>Typography</h3>
                        </div>
                        <div className='typography-inner-wrapper'>
                            <div className='row'>
                                <div className='col-lg-7'>
                                    <div className='typography-family-info'>
                                        <div className='alphabet-font-wrapper d-flex align-items-center'>
                                            <h1 className='mb-0'>Aa</h1>
                                            <span>Raleway</span>
                                        </div>
                                        <div className='alphabets-wrapper'>
                                            <h2>abcdefghijklmnopqrstuvwxyz</h2>
                                            <h3>abcdefghijklmnopqrstuvwxyz</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-5'>
                                    <div className='typography-font-info'>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 36</span>
                                            <h1>Heading 1</h1>
                                        </div>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 28</span>
                                            <h2>Heading 2</h2>
                                        </div>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 24</span>
                                            <h3>Heading 3</h3>
                                        </div>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 18</span>
                                            <h4>Heading 4</h4>
                                        </div>
                                        <div className='typography-font d-flex align-items-center'>
                                            <span>Size 16</span>
                                            <h5>Bodytext 1</h5>
                                        </div>
                                        <div className='typography-font mb-0 d-flex align-items-center'>
                                            <span>Size 14</span>
                                            <h6>Bodytext 2</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='typography-wrapper iconography-wrapper'>
                    <div className='container'>
                        <div className='typography-heading'>
                            <h3>Iconography</h3>
                        </div>
                        <div className='typography-inner-wrapper'>
                            <div className='row'>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='ozen-icon-wrapper'>
                                        <h6>Line art icons</h6>
                                        <OzenDarkIcons icondata={icondata}/>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='ozen-icon-wrapper'>
                                        <h6>shadow icons</h6>
                                        <div className='d-flex flex-wrap justify-content-between responsive-icon-wrapper'>
                                            <OzenColorsIcons iconcolorsdata={iconcolorsdata}/>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 col-md-6'>
                                    <div className='ozen-icon-wrapper'>
                                        <h6>shadow icons</h6>
                                        <div className='d-flex flex-wrap justify-content-between responsive-icon-wrapper'>
                                            <OzenColorsIconSecond iconcolorsdatasecond={iconcolorsdatasecond}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-mobile-folio'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>Mobile View<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link to='' target="_blank" className='text-uppercase d-inline-block theme-btn'>View All <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='website-img'>
                                <img src={MobileAppImage} title='' alt='' className='w-100' />
                            </div>
                        </div>
                        <div className='atmosphere-ui-sheet'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>UI Sheet<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                                <div className='visit-site-btn text-end'>
                                    <Link to=''  className='text-uppercase d-inline-block theme-btn'>Visit Site <MdArrowOutward /></Link>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='website-img'>
                                        <img src={BrochureImage} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                                <div className='col-lg-6 col-md-6'>
                                    <div className='website-img'>
                                        <img src={BrochureImageSecond} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container'>
                        <div className='atmosphere-ui-sheet pt-0'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>SEO Copywriting<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper w-100 d-flex align-item-center justify-content-between'>
                                    <p className='mb-0'>{copywritingPara}</p>
                            </div>
                            <div className='row align-items-center'>
                                <div className='col-lg-7 col-md-7'>
                                    <div className='seo-copywriting-wrapper'>
                                        <img src={seoCopywritingImage} title='' alt='' className='w-100' />
                                    </div>
                                </div>
                                <div className='col-lg-5 col-md-5'>
                                    <div className='copywriting-list-wrapper'>
                                        <ListGroup as='ul' className='align-items-center justify-content-center'>
                                            <SeoCopywritingList seocopywritingdata={seocopywritingdata}/>
                                        </ListGroup>            
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study position-relative'>
                    <div className='container-fluid custom-container-fluid'>
                        <div className='atmosphere-website-folio pb-0'>
                            <div className='service-card-heading'>
                                <h2 className='fw-bold mb-0'>UCMS Design<span className='brand-color'>.</span></h2>
                            </div>
                            <div className='webasite-para-wrapper d-flex align-item-center justify-content-between'>
                                <div className='website-para'>
                                    <p className='mb-0'>{atmospherePara}</p>
                                </div>
                            </div>
                            <div className='website-tabs-wrapper'>
                                <FolioUcmsTabs ucmstabArray={ucmstabArray}/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className='about-atmosphere atmosphere-case-study'>
                    <div className='container-fluid custom-container-fluid'>
                        <Link to="/atmosphere-living" className='healthism-btn-wrapper ozen-living-wrapper mt-0 w-100 text-center'>
                            <span className='d-block'>next project</span>
                            <h2>Atmosphere Living</h2>
                        </Link>
                    </div>
                </div>
            </section>

            <ConsultUs consultdata={consultdata} showheading={false} showSmallHeading={false} /> 
        </>
    )
}

export default TheOzenCollectionFolio

import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap-v5';
import Modal from "react-bootstrap/Modal";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

function Modals({ show, handleClose }) {

const [value, setValue] = useState();

return (
    <Modal className='get-started-model' show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
            <Modal.Title>Get started with us. </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Select className='rounded-0 py-3 px-3  custom-input form-control' aria-label="Default select example">
                        <option>Website Package</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </Form.Select>
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control className='rounded-0 py-3 px-3  custom-input form-control' type="text" placeholder="Your name*" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control className='rounded-0 py-3 px-3  custom-input form-control' type="email" placeholder="Your email*" />
                </Form.Group>
                <Form.Group className="mb-3">
                    <PhoneInput
                        international
                        className="custom-input"
                        defaultCountry="IN"
                        value={value}
                        onChange={setValue}
                    />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control className='rounded-0 py-3 px-3  custom-input form-control' type="email" placeholder="Where did you here about us? " />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Control className='rounded-0 py-3 px-3  custom-input form-control'
                        as="textarea"
                        placeholder="Leave a comment here"
                        style={{ height: '100px' }}
                    />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button className='btn text-uppercase orange-btn'>Submit</Button>
        </Modal.Footer>
    </Modal>
);
}

export default Modals;


import React from 'react'
import Card from 'react-bootstrap/Card';

function FeaturesUsedCard({featuresuseddata}) {
  return (
    <>
      {featuresuseddata.map(item=>(
        <div className='col-lg-4 col-md-6 col-sm-6'>
            <Card className="digitech-features-card text-center rounded-0">
                <div className='card-img-wrapper'>
                    <Card.Img className={`m-auto ${item.featurecard}`}  src={item.featuresImage} alt='' title='Branding' />
                </div>
                <Card.Body className='p-0 mt-5'>
                    <Card.Title className='mb-0'>{item.featuresTitle}</Card.Title>
                    <Card.Text className='text-center px-4 mt-3'>{item.featureText}</Card.Text>
                </Card.Body>
            </Card>
        </div>
      ))}
    </>
  )
}

export default FeaturesUsedCard

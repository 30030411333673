import React from 'react'
import { Card } from 'react-bootstrap-v5'

function StatisticsCards({statisticsdata}) {
    return (
        <>
            {statisticsdata.map(item=>(
                <Card className={`rounded-0 border-0 statistics-card-wrapper ${item.softwareStatistics} ${item.statisticscard} ${item.resultCardTwo}`}>
                    <Card.Body className='d-flex align-items-center p-0 justify-content-between'>
                        <Card.Title className='mb-0 fs-2 fw-semibold'>{item.statisticsTitle}</Card.Title>
                        <Card.Img src={item.statisticsImage} className={`result-card-img ${item.brandCommunication} ${item.webDevelopmentIcon}`} alt='result' title='result' />
                    </Card.Body>
                    <Card.Text className='mt-4 w-100'>{item.statisticsPara}</Card.Text>
                </Card>
            ))}
        </>
    )
}

export default StatisticsCards

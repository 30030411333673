import {React, useEffect, useState} from 'react';
import { Link, NavLink } from 'react-router-dom';
import MenuImage from '../assets/images/logo.svg';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap-v5';
import HeaderDropdown from './HeaderDropdown';
import DropdownImage from '../assets/images/web-development.svg'
import DropdownImageThree from '../assets/images/ecommerce.svg'
import DropdownImageTwo from '../assets/images/comet.svg'
import DropdownImageFour from '../assets/images/software-dev.svg'
import DropdownImageSix from '../assets/images/atmosphere-logo.svg'
import DropdownImageSeven from '../assets/images/ozen-logo.svg'
import DropdownImageEight from '../assets/images/solfin-logo.svg'
import LogoImageOne from '../assets/images/react.webp'
import LogoImageTwo from '../assets/images/node-js.webp'
import LogoImageThree from '../assets/images/android.webp'
import LogoImageFour from '../assets/images/apple-logo.webp'
import LogoImageFive from '../assets/images/postgresql.webp'
import { MdArrowOutward } from 'react-icons/md';
import DropdownImageOne from '../assets/images/mob-app-development.svg'
import DropdownImageFive from '../assets/images/uiux-design.svg'
import DropdownImageNine from '../assets/images/gs-logo-two.svg'
import DropdownImageTen from '../assets/images/digital-marketing.svg'
import DropdownImageEleven from '../assets/images/brand-communication.svg'


const Header = () => {
  const [display,setDiplay]=useState(true)
  useEffect(()=>{
    setDiplay(true)
    // console.log("value of display  : ",display)
  },[display,setDiplay])
  console.log("value of dispaly  : ",display)
  const handelDisplay=()=>{
    setDiplay(false)
    // console.log("button is clicked ")
  }
  const handelHamburgerMenu=()=>{
    // setDiplay(false)
    // setExpanded(false);
    handleToggle()
    // console.log("button is clicked ")
  }

    const handleToggle = () => {
        setExpanded(prevExpanded => !prevExpanded); ; // Toggle the state to keep track of whether the menu is expanded or not.
        document.body.classList.toggle('opened-menu'); // Toggle the 'opened-menu' class on the body.
    };
    const [expanded, setExpanded] = useState(false);  
    const [hideMenu, setHideMenu] = useState(false);

    const dropdowndata = [
      {DropdownHeading: 'Web Development', DropdownPara:'Creating Dynamic Web Experiences', DropdownLink:'/web-development', dropdownImage:DropdownImage},
      {DropdownHeading: 'Software Development', DropdownPara:'Engineering Innovative Cloud Solutions', dropdownImage:DropdownImageFour, DropdownLink:'/software-development'},
      {DropdownHeading: 'Mob App Development', DropdownPara:'Designing Seamless Mobile Experiences', dropdownImage:DropdownImageOne, DropdownLink:'/mob-development'},
      {DropdownHeading: 'Digital Marketing', DropdownPara:'Crafting Intuitive User Interfaces', dropdownImage:DropdownImageTen, DropdownLink:'/digital-marketing'},
      {DropdownHeading: 'UI/UX Design', DropdownPara:'Driving Online Brand Engagement', dropdownImage:DropdownImageFive, DropdownLink:'/uiux-design'},
      {DropdownHeading: 'Branding & Communication', DropdownPara:' Crafting Memorable Brand Identities', dropdownImage:DropdownImageEleven, DropdownLink:'/brand-communication'}
    ]

 

  const casestudydata = [
      {DropdownHeading: 'Atmosphere Hotel & Resort', DropdownPara:'Transforming Website Management', DropdownLink:'/atmosphere', dropdownImage:DropdownImageSix,},
      {DropdownHeading: 'The Ozen Collection', DropdownPara:'Creating Luxury Hospitality Experience', DropdownLink:'/ozen', dropdownImage:DropdownImageSeven,},
      {DropdownHeading: 'Solfin', DropdownPara:'Building a Sustainable Future', DropdownLink:'/solfin', dropdownImage:DropdownImageEight,},
      {DropdownHeading: 'Games Stores', DropdownPara:'E-Commerce for Gaming Enthusiasts', DropdownLink:'/game-store', dropdownImage:DropdownImageNine, },
      // {DropdownHeading: '3i schools', DropdownPara:'Lorem ipsum dolor sit amet elit', leftBox:'left-box'},
      // {DropdownHeading: 'Freshkart Farms', DropdownPara:'Lorem ipsum dolor sit amet elit', leftBox:'left-box'},
      // {DropdownHeading: 'Rheja', DropdownPara:'Lorem ipsum dolor sit amet elit', leftBox:'rheja-bg'}
  ]

  
  const solutionstudydata = [
      {DropdownHeading: 'Comet CMS', DropdownPara:'Dynamic Multi-site CMS Solution', DropdownLink:'/comet', dropdownImage:DropdownImageTwo},
      {DropdownHeading: 'Comet Shop', DropdownPara:' Intuitive E-commerce Platform', dropdownImage:DropdownImageThree, DropdownLink:'/e-commerce'}
  ]

    return (
      <>
        <header>
          <div className='header-wrapper'>
            <div className='container-fluid custom-container-fluid'>
              <div className='row align-items-center'>
                <div className='col-lg-12'>
                  <div className='d-flex  responsive-display  justify-content-center'>
                    <Link to='/' className='menu-image'>
                      <img src={MenuImage} alt='' title='' />
                    </Link> 
                    <div id='digitech-nav' className='digitech-responsive-nav digitech-nav-wrapper d-flex align-items-center'>
                      <Navbar className="navbar-nav smooth p-0">
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav as='ul' className="me-auto d-flex align-items-center justify-content-end">
                            <Nav.Item  as='li'>
                              <NavLink className='nav-link' to="/">Home</NavLink>
                            </Nav.Item>
                            {
                              display?(
                                <Nav.Item as='li'>
                              
                              
                                <NavDropdown title="Services" id="basic-nav-dropdown" className='custom-dropdown'>
                                <HeaderDropdown setDiplay={setDiplay} dropdowndata={dropdowndata} />
                                <div className='technology-used'>
                                    <div className='dropdown-technologies-img'>
                                      <span><img src={LogoImageOne} alt='logo' title='logo' /></span>
                                      <span><img src={LogoImageTwo} alt='logo' title='logo' /></span>
                                      <span><img src={LogoImageThree} alt='logo' title='logo' /></span>
                                      <span><img src={LogoImageFour} alt='logo' title='logo' /></span>
                                      <span className='figma-img'><img src={LogoImageFive} alt='logo' title='logo' /></span>
                                    </div>
                                    <Link to='/consult' onClick={handelDisplay} className='text-decoration-none theme-btn ms-4 fs-6'>Get Started <MdArrowOutward /></Link>
                                </div>
                              </NavDropdown>

                            </Nav.Item>
                              ):(
                                <NavDropdown title="Services" id="basic-nav-dropdown" className='custom-dropdown'>
                                </NavDropdown>
                              )
                            }
                            
                            {
                              display?(
                                <Nav.Item as='li'>
                                <NavDropdown title="Solutions" id="basic-nav-dropdown" className='custom-dropdown'>
                                    <HeaderDropdown setDiplay={setDiplay} dropdowndata={solutionstudydata} />
                                    {/* <div className='pricing-links-wrapper'>
                                      <h5 className='mb-3 pricing-heading'>Pricing & Packages</h5>
                                      <div className='header-pricing-link d-flex align-items-center flex-wrap'>
                                        <Link to='/pricing' className='text-decoration-none header-pricing-btn' onClick={(e)=>{setDiplay(false)}}>Website Packaging <MdArrowOutward /></Link>
                                        <Link to='' className='text-decoration-none ms-4 header-pricing-btn' onClick={(e)=>{setDiplay(false)}}>E-Commerce Packaging <MdArrowOutward /></Link>
                                        <Link to='/digital-marketing-pricing' className='text-decoration-none ms-4 header-pricing-btn' onClick={(e)=>{setDiplay(false)}}>Digital Marketing Packaging <MdArrowOutward /></Link>
                                      </div>
                                    </div> */}
                                </NavDropdown>
                            </Nav.Item>
                              ):(
                                <NavDropdown title="Solutions" id="basic-nav-dropdown" className='custom-dropdown'>

                                </NavDropdown>

                              )
                            }
                            {
                              display?(
                                <Nav.Item as='li'>
                                <NavDropdown title="Stories" id="basic-nav-dropdown" className='custom-dropdown'>
                                    <HeaderDropdown setDiplay={setDiplay}  dropdowndata={casestudydata} />
                                </NavDropdown>
                            </Nav.Item>
                              ):(
                                <NavDropdown title="Stories" id="basic-nav-dropdown" className='custom-dropdown'>
                                  
                                </NavDropdown>

                              )
                            }
                            <Nav.Item as='li'>
                              <NavLink className='nav-link'  to='/portfolio'>Portfolio</NavLink>
                            </Nav.Item>
                            <Nav.Item as='li'>
                              <NavLink className='nav-link' to="/consult">Consult Us</NavLink>
                            </Nav.Item>
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                    </div>
                  </div>
                </div>
              </div>
          <div className='menu d-flex justify-content-end'>
            <Link onClick={handleToggle} className="nav-tgl" id='toggleButton' type="button" aria-label="toggle menu">
                <span aria-hidden="true"></span>
            </Link>
            {/* Conditionally render nav based on 'expanded' state */}
            {expanded && (
                <Navbar className="digitech-nav ">
                    <div className="nav-inner">
                        <Nav as='ul' className="me-auto nav-list-wrapper">
                            <Nav.Item onClick={() =>handleToggle()} as='li'><NavLink className='nav-link' to="/">Home</NavLink></Nav.Item>
                            <Nav.Item as='li'>
                                <NavDropdown title="Services" id="basic-nav-dropdown" className='custom-dropdown'>
                                    <HeaderDropdown setExpanded={setExpanded} dropdowndata={dropdowndata} />
                                    <div className='technology-used'>
                                      <div className='dropdown-technologies-img d-flex align-content-center'>
                                        <span className='responsive-tech-img'><img src={LogoImageOne} alt='logo' title='logo' /></span>
                                        <span><img src={LogoImageTwo} alt='logo' title='logo' /></span>
                                        <span><img src={LogoImageThree} alt='logo' title='logo' /></span>
                                        <span><img src={LogoImageFour} alt='logo' title='logo' /></span>
                                        <span className='figma-img'><img src={LogoImageFive} alt='logo' title='logo' /></span>
                                      </div>
                                      <Link to='/consult' onClick={handelHamburgerMenu}  className='text-decoration-none theme-btn ms-4 fs-6'>Get Started <MdArrowOutward /></Link>
                                  </div>
                                </NavDropdown>
                            </Nav.Item>
                            <Nav.Item as='li'>
                                <NavDropdown title="Solutions" id="basic-nav-dropdown" className='custom-dropdown'>
                                    <HeaderDropdown setExpanded={setExpanded} dropdowndata={solutionstudydata} />
                                    {/* <div className='pricing-links-wrapper'>
                                        <h5 className='mb-3 pricing-heading'>Pricing & Packages</h5>
                                        <div className='header-pricing-link d-flex align-items-center flex-wrap'>
                                          <Link to='/pricing' className='text-decoration-none header-pricing-btn' onClick={(e)=>{handelHamburgerMenu()}}>Website Packaging <MdArrowOutward /></Link>
                                          <Link to='' className='text-decoration-none ms-4 header-pricing-btn' onClick={(e)=>{handelHamburgerMenu()}}>E-Commerce Packaging <MdArrowOutward /></Link>
                                          <Link to='/digital-marketing-pricing' className='text-decoration-none ms-4 header-digital-pricing header-pricing-btn' onClick={(e)=>{handelHamburgerMenu()}}>Digital Marketing Packaging <MdArrowOutward /></Link>
                                        </div>
                                      </div> */}
                                </NavDropdown>
                            </Nav.Item>
                            <Nav.Item as='li'>
                                <NavDropdown title="Stories" id="basic-nav-dropdown" className='custom-dropdown'>
                                    <HeaderDropdown setExpanded={setExpanded} dropdowndata={casestudydata} />
                                </NavDropdown>
                            </Nav.Item>
                            <Nav.Item onClick={handleToggle} as='li'><NavLink className='nav-link'  to='/portfolio'>Portfolio</NavLink></Nav.Item>
                            <Nav.Item onClick={handleToggle} as='li'><NavLink className='nav-link' to="/consult">Consult Us</NavLink></Nav.Item>
                        </Nav>
                    </div>
                </Navbar>
              )}
          </div>
            </div>
          </div>
        </header>
      </>
    )
  }

export default Header
import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import { MdArrowOutward } from "react-icons/md";

function ServiceHomeAccordion({ ServiceaccordionData }) {
    const [activeKey, setActiveKey] = useState(ServiceaccordionData.length > 0 ? ServiceaccordionData[0].planEventKey : null);

    const toggleAccordion = (eventKey) => {
        setActiveKey(activeKey === eventKey ? null : eventKey);
    };

    return (
        <Accordion activeKey={activeKey} onSelect={toggleAccordion}>
            {ServiceaccordionData.map((item, index) => (
                <Accordion.Item key={index} eventKey={item.planEventKey}>
                    <Accordion.Header className='responsive-service-card'>
                        <div className='service-card-img'>
                            <img src={item.ServiceIcon} title='' alt='' />
                        </div>
                        {item.headinTitle}
                    </Accordion.Header>
                    <Accordion.Body>
                        <p>{item.CardParagraph}</p>
                        <div className='btn-wrapper'>
                            <Link to={item.serviceLink} className='btn text-white text-uppercase learn-more-btn text-decoration-none p-0'>
                                {item.LearnMore} <MdArrowOutward />
                            </Link>
                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            ))}
        </Accordion>
    );
}

export default ServiceHomeAccordion

import React, { useState } from 'react'
import PricingBanner from '../../components/PricingBanner'
import { ListGroup} from 'react-bootstrap-v5'
import PaymentBoxes from '../../components/PaymentBoxes'
import paymentIcon from '../../assets/images/accessibility.svg'
import { BsChevronDown } from "react-icons/bs";
import paymentImageOne from '../../assets/images/visa.webp'
import paymentImageTwo from '../../assets/images/discover.webp'
import paymentImageThree from '../../assets/images/american-express.webp'
import paymentImageFour from '../../assets/images/master-card.webp'
import pricingIcon from "../../assets/images/check.svg";
import PricingFeatures from '../../components/PricingFeatures'
import pricingIconminus from '../../assets/images/minus-second.svg'
import PricingTabs from '../../components/PricingTabs'
import { Link } from 'react-router-dom'
import FeatureHeadings from '../../components/FeatureHeadings'

function Pricing() {
    
    const pricingdata = [
        {pricingTitle: 'Our Plans', pricingPara: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eget malesuada est. Donec lobortis consequat ultricies. Etiam laoreet odio at dictum elementum."}
    ]

    const comparePlanFeature = "compare plan feature"

    const paymentdata = [
        {
            paymentIcon: paymentIcon, paymentHeading: "Money Back Gauranteee", paymentPara: "Lorem ipsum dolor sit amet, consectetur adipiscing"
        },
        {
            paymentIcon: paymentIcon, paymentHeading: "Secure Payment", paymentPara: "Lorem ipsum dolor sit amet, consectetur adipiscing"
        },
        {
            paymentIcon: paymentIcon, paymentHeading: "Secure Payment", paymentPara: "Lorem ipsum dolor sit amet, consectetur adipiscing"
        }
    ]

    const [isOpened, setIsOpened] = useState(false);

    function toggle() {
        setIsOpened(wasOpened => !wasOpened);
    }

    const paymentAccepted = "Accepted Payment Methods"

    
    
   
    const tabArray = [
        {
            coverId:0, buttonText: 'Website Pricing',
            pricinglistdata : [
                {
                    packageHeading: 'Starter', packageSmallText: 'Scale your business', packagePrice: '20k', packageButton: 'get started', fadeClass: "fade-tab", monthText: '/month', discountText: 'Save 20%', cutPrice: '30k', showButton: true,
                    
                    collabratorslistdata : [
                        {collabratorlistText: 'collaborators', boldText: '15'},
                        {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                        {collabratorlistText: 'Free domain for 1 year'},
                        {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                        {collabratorlistText: 'Accept payments'},
                        {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                        {collabratorlistText: 'developer platform', boldText: 'Advanced'},
                    ]
                },
                {
                    packageHeading: 'Plus', packageSmallText: 'Scale your business', packagePrice: '50k', packageButton: 'get started', recommendedText: 'recommended', getStartedPurpleBtn: "pricing-btn",fadeClass: "fade-tab", monthText: '/month', discountText: 'Save 20%', cutPrice: '60k', showButton: true,
                    
                    collabratorslistdata : [
                        {collabratorlistText: 'collaborators', boldText: '15'},
                        {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                        {collabratorlistText: 'Free domain for 1 year'},
                        {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                        {collabratorlistText: 'Accept payments'},
                        {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                        {collabratorlistText: 'developer platform', boldText: 'Advanced'},
                    ]
                },
                {
                    packageHeading: 'Enterprise', packageSmallText: 'Scale your business', packagePrice: '100k', packageButton: 'Contact us',fadeClass: "fade-tab", monthText: '/month', discountText: 'Save 20%', cutPrice: '120k',getstartedLink : '/consult',
                    
                    collabratorslistdata : [
                        {collabratorlistText: 'collaborators', boldText: '15'},
                        {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                        {collabratorlistText: 'Free domain for 1 year'},
                        {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                        {collabratorlistText: 'Accept payments'},
                        {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                        {collabratorlistText: 'developer platform', boldText: 'Advanced'},
                    ]
                }
            ]
        },
        {
            coverId:1, buttonText: 'CMS Pricing',
            pricinglistdata : [
                {
                    packageHeading: 'Starter', packageSmallText: 'Scale your business', packagePrice: '30k', packageButton: 'get started',monthText: '/month', discountText: 'Save 20%', cutPrice: '40k',showButton: true,
                     
                    collabratorslistdata : [
                        {collabratorlistText: 'collaborators', boldText: '15'},
                        {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                        {collabratorlistText: 'Free domain for 1 year'},
                        {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                        {collabratorlistText: 'Accept payments'},
                        {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                        {collabratorlistText: 'developer platform', boldText: 'Advanced'},
                    ]
                },
                {
                    packageHeading: 'Plus', packageSmallText: 'Scale your business', packagePrice: '80k', packageButton: 'get started', recommendedText: 'recommended', getStartedPurpleBtn: "pricing-btn",monthText: '/month', discountText: 'Save 20%', cutPrice: '90k',showButton: true,
                    
                    collabratorslistdata : [
                        {collabratorlistText: 'collaborators', boldText: '15'},
                        {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                        {collabratorlistText: 'Free domain for 1 year'},
                        {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                        {collabratorlistText: 'Accept payments'},
                        {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                        {collabratorlistText: 'developer platform', boldText: 'Advanced'},
                    ]
                },
                {
                    packageHeading: 'Enterprise', packageSmallText: 'Scale your business', packagePrice: '200k', packageButton: 'Contact us',monthText: '/month', discountText: 'Save 20%', cutPrice: '210k',
                    
                    collabratorslistdata : [
                        {collabratorlistText: 'collaborators', boldText: '15'},
                        {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                        {collabratorlistText: 'Free domain for 1 year'},
                        {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                        {collabratorlistText: 'Accept payments'},
                        {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                        {collabratorlistText: 'developer platform', boldText: 'Advanced'},
                    ]
                }
            ]
        },
        {
            coverId:2, buttonText: 'E-Commerce Pricing',
            pricinglistdata : [
                {
                    packageHeading: 'Starter', packageSmallText: 'Scale your business', packagePrice: '40k', packageButton: 'get started', monthText: '/month', discountText: 'Save 20%', cutPrice: '50k',showButton: true,
                    
                    collabratorslistdata : [
                        {collabratorlistText: 'collaborators', boldText: '15'},
                        {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                        {collabratorlistText: 'Free domain for 1 year'},
                        {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                        {collabratorlistText: 'Accept payments'},
                        {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                        {collabratorlistText: 'developer platform', boldText: 'Advanced'},
                    ]
                },
                {
                    packageHeading: 'Plus', packageSmallText: 'Scale your business', packagePrice: '100k', packageButton: 'get started', recommendedText: 'recommended', getStartedPurpleBtn: "pricing-btn", monthText: '/month', discountText: 'Save 20%', cutPrice: '110k',showButton: true,
                    
                    collabratorslistdata : [
                        {collabratorlistText: 'collaborators', boldText: '15'},
                        {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                        {collabratorlistText: 'Free domain for 1 year'},
                        {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                        {collabratorlistText: 'Accept payments'},
                        {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                        {collabratorlistText: 'developer platform', boldText: 'Advanced'},
                    ]
                },
                {
                    packageHeading: 'Enterprise', packageSmallText: 'Scale your business', packagePrice: '300k', packageButton: 'Contact us', monthText: '/month', discountText: 'Save 20%', cutPrice: '310k',
                    
                    collabratorslistdata : [
                        {collabratorlistText: 'collaborators', boldText: '15'},
                        {collabratorlistText: 'storage space', boldText: 'Unlimited'},
                        {collabratorlistText: 'Free domain for 1 year'},
                        {collabratorlistText: 'site analytics', boldText: 'Advanced'},
                        {collabratorlistText: 'Accept payments'},
                        {collabratorlistText: 'eCommerce', boldText: 'Advanced'},
                        {collabratorlistText: 'developer platform', boldText: 'Advanced'},
                    ]
                }
            ]
        }
    ]

    const pricingfeaturesheading = [
        {
            domainHeading: 'Custom Domain', mainHeading: 'Site Feature', hideToolTip: 'hide-tooltip'
        },
        {
            domainHeading: 'Free domain for 1 year', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading', hideToolTip: 'hide-tooltip'
        },
        ,
        {
            domainHeading: 'Remove branding', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading' , hideToolTip: 'hide-tooltip'
        },
        {
            domainHeading: 'Storage space', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading', hideToolTip: 'hide-tooltip'
        },
        ,
        {
            domainHeading: 'Customer accounts', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading', hideToolTip: 'hide-tooltip' 
        },
        {
            domainHeading: 'Customized reports', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading', hideToolTip: 'hide-tooltip'
        },
        ,
        {
            domainHeading: 'Customer care', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading', hideToolTip: 'hide-tooltip' 
        },
        {
            domainHeading: 'Custom Domain', mainHeading: 'Full marketing suite', HideBox:'hide-blank-box', hideToolTip: 'hide-tooltip'
        },
        {
            domainHeading: 'Free domain for 1 year', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading', hideToolTip: 'hide-tooltip'
        },
        ,
        {
            domainHeading: 'Remove branding', HideBox:'hide-blank-box', hideMainHeading: 'hide-main-heading', hideToolTip: 'hide-tooltip' 
        },
    ]

    const pricingfeaturesdata = [
        {
            Starter: 'Starter',pricingDomain: "customer-accounts-text", mainprice: '20k', rupeeSymble: '₹',  RecommendedText: 'Recommended', featureRightBlank: 'feature-right-blank', hideTableResponsive : 'hide-table-responsive',StarterTwo: 'Plus',
            mainpriceTwo: '50k', StarterThree:  'Enterprise', mainpriceThree: '100k'
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },  
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },  
        {
            pricingTextOne: '50GB', showSmallHeading:false, pricingTextThree: 'Unlimited', pricingDomain: "customer-accounts-text", pricingTextTwo: '100GB', HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },   
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIconminus, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        }, 
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIconminus, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        }, 
        {
            pricingTextOne: '50GB', showSmallHeading:false, pricingTextThree: 'Unlimited', pricingDomain: "customer-accounts-text", pricingTextTwo: '100GB', HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box', bottomBorder: 'border-bottom-show' 
        }, 
        {
                mainprice: '20k', rupeeSymble: '₹',  RecommendedText: 'Recommended', featureRightBlank: 'feature-right-blank', hideTableResponsive : 'hide-table-responsive', hideMainHeading: 'hide-pricing-box'
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIconminus, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
        {
            pricingIconTwo: pricingIcon, showSmallHeading:true, pricingIconThree: pricingIcon, pricingDomain: "customer-accounts-text", pricingIconOne: pricingIcon, HideBox:'hide-blank-box', hideMainHeading: 'hide-pricing-box' 
        },
    ]

    const ShowFeatureText = 'show more features'

    return (
        <>
            <PricingBanner pricingdata={pricingdata} />

            <section>
                <div className='pricing-wrapper'>
                    <div className='container'>
                        
                        {/* <div className='pricing-info-wrapper'>
                            <ListGroup as="ul" className='d-flex align-items-center'>
                                <PricingList pricinglistdata={pricinglistdata} />
                            </ListGroup>
                        </div> */}
                        
                        <div className='pricing-tabs-wrapper'>
                            <PricingTabs tabArray={tabArray} />
                        </div>

                        <div className='compare-wrapper'>
                            <div className='compare-heading'>
                                <a href='#pricingtable' className='compare-feature-wrapper'>{comparePlanFeature}</a>
                            </div>
                            <div className='payment-main-wrapper'>
                                <ListGroup as="ul" className='d-flex align-items-center payment-wrapper flex-row flex-wrap'>
                                    <PaymentBoxes paymentdata={paymentdata} />
                                    {isOpened && (
                                        <PaymentBoxes paymentdata={paymentdata} />
                                    )}
                                </ListGroup>
                                <div className="boxtitle" onClick={toggle}>
                                    <div className='show-less-wrapper bg-white'>
                                        <BsChevronDown className='show-less-icon' />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='payment-accepted-method d-flex align-items-center justify-content-center'>
                            <div className='payment-accepted-text'>{paymentAccepted}</div>
                            <div className='payment-accepted-wrapper'>
                                <span className='payment-image'><img src={paymentImageOne} alt='' title='' /></span>
                                <span className='payment-image'><img src={paymentImageTwo} alt='' title='' /></span>
                                <span className='payment-image'><img src={paymentImageThree} alt='' title='' /></span>
                                <span className='payment-image'><img src={paymentImageFour} alt='' title='' /></span>
                            </div>
                        </div>

                        <div className='pricing-table-wrapper' id="pricingtable">
                            <div className='pricing-table-inner d-flex'>
                                <div className='package-table-headings'>
                                    <FeatureHeadings pricingfeaturesheading={pricingfeaturesheading} />
                                </div>
                                <div className='pricing-data-inner'>
                                    <PricingFeatures pricingfeaturesdata={pricingfeaturesdata} />
                                </div>
                            
                                {/* <div className='more-features-show'>
                                    <PricingFeaturesSecond pricingfeaturessecond={pricingfeaturessecond} />
                                    {isOpened && (
                                            <PricingFeatures pricingfeaturesdata={pricingfeaturesdata} />
                                    )}
                                    <div className="boxtitle" onClick={toggle}>
                                        <div className='compare-heading show-features-wrapper'>
                                            <Link to='' className='show-more-btn text-uppercase'>{ShowFeatureText} <FaChevronDown /></Link>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Pricing

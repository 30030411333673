import React from 'react'
import { ListGroup } from 'react-bootstrap-v5'

function SeoCopywritingList({seocopywritingdata}) {
    return (
        <>  
            {seocopywritingdata.map (item =>(
                <ListGroup.Item as='li'>
                    <h5><span>{item.serialNumber}</span> {item.copywritingTitle}</h5>
                    <p>{item.seoCopyeritingPara}</p>
                </ListGroup.Item>
            ))}        
        </>
    )
}

export default SeoCopywritingList

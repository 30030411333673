import React from 'react'
import { Link } from 'react-router-dom'
import { MdArrowOutward } from 'react-icons/md';

function LookingPackages({packagesdata, showSmallHeading=false, showheading=true,}) {
    return (
        <>
            {packagesdata.map (item => (
                <div className='row align-items-center'>
                    <div className='col-lg-9'>
                        <div className='consult-info-wrapper'>
                            {showSmallHeading ? (<h6 className='subbrand-color text-uppercase'>{item.smallHeading}</h6>):(<h6 className='brand-color text-uppercase theme-font-family'>{item.smallHeading}</h6>)}
                           
                           <h2 className='mb-4 small-heading fw-bold'>{item.largeHeading}</h2>
                            <p>{item.packagePara}</p>
                        </div>
                    </div>
                    <div className='col-lg-3'>
                        <div className='consult-btn-wrapper d-flex align-item-center justify-content-end'>
                            <Link to='/consult'  className={`btn consult-btn ${item.cometConsultBtn}`}>Get Started <MdArrowOutward /></Link>
                        </div>
                    </div>
                </div>
            ))}
        </>
    )
}

export default LookingPackages

import React from 'react'
import HospitalOne from '../assets/images/multi-cms.svg'
import EdTechIcon from '../assets/images/e-commerce.svg'
import Ecommerce from '../assets/images/mobile-banking.svg'
import HealthCare from '../assets/images/brand-loyalty.svg'
import CardView from './CardView';
import { Accordion } from 'react-bootstrap-v5'
import ServiceHomeAccordion from './ServiceHomeAccordion'

function ServicesCard() {
    const carddata = [
        {headinTitle:'Multisite CMS', ServiceIcon: HospitalOne, CardParagraph: 'Efficiency & coherence soar as our unified solution harmonizes content management across multiple sites.', LearnMore: 'Learn More', serviceLink: '/comet'},

        {headinTitle:'E-commerce', ServiceIcon: EdTechIcon, CardParagraph: 'Set up online shops and boost sales effortlessly with our dynamic e-commerce solutions, engineered for swift transactions & amplified conversions.', LearnMore: 'Learn More',cardBrand:'edtech-card', serviceLink: '/e-commerce'},

        {headinTitle:'Payment Solutions', ServiceIcon: Ecommerce,  CardParagraph: 'Maximize customer convenience with our secure payment and point-of-sale solution, seamlessly integrated with popular payment gateways for streamlined transactions.', LearnMore: 'Get Started',cardBrand:'ecommerce-card', serviceLink: '/consult'},

        {headinTitle:'Brand Loyalty', ServiceIcon: HealthCare, CardParagraph: 'Cultivate lasting connections with customisable brand loyalty programs, enticing customers with digital privilege cards for exclusive benefits and enhanced retention.', LearnMore: 'Get Started',cardBrand:'healthcare-card', serviceLink: '/consult'}
    ]

    const ServiceaccordionData = [
        {planEventKey: '0', headinTitle:'Multisite CMS', ServiceIcon: HospitalOne, CardParagraph: 'Efficiency & coherence soar as our unified solution harmonizes content management across multiple sites.', LearnMore: 'Learn More', serviceLink: '/comet'},

        {planEventKey: '1', headinTitle:'E-Commerce', ServiceIcon: EdTechIcon, CardParagraph: 'Set up online shops and boost sales effortlessly with our dynamic e-commerce solutions, engineered for swift transactions & amplified conversions.', LearnMore: 'Learn More',cardBrand:'edtech-card', serviceLink: '/e-commerce'},

        {planEventKey: '2', headinTitle:'Payment Solutions', ServiceIcon: Ecommerce, CardParagraph: 'Maximize customer convenience with our secure payment and point-of-sale solution, seamlessly integrated with popular payment gateways for streamlined transactions.', LearnMore: 'Get Started',cardBrand:'ecommerce-card', serviceLink: '/consult'},

        {planEventKey: '3', headinTitle:'Brand Loyalty', ServiceIcon: HealthCare, CardParagraph: 'Cultivate lasting connections with customisable brand loyalty programs, enticing customers with digital privilege cards for exclusive benefits and enhanced retention.', LearnMore: 'Get Started',cardBrand:'healthcare-card', serviceLink: '/consult'}
    ]

    const defaultActiveKey = ServiceaccordionData.length > 0 ? ServiceaccordionData[0].planEventKey : null;


  return (
    <>
        <section>
            <div className='digitech-services-wrapper'>
                <div className='container-fluid custom-container-fluid'>
                    <div className='service-card-heading'>
                        <h2 className='fw-bold'>Innovative Solutions Tailored for Your Success<span className='brand-color'>.</span></h2>
                    </div>
                    <div className='service-card-inner-wrapper'>
                        <div className='row'>
                            <CardView carddata={carddata}/>
                            <div className='service-accordion'>
                                <Accordion className='service-accordion-wrapper' defaultActiveKey={defaultActiveKey}>
                                    <ServiceHomeAccordion ServiceaccordionData={ServiceaccordionData} />
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}

export default ServicesCard

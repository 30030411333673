import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom'
import { MdArrowForward } from "react-icons/md";

function CaseStudySlider({images,classview='',gallerySettings}) {
    const settings = {...gallerySettings}
  return (
    <>
        <Slider {...settings}>
            {images.map(item=>(
                <div className='Digitech-case-study-wrapper'>
                    <div className={`case-study-image overflow-hidden ${item.classview} ${item.casestudyclass}`}>
                        <img src={item.imageUrl} alt='Our Gallery' title=''  />
                    </div>
                    <div className='case-study-text rounded-4 ms-auto me-auto'>
                        <Link to={item.caseStudyLink} className='case-study-heading mb-3'>{item.galleryHeading}</Link>
                        <Link  className='mt-4 d-block theme-btn text-uppercase' to={item.learnMore}>{item.learnMoreLink} <MdArrowForward /></Link>
                    </div>
                </div>
            ))}
        </Slider>
    </>
  )
}

export default CaseStudySlider

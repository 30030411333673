import React from 'react'
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { IoIosArrowRoundForward } from "react-icons/io";

function BannerTabsContent({tabContent}) {
    return (
        <>
            <div className='tab-content'>
                {tabContent.map((item=> 
                    <div className='banner-heading text-center'>
                        {/* <div className='banner-img'>
                            <img src={item.bannerIcon} title='icon' alt='icon' />
                        </div> */}
                        <h1>{item.bannerHeading}</h1>
                        <p className='banner-paragrapgh'>{parse(item.para)}</p>
                        <Link to={item.innerPageLink} className='btn banner-theme-btn text-uppercase border-0 mb-4 py-0 px-0'>{item.tabPageLink} <IoIosArrowRoundForward /></Link>
                    </div>
                ))}
            </div>
        </>
    )
}

export default BannerTabsContent

import React, { useRef } from 'react';
import { Tabs, Tab, TabScreen } from 'react-tabs-scrollable';
import WebsiteTabImage from './WebsiteTabImage';

function FolioWebsiteTabs({ tabArray = [] }) {
  console.log("explore Atmosphere component is loaded");
  const [activeTab, setActiveTab] = React.useState(0);
  const tabsRef = useRef(null);

  const onTabClick = (index) => {
    console.log("Tab clicked:", index);
    setActiveTab(index);
  };

  

  console.log("tab array is:", tabArray);

  return (
    <>
      <div className="folio-tabs-navigation">
        <Tabs
          ref={tabsRef}
          className="tabs-container"
          mode="scrollSelectedToCenterFromView"
          activeTab={activeTab}
          onTabClick={(e, index) => onTabClick(index)}
          tabsArray={tabArray}
          centerMode={true}
        >
          {tabArray.map((item, index) => (
            <Tab
              eventKey={item.coverId}
              key={item.coverId}
              className={`item ${activeTab === item.coverId ? 'active text-start border-0' : 'text-start border-0'}`}
              onClick={() => onTabClick(index)}
            >
              {item.name}
            </Tab>
          ))}
        </Tabs>
      </div>
      {tabArray.map((screen) => (
        <TabScreen key={screen.coverId} activeTab={activeTab} index={screen.coverId} className={`tab-image-content ${screen.ahrtabContent} ${screen.gameStoresContent}`}>
          <WebsiteTabImage imageUrl={screen.imageUrl} imageFrameUrl={screen.imageFrameUrl} />
          <div className={`website-dropshadow ${screen.ahrDropShadow} ${screen.ozenDropShadow}`}></div>
        </TabScreen>
      ))}
    </>
  );
}

export default FolioWebsiteTabs;
